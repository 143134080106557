body {
  margin: 0;
  padding: 0;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  color: #262a2d;
}
body.fc-hidden #fc_frame,
body.fc-hidden .zsiq_floatmain {
  display: none !important;
}
.genericBoxShadow {
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.genericBoxShadow-heavy {
  -webkit-box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
.genericBoxShadow-soft {
  -webkit-box-shadow: 0 3px 30px rgba(103, 125, 169, 0.07);
  -moz-box-shadow: 0 3px 30px rgba(103, 125, 169, 0.07);
  box-shadow: 0 3px 30px rgba(103, 125, 169, 0.07);
  border-radius: 2px;
}
.bgCover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.animateWidth {
  -moz-transition: width 0.3s ease-in-out;
  -o-transition: width 0.3s ease-in-out;
  -webkit-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
}
.animateMargin {
  -moz-transition: margin 0.3s ease-in-out;
  -o-transition: margin 0.3s ease-in-out;
  -webkit-transition: margin 0.3s ease-in-out;
  transition: margin 0.3s ease-in-out;
}
.animateOpacity {
  -moz-transition: opacity 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}
.colorTransition {
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.transformTransition {
  -moz-transition: transform 0.2s ease-in-out;
  -o-transition: transform 0.2s ease-in-out;
  -webkit-transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
}
.allTransition {
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
}
/* Colours */
/* Fonts */
/* sfPro */
@font-face {
  font-family: 'sf-semibold';
  src: url('./assets/fonts/sfpro/SF-Pro-Display-Semibold.otf');
}
@font-face {
  font-family: 'sf-medium';
  src: url('./assets/fonts/sfpro/SF-Pro-Display-Medium.otf');
}
@font-face {
  font-family: 'sf-regular';
  src: url('./assets/fonts/sfpro/SF-Pro-Display-Regular.otf');
}
/* Merriweather */
@font-face {
  font-family: 'merri-bold';
  src: url('./assets/fonts/merri/Merriweather-Bold.ttf');
}
@font-face {
  font-family: 'black_jackregular';
  src: url('./assets/fonts/bj/blackjack.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
/* Text styles */
.text-h1 {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  color: #262a2d;
  font-size: 36px;
  margin: 0;
}
.text-h3 {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  color: #3f4264;
  font-size: 22px;
  margin: 0;
}
.text-h4 {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  color: #5f717f;
  font-size: 20px;
  margin: 0;
}
.text-h5 {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  color: #5f717f;
  font-size: 13px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 0;
}
.text-h5-noUpper {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  color: #5f717f;
  font-size: 13px;
  margin: 0;
}
.text-h6 {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  color: #536280;
  font-size: 16px;
  margin: 0;
}
.text-formTitleLarge {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 26px;
  color: #536280;
  margin: 0;
}
.text-formTitleMedium {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 20px;
  color: #536280;
  margin: 0;
}
.text-formTitleSmall {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #536280;
  margin: 0;
}
.text-formLabel {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #536280;
  margin: 0;
}
.text-formLabelSmall {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 13px;
  color: #536280;
  margin: 0;
}
.text-body {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 13px;
  color: #536280;
  margin: 0;
}
.text-formQuestionNumber {
  width: 40px;
  text-align: center;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 20px;
  color: #4a57b7;
}
.text-hubTitle {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 36px;
  color: #536280;
  margin: 0;
}
.text-hubSubTitle {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 20px;
  color: #536280;
  margin: 0;
}
.text-error {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 13px;
  color: #c02743;
  margin: 0;
}
.text-help {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  color: #8695b2;
  font-size: 11px;
  margin: 0;
}
.text-highlightedHelp {
  font-size: 12px;
  color: #5c90df;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
}
.text-name {
  font-size: 16px;
  color: #262a2d;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  margin: 0;
}
.text-statsNumber {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 20px;
  color: #4a57b7;
  margin: 0;
}
.text-statsText {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 13px;
  color: #536280;
  margin: 0;
}
a,
.text-link {
  color: #4a57b7;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  cursor: pointer;
}
a:hover,
.text-link:hover,
a:active,
.text-link:active {
  color: #364196;
  text-decoration: none;
}
.generalText {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #262a2d;
}
.italics {
  font-style: italic;
}
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-cap {
  text-transform: capitalize;
}
.qs-regular {
  font-family: 'Quicksand', sans-serif;
  font-weight: 400;
}
.qs-medium {
  font-family: 'Quicksand', sans-serif;
  font-weight: 500;
}
.qs-semibold {
  font-family: 'Quicksand', sans-serif;
  font-weight: 600;
}
.qs-bold {
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
}
.sf-semibold {
  font-family: 'sf-semibold', serif;
}
.merri-bold {
  font-family: 'merri-bold', serif;
}
.fontLight {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
}
.fontRegular {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
}
.fontMedium {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
}
.fontHeavy {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
}
.font-bold {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700;
}
.font-semibold {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
}
.letterSpacing-1 {
  letter-spacing: 1px;
}
.text-bold {
  font-weight: bold;
}
.textAlign-right {
  text-align: right;
}
.fontSize-13 {
  font-size: 13px;
}
.fontSize-16 {
  font-size: 16px;
}
.fontSize-18 {
  font-size: 18px;
}
.fontSize-20 {
  font-size: 20px;
}
.fontSize-26 {
  font-size: 26px;
}
.fontSize-32 {
  font-size: 32px;
}
.fontSize-36 {
  font-size: 36px;
}
.lineHeight-22 {
  line-height: 22px;
}
.lineHeight-32 {
  line-height: 32px;
}
.height-30 {
  height: 30px;
}
.height-40 {
  height: 40px;
}
.height-100p {
  height: 100%;
}
.lineClamp-2 {
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -webkit-line-clamp: 2;
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
}
.lineClamp-4 {
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -webkit-line-clamp: 4;
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
}
.fillSpace {
  overflow: hidden;
}
.listEntry {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  padding: 10px;
  color: #4a57b7;
  font-size: 16px;
  margin: 0;
  cursor: pointer;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.listEntry:hover {
  background-color: rgba(74, 87, 183, 0.2);
}
/* Header/Label Styles */
.header {
  background-color: #fff;
  margin-bottom: 2px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  z-index: 5;
  border-bottom: solid 1px #e7ecf1;
  min-height: 55px;
}
.header-simple {
  border-bottom: none;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  border-radius: 0;
  justify-content: center;
}
.header-fixed {
  position: fixed;
  top: 0;
  left: 0;
}
.header_title {
  color: #4a57b7;
}
.header_back {
  cursor: pointer;
  font-size: 30px;
  color: #4a57b7;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  width: 40px;
  text-align: center;
  display: inline-block;
}
.header_back:hover {
  color: #364196;
}
.container1200 {
  max-width: 1200px;
  flex: 1;
}
.container600 {
  max-width: 600px;
  flex: 1;
}
.header_logo {
  height: 35px;
}
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  padding: 40px;
}
.popup_box {
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  max-width: 80vw;
  position: relative;
  box-shadow: 0 1px 30px 0 rgba(0, 0, 0, 0.1);
}
.popup_box_inner {
  flex: 1;
  overflow-y: auto;
}
.popup_close {
  color: #67758f;
  font-size: 16px;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.popup_close:hover {
  color: #536280;
}
.popup_closeIcon {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  width: 14px;
  height: 14px;
  z-index: 500;
}
.popup_title {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  color: #536280;
  font-size: 32px;
}
.popup_subtitle {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  color: #536280;
  font-size: 16px;
  margin: 0;
}
.popup_buttonSection {
  padding: 12px 16px;
  display: flex;
  flex-direction: row-reverse;
  border-top: solid 1px #dbddf1;
}
.popup-error .popup_box {
  width: 300px;
  padding: 30px;
}
.popup_box-hasPadding {
  padding: 30px;
}
.fieldLabel {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #536280;
  -moz-transition: opacity 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}
.fieldLabel--dark {
  letter-spacing: 1px;
  color: #536280;
}
.fieldLabel-warning {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #c02743;
}
.actionText {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #536280;
}
.actionText--disabled {
  opacity: 0.5;
}
.noUnderline {
  text-decoration: none;
}
.noUnderline:hover {
  text-decoration: none;
}
/* Text Colour */
.text-lighter {
  color: rgba(113, 123, 129, 0.8);
}
.text-light {
  color: #717b81;
}
.text-mid {
  color: #5a5a6e;
}
.text-dark {
  color: #262a2d;
}
.text-dusk {
  color: #536280;
}
.text-bluegrey {
  color: #6c7a90;
}
.text-loginGrey {
  color: rgba(113, 123, 129, 0.8);
}
.text-plussBlue {
  color: #191e78;
}
.text-plussRed {
  color: #c02743;
}
.text-tang {
  color: #ff8c75;
}
.text-teal {
  color: #49bdbb;
}
.text-white {
  color: #fff !important;
}
.text-shadow {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.text-italic {
  font-style: italic;
}
.text-sectionTitle {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 1px;
  color: #536280;
}
.text-brandingColour {
  color: #4a57b7;
}
.text-brandingAction {
  color: #5c90df;
}
.text-brandingOff {
  color: #597db4;
}
.text-subtitle {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 13px;
  color: #5c90df;
}
.text-capitaliseWords {
  text-transform: capitalize;
}
.background-success {
  background-color: #4a57b7;
}
/* Spacing Styles */
.flex {
  display: flex;
}
.flex-reverse {
  display: flex;
  flex-direction: row-reverse;
}
.flex-center-row {
  justify-content: center;
}
.flex-column {
  flex-direction: column;
}
.flex-row {
  flex-direction: row;
}
.flex-center {
  align-items: center;
}
.flex-align-start {
  align-items: flex-start;
}
.flex-align-end {
  align-items: flex-end;
}
.flex-end {
  justify-content: flex-end;
}
.flex-between {
  justify-content: space-between;
}
.flex-around {
  justify-content: space-around;
}
.flex-1 {
  flex: 1;
}
.inline {
  display: inline;
}
.overflowY-auto {
  overflow-y: auto;
}
.overflow-hidden {
  overflow: hidden;
}
.text-centered {
  text-align: center;
}
.margin-0 {
  margin: 0;
}
.marginTop-5 {
  margin-top: 5px;
}
.marginTop-8 {
  margin-top: 8px;
}
.marginTop-10 {
  margin-top: 10px;
}
.marginTop-16 {
  margin-top: 16px;
}
.marginTop-20 {
  margin-top: 20px;
}
.marginTop-24 {
  margin-top: 24px;
}
.marginTop-30 {
  margin-top: 30px;
}
.marginTop-32 {
  margin-top: 32px;
}
.marginTop-40 {
  margin-top: 40px;
}
.marginTop-55 {
  margin-top: 55px;
}
.marginTop-60 {
  margin-top: 60px;
}
.marginTop-header {
  margin-top: 55px;
}
.marginBottom-footer {
  margin-bottom: 100px;
}
.marginBottom-5 {
  margin-bottom: 5px;
}
.marginBottom-8 {
  margin-bottom: 8px;
}
.marginBottom-10 {
  margin-bottom: 10px;
}
.marginBottom-16 {
  margin-bottom: 16px;
}
.marginBottom-20 {
  margin-bottom: 20px;
}
.marginBottom-24 {
  margin-bottom: 24px;
}
.marginBottom-32 {
  margin-bottom: 32px;
}
.marginBottom-40 {
  margin-bottom: 40px;
}
.marginLeft-8 {
  margin-left: 8px;
}
.marginLeft-10 {
  margin-left: 10px;
}
.marginLeft-16 {
  margin-left: 16px;
}
.marginLeft-20 {
  margin-left: 20px;
}
.marginLeft-24 {
  margin-left: 24px;
}
.marginLeft-32 {
  margin-left: 32px;
}
.marginLeft-40 {
  margin-left: 40px;
}
.marginRight-8 {
  margin-right: 8px;
}
.marginRight-10 {
  margin-right: 10px;
}
.marginRight-16 {
  margin-right: 16px;
}
.marginRight-20 {
  margin-right: 20px;
}
.marginRight-24 {
  margin-right: 24px;
}
.marginRight-40 {
  margin-right: 40px;
}
.padding-8 {
  padding: 8px;
}
.padding-10 {
  padding: 10px;
}
.padding-16 {
  padding: 16px;
}
.padding-18 {
  padding: 18px;
}
.padding-20 {
  padding: 20px;
}
.padding-32 {
  padding: 32px;
}
.padding-40 {
  padding: 40px;
}
.padding-60 {
  padding: 60px;
}
.paddingTop-8 {
  padding-top: 8px;
}
.paddingTop-18 {
  padding-top: 18px;
}
.paddingTop-40 {
  padding-top: 40px;
}
.paddingBottom-4 {
  padding-bottom: 4px;
}
.paddingBottom-8 {
  padding-bottom: 8px;
}
.paddingBottom-16 {
  padding-bottom: 16px;
}
.paddingBottom-20 {
  padding-bottom: 20px;
}
.paddingBottom-40 {
  padding-bottom: 40px;
}
.paddingVertical-8 {
  padding-top: 8px;
  padding-bottom: 8px;
}
.paddingVertical-16 {
  padding-top: 16px;
  padding-bottom: 16px;
}
.paddingVertical-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.paddingVertical-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}
.paddingVertical-60 {
  padding-top: 60px;
  padding-bottom: 60px;
}
.paddingHorizontal-8 {
  padding-left: 8px;
  padding-right: 8px;
}
.paddingHorizontal-16 {
  padding-left: 16px;
  padding-right: 16px;
}
.paddingHorizontal-32 {
  padding-left: 32px;
  padding-right: 32px;
}
.paddingHorizontal-40 {
  padding-left: 40px;
  padding-right: 40px;
}
.paddingRight-2 {
  padding-right: 2px;
}
.paddingRight-5 {
  padding-right: 5px;
}
.paddingRight-10 {
  padding-right: 10px;
}
.paddingRight-20 {
  padding-right: 20px;
}
.paddingRight-32 {
  padding-right: 32px;
}
.paddingRight-40 {
  padding-right: 40px;
}
.paddingLeft-2 {
  padding-left: 2px;
}
.paddingLeft-5 {
  padding-left: 5px;
}
.paddingLeft-10 {
  padding-left: 10px;
}
.paddingLeft-32 {
  padding-left: 32px;
}
.paddingLeft-40 {
  padding-left: 40px;
}
.paddingLeft-20 {
  padding-left: 20px;
}
.paddingTop-header {
  padding-top: 55px;
}
.paddingBottom-footer {
  padding-bottom: 100px;
}
.minHeight-400 {
  min-height: 400px;
}
.divideBorder {
  border: 1px solid #dbddf1;
}
.topDivideBorder {
  border-top: 2px solid #dbddf1;
}
.topDivideBorderThin {
  border-top: 1px solid #dbddf1;
}
.bottomDivideBorder {
  border-bottom: 2px solid #dbddf1;
}
.bottomDivideBorderThin {
  border-bottom: 1px solid #dbddf1;
}
.rightDivideBorder {
  border-right: 2px solid #dbddf1;
}
.genericBox {
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: #fff;
}
.borderRadius-2 {
  border-radius: 2px;
  overflow: hidden;
}
.borderRadius-4 {
  border-radius: 4px;
  overflow: hidden;
}
.flexVertCenter {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.flexRowCenter {
  display: flex;
  align-items: center;
}
.spinner {
  font-size: 26px;
  color: #597db4;
}
.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.titleWrapper {
  padding: 16px 32px 16px 32px;
  display: inline-block;
}
.titleWrapper span {
  color: #4a57b7;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 1px;
}
.addButton {
  -webkit-box-shadow: 1px 1px 8px rgba(53, 81, 108, 0.2);
  -moz-box-shadow: 1px 1px 8px rgba(53, 81, 108, 0.2);
  box-shadow: 1px 1px 8px rgba(53, 81, 108, 0.2);
  border-radius: 4px;
  display: flex;
  cursor: pointer;
  width: fit-content;
  overflow: hidden;
}
.addButton:hover .addButton__text {
  color: #364196;
}
.addButton:hover .addButton__right {
  background-color: #364196;
}
.addButton__text {
  padding: 0 16px;
  color: #5c90df;
  letter-spacing: 1px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 13px;
  min-width: 140px;
  line-height: 30px;
  display: block;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.addButton__right {
  width: 32px;
  color: #fff;
  background-color: #5c90df;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.addButton__icon {
  color: #fff;
  font-size: 13px;
  width: 100%;
  line-height: 30px;
  text-align: center;
}
.addButton--inactive {
  cursor: default;
}
.addButton--inactive:hover .addButton__text {
  color: #5c90df;
}
.addButton--inactive:hover .addButton__right {
  background-color: #5c90df;
}
.cornerCancelButton {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cornerCancelButton_icon {
  color: #ff6363;
  font-size: 20px;
  cursor: pointer;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.cornerCancelButton_icon:hover {
  color: #fc0000;
}
#tidio-chat-iframe {
  bottom: 50px !important;
}
#jsd-widget {
  z-index: 1000;
  bottom: 40px !important;
  right: 20px !important;
}
/* Input Styles */
::placeholder {
  color: #8695b2;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
}
.genericInputContainer {
  position: relative;
  margin-bottom: 16px;
}
.genericInputContainer .genericInput-help {
  margin-top: 4px;
}
.inputRequired {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #4a57b7;
  position: absolute;
  left: -13px;
}
.genericInput {
  border: none;
  border-bottom: 1px solid #dbddf1;
  width: 100%;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 16px;
  padding-bottom: 4px;
  line-height: 1.41;
  letter-spacing: 0.3px;
  color: #536280;
  overflow-y: hidden;
  resize: none;
  background-color: rgba(0, 0, 0, 0);
}
.genericInput:focus {
  border-bottom: 1px solid #536280;
}
.genericInput-help {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  color: #8695b2;
  font-size: 11px;
}
.genericInput-valid .inputRequired {
  background-color: #49bdbb;
}
.genericInput-error .inputRequired {
  background-color: #c02743;
}
.genericInput-error .genericInput {
  border-bottom: 1px solid #c02743;
}
.genericInput-error .genericInput:focus {
  border-bottom: 1px solid #c02743;
}
.genericInput-disabled .genericInput {
  border-bottom: 1px solid transparent !important;
}
.genericInput-large .genericInput {
  font-size: 26px;
  padding-bottom: 0;
}
.genericInput-large .inputRequired {
  top: 41px;
}
.genericInput-textarea .inputRequired {
  top: 28px;
}
.genericInputContainer-largeLabel .fieldLabel {
  font-size: 18px;
  color: #536280;
}
.highlightedHelp {
  font-size: 12px;
  color: #5c90df;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
}
.dropdownInput .genericInput {
  border-bottom: 0px solid transparent !important;
}
.dropdownInput .dropdownInput-bottomBorder {
  border-bottom: 1px solid #dbddf1;
  width: 100%;
  display: flex;
  position: relative;
}
.dropdownInput .dropdownInput-bottomBorder.dropdownInput-bottomBorder--disabled {
  border-bottom: 1px solid transparent;
}
.dropdownInput .dropdownInput-bottomBorder.dropdownInput-bottomBorder--error {
  border-bottom: 1px solid #c02743;
}
.select {
  cursor: pointer;
}
.select.validation-disabled {
  cursor: default;
}
.select_input {
  border: none;
  background-color: rgba(0, 0, 0, 0);
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
}
.select_input[disabled] {
  cursor: inherit;
}
.select_chevron {
  font-size: 12px;
  color: #8695b2;
  cursor: inherit;
}
.select_options {
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: #fff;
  position: absolute;
  top: 35px;
  left: 0;
  right: 0;
  z-index: 90;
  max-height: 220px;
  overflow-y: auto;
}
.select_option {
  padding: 8px 16px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #536280;
  cursor: pointer;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.select_option:hover {
  background-color: #536280;
  color: #fff;
}
.imageInputOuter {
  display: flex;
  height: 168px;
  flex-direction: row-reverse;
  overflow: hidden;
}
.imageInputOuter-single {
  width: 293px;
}
.imageInputOuter-single.imageInputOuter-noMenu {
  width: 205px;
}
.imageInputOuter-single .imageInputContainer {
  overflow-y: unset;
}
.imageInputOuter-grid {
  height: unset;
  display: block;
}
.imageInputOuter-grid .imageInputContainer {
  overflow: unset;
  white-space: unset;
}
.imageInputOuter-grid .imageInput {
  margin-top: 8px;
}
.imageInput {
  margin-right: 8px;
  position: relative;
  width: 205px;
  height: 168px;
  display: inline-block;
  vertical-align: top;
}
.imageInputRight {
  width: 80px;
  height: 100%;
  margin-left: 8px;
}
.imageInputRight_button {
  width: 80px;
  height: 80px;
  background-color: rgba(92, 144, 223, 0.5);
  margin-top: 8px;
  cursor: pointer;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}
.imageInputRight_button:hover {
  background-color: #5c90df;
}
.imageInputRight_button:first-of-type {
  margin-top: 0;
}
.imageInputRight_button_icon {
  width: 20px;
  height: 20px;
  font-size: 20px;
  text-align: center;
  color: #fff;
}
.imageInputRight_button_text {
  margin: 8px 0 0;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  color: #fff;
  font-size: 10px;
  text-align: center;
}
.imageInput_upload {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  border: dashed 1px #8695b2;
  cursor: pointer;
  background-color: #fff;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}
.imageInput_upload input {
  width: 1px;
}
.imageInput_upload:hover {
  background-color: #f4f7f9;
}
.imageInput_upload.imageInput_upload--error {
  border: dashed 1px #c02743;
}
.imageInput_upload.folder {
  margin-right: 8px;
  width: 205px;
  height: 168px;
  float: left;
}
.imageInput_dropZoneActive {
  background-color: #f4f7f9;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.imageInput_horizontalText {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;
}
.imageInput_horizontalText .imageInput_icon {
  margin: 0;
}
.imageInput_horizontalText .imageInput_helpText {
  margin: 0;
  font-size: 13px;
}
.imageInput_horizontalText .imageInput_button {
  margin: 0;
}
.imageInput_horizontalText .imageInput_button .button {
  padding: 4px 8px;
}
.imageInput_horizontalText .imageInput_button .buttonText {
  font-size: 12px;
}
.imageInput_icon {
  width: 25px;
  height: 32px;
  margin: 0 auto;
  display: block;
}
.imageInput_helpText {
  font-size: 15px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  color: #8695b2;
  margin-top: 20px;
  text-align: center;
}
.imageInput_image {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: none;
}
.imageInput_text {
  font-size: 15px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  color: #8695b2;
  margin: 10px 0 0 0;
  padding: 0 10px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.imageInput_button {
  text-align: center;
  margin-top: 16px;
}
.imageInput_uploading {
  display: none;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  border: dashed 1px #8695b2;
  align-items: center;
  justify-content: center;
}
.imageInput_spinner {
  margin: 0 auto;
  display: block;
}
.imageInput_remove {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 8px;
  background-color: #a4aada;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  color: #536280;
  cursor: pointer;
  display: none;
  border-radius: 0 2px 0 2px;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.imageInput_remove:hover {
  background-color: #536280;
  color: #a4aada;
}
.imageInput_removeIcon {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px;
  font-size: 20px;
  color: #536280;
  cursor: pointer;
  display: none;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.imageInput_removeIcon:hover {
  color: #67758f;
}
.imageInput_download {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 10px;
  background-color: #5c90df;
  cursor: pointer;
  display: none;
  border-radius: 2px 0 2px 0;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.imageInput_download:hover {
  background-color: #364196;
}
.dlicon {
  width: 20px;
}
.imageInput_delete {
  position: absolute;
  bottom: 11px;
  right: 11px;
  background-color: #fff;
  height: 24px;
  width: 24px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.imageInput_deleteIcon {
  font-size: 22px;
  color: #5c90df;
  cursor: pointer;
  line-height: 24px;
  text-align: center;
}
.imageInput_selected {
  position: absolute;
  top: 11px;
  right: 11px;
  background-color: #fff;
  height: 24px;
  width: 24px;
  border-radius: 12px;
  opacity: 0;
  -moz-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}
.imageInput_selectedIcon {
  font-size: 14px;
  line-height: 24px;
  width: 24px;
  text-align: center;
  color: #5c90df;
}
.imageInput-hasImage .imageInput_upload {
  display: none;
}
.imageInput-hasImage .imageInput_remove,
.imageInput-hasImage .imageInput_removeIcon,
.imageInput-hasImage .imageInput_download,
.imageInput-hasImage .imageInput_image {
  display: block;
}
.imageInput-uploading .imageInput_upload {
  display: none;
}
.imageInput-uploading .imageInput_uploading {
  display: flex;
}
.imageInput-only {
  overflow: hidden;
  margin: 0;
}
.imageInput-only .imageInput_image,
.imageInput-only .imageInput_upload,
.imageInput-only .imageInput_uploading {
  width: 100%;
  height: 100%;
}
.imageInput-libraryImage .imageInput_image {
  cursor: pointer;
  border: solid 3px #e2e7ee;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.imageInput-libraryImage .imageInput_image:hover {
  border-color: #597db4;
}
.imageInput-librarySelected .imageInput_image {
  border-color: #597db4;
}
.imageInput-librarySelected .imageInput_selected {
  opacity: 1;
}
.imageInput-condensed .imageInput_upload {
  padding: 10px 0;
}
.imageInput-condensed .imageInput_helpText {
  margin: 10px 0;
}
.imageInput-condensed .imageInput_button {
  margin-top: 10px;
}
.imageInputMenu {
  width: 100%;
  text-align: center;
  margin-bottom: 16px;
}
.imageInputMenu__option {
  cursor: pointer;
  display: inline-block;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  font-size: 13px;
  padding: 4px;
  margin: 0 16px;
  letter-spacing: 2px;
  color: #8695b2;
  border-bottom: solid 2px rgba(0, 0, 0, 0);
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.imageInputMenu__option:hover {
  color: #5c90df;
}
.imageInputMenu__option--selected {
  color: #5c90df;
  border-bottom-color: #5c90df;
}
.imageInputContainer {
  overflow-y: auto;
  white-space: nowrap;
  max-width: 100%;
  flex: 1;
}
.imageInputContainer-noPadding {
  padding: 0;
}
.imageInputContainer-simple {
  background-color: #fff;
}
.imageInputContainer-simple .imageInput-hasImage {
  border-radius: 4px;
  border: dashed 1px #8695b2;
  overflow: hidden;
}
.imageInputContainer-simple .imageInput-hasImage .imageInput_image {
  margin-top: 10px;
}
.imageInputContainer-simple .imageInput-hasImage .imageInput_button {
  margin-top: 0;
}
.imageInputContainer-library {
  min-width: 600px;
  background-color: #fff;
  padding: 0;
  overflow: unset;
  white-space: unset;
}
.imageInputContainer-library .imageInput,
.imageInputContainer-library .imageInput_upload {
  margin-top: 8px;
}
.fileInput,
.hiddenInput {
  width: 0;
  height: 0;
  opacity: 0;
  display: block;
  position: absolute;
  left: -20000px;
}
.imageBreadCrumb {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 15px;
}
.imageBreadCrumb .root {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  font-size: 24px;
  color: #536280;
  opacity: 0.5;
  cursor: pointer;
}
.imageBreadCrumb .separator {
  height: unset;
  background-color: unset;
  font-size: 24px;
  margin-left: 15px;
  margin-right: 15px;
  color: #5c90df;
}
.imageBreadCrumb .folder {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  font-size: 24px;
  color: #536280;
}
.imageFolderContainer {
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: #fff;
  margin-bottom: 10px;
  padding: 24px 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.imageFolderContainer .addIcon {
  color: #5c90df;
  margin-right: 15px;
  font-size: 20px;
}
.imageFolderContainer .addText {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #5c90df;
  flex: 1;
}
.imageFolderContainer .addControlContainer {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}
.imageFolderContainer .folderIcon {
  color: #a1afcc;
  margin-right: 15px;
  font-size: 20px;
}
.imageFolderContainer .folderTitle {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #5a5a6e;
  flex: 1;
}
.imageFolderContainer .folderAttributeGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.imageFolderContainer .folderAttribute {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #536280;
  width: 100px;
}
.imageFolderContainer:hover {
  -webkit-box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
.imageLibrary {
  width: 100%;
}
.imageLibrary .imageLibraryTitleSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
}
.imageLibrary .imageLibraryTitleSection .libraryTitle {
  font-family: 'Source Sans Pro';
  font-size: 36px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.51;
  letter-spacing: 0.72px;
  color: #536280;
  display: flex;
  align-items: center;
}
.imageLibrary .imageLibraryTitleSection .libraryTitle .folderSeparator {
  font-size: 12px;
  padding: 0 12px;
  color: #5c90df;
}
.imageLibrary .imageLibraryTitleSection .searchFolder {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.imageLibrary .imageLibraryTitleSection .searchFolder .searchIcon {
  font-size: 14px;
  color: #8695b2;
}
.imageLibrary .imageLibraryTitleSection .searchFolder .genericInput {
  width: 86px;
  font-size: 14px;
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 1px solid transparent;
}
.imageLibrary .imageLibraryTitleSection .searchFolder .genericInput:focus {
  border-bottom: 1px solid #d5dde4;
}
.imageLibrary .imageLibraryPublishedImages {
  border-bottom: 1px solid #e7ecf1;
}
.imageLibrary .imageLibraryPublishedImages .title {
  font-family: 'Source Sans Pro';
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.51;
  letter-spacing: 1.12px;
  color: #5c90df;
  margin-bottom: 27px;
}
.imageLibrary .imageLibraryPublishedImages .publishedImages {
  display: flex;
  flex-direction: row;
  gap: 25px;
  overflow: auto;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.imageLibrary .imageLibraryPublishedImages .publishedImages::-webkit-scrollbar {
  display: block;
}
.imageLibrary .imageLibraryContents {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 25px;
  align-content: flex-start;
  padding-top: 23px;
}
.imageLibraryPublicFolderContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 545px;
  height: 220px;
  border-radius: 4px;
  background-color: #f4f7f9;
  box-shadow: 0 3px 30px 0 rgba(103, 125, 169, 0.07);
  cursor: pointer;
}
.imageLibraryPublicFolderContainer .leftSection {
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f4f7f9;
  border-radius: 4px;
}
.imageLibraryPublicFolderContainer .leftSection .folderIcon {
  background-repeat: no-repeat;
  background-size: contain;
  height: 130px;
  width: 90px;
  background-image: url('./assets/images/publishingFolder.png');
  margin-right: 10px;
  margin-top: 20px;
}
.imageLibraryPublicFolderContainer .rightSection {
  width: 325px;
  background-color: #ffffff;
  border-radius: 4px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.imageLibraryPublicFolderContainer .rightSection .publishingSwitch {
  position: absolute;
  top: 20px;
  right: 20px;
}
.imageLibraryPublicFolderContainer .rightSection .publishingSwitch .switchText {
  position: absolute;
  display: flex;
  align-items: center;
  font-family: 'Source Sans Pro';
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.51;
  letter-spacing: normal;
  color: #ffffff;
}
.imageLibraryPublicFolderContainer .rightSection .publishingSwitch .switchText.off {
  top: 0;
  left: -12px;
  bottom: 0;
  right: 0;
}
.imageLibraryPublicFolderContainer .rightSection .publishingSwitch .switchText.on {
  top: 0;
  left: 8px;
  bottom: 0;
  right: -12px;
}
.imageLibraryPublicFolderContainer .rightSection .folderTitle {
  font-family: 'Source Sans Pro';
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.8px;
  color: #5c90df;
}
.imageLibraryPublicFolderContainer .rightSection .folderInfo {
  display: flex;
  flex-direction: row;
  font-family: 'Source Sans Pro';
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5f717f;
}
.imageLibraryPublicFolderContainer .rightSection .folderInfo .publishingState {
  font-weight: bold;
}
.imageLibraryFolderContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 260px;
  height: 220px;
  border-radius: 4px;
  background-color: #f4f7f9;
  box-shadow: 0 3px 30px 0 rgba(103, 125, 169, 0.07);
  cursor: pointer;
}
.imageLibraryFolderContainer .topSection {
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f4f7f9;
  border-radius: 4px;
}
.imageLibraryFolderContainer .topSection .editFolderContainer {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  box-shadow: 0 1px 10px 0 rgba(103, 125, 169, 0.1);
  background-color: #ffffff;
  cursor: pointer;
}
.imageLibraryFolderContainer .topSection .editFolderContainer .editFolderIcon {
  color: #5c90df;
  font-size: 15px;
}
.imageLibraryFolderContainer .topSection .folderIcon {
  background-repeat: no-repeat;
  background-size: contain;
  height: 66px;
  width: 82px;
  background-image: url('./assets/images/folder.png');
}
.imageLibraryFolderContainer .bottomSection {
  height: 70px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 4px;
  padding: 14px 18px 13px 18px;
}
.imageLibraryFolderContainer .bottomSection .folderTitle {
  font-family: 'Source Sans Pro';
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.64px;
  color: #5f717f;
}
.imageLibraryFolderContainer .bottomSection .imageCount {
  font-family: 'Source Sans Pro';
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5f717f;
}
.imageLibraryFolderContainer .doneButton {
  position: absolute;
  right: 0;
  bottom: -40px;
}
.galleryImageContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 20%;
  height: 150px;
  background-color: #f4f7f9;
  box-shadow: 0 3px 30px 0 rgba(103, 125, 169, 0.07);
  border-radius: 2px;
  overflow: hidden;
}
.galleryImageContainer .topSection {
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f4f7f9;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.galleryImageContainer .topSection .imageLibraryIcon {
  background-repeat: no-repeat;
  background-size: contain;
  height: 36px;
  width: 46px;
  background-image: url('./assets/images/imageLibrary.png');
}
.galleryImageContainer .bottomSection {
  height: 40px;
  width: 100%;
  background-color: #ffffff;
  padding: 7px 7px 0 7px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.galleryImageContainer .bottomSection .imageDate {
  font-family: 'Source Sans Pro';
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.44px;
  color: #5f717f;
  overflow: auto;
}
.galleryImageContainer .bottomSection .imageLibraryText {
  font-family: 'Source Sans Pro';
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5c90df;
  text-align: center;
}
.imageLibraryImageContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 260px;
  height: 220px;
  border-radius: 4px;
  background-color: #f4f7f9;
  box-shadow: 0 3px 30px 0 rgba(103, 125, 169, 0.07);
  margin-bottom: 23px;
}
.imageLibraryImageContainer .topSection {
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f4f7f9;
  border-radius: 4px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.imageLibraryImageContainer .topSection .deleteImageContainer {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  box-shadow: 0 1px 10px 0 rgba(103, 125, 169, 0.1);
  background-color: #ffffff;
  cursor: pointer;
}
.imageLibraryImageContainer .topSection .deleteImageContainer .deleteImageIcon {
  color: #ff8c75;
  font-size: 15px;
}
.imageLibraryImageContainer .bottomSection {
  height: 60px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 4px;
  padding-left: 7px;
  padding-right: 7px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.imageLibraryImageContainer .bottomSection .imageDate {
  font-family: 'Source Sans Pro';
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #262a2d;
}
.imageLibraryImageContainer .bottomSection .imageBy {
  font-family: 'Source Sans Pro';
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.51;
  letter-spacing: normal;
  color: #536280;
}
.imageLibraryPopupBackground {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
  width: 100%;
  height: 100%;
  background-color: rgba(60, 60, 80, 0.5);
}
.imageLibraryPopupBackground .uploaderPopup {
  display: flex;
  flex-direction: column;
  width: 810px;
  height: 630px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  border-radius: 10px;
}
.imageLibraryPopupBackground .uploaderPopup .uploaderInner {
  flex-direction: column;
  padding: 40px;
  display: flex;
  flex: 1;
  overflow-y: auto;
}
.imageLibraryPopupBackground .uploaderPopup .uploaderInner .titleContainer {
  display: flex;
  align-items: center;
  height: 40px;
}
.imageLibraryPopupBackground .uploaderPopup .uploaderInner .titleContainer .title {
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
  font-size: 26px;
  color: #4a57b7;
  display: inline-block;
}
.imageLibraryPopupBackground .uploaderPopup .uploaderInner .uploadInputContainer {
  flex: 1;
  margin-right: 24px;
}
.imageLibraryPopupBackground .uploaderPopup .uploaderInner .guidelineContainer .title {
  font-family: 'Quicksand', sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #4a57b7;
  margin-bottom: 24px;
}
.imageLibraryPopupBackground .uploaderPopup .uploaderInner .guidelineContainer .text {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #536280;
  margin-bottom: 16px;
}
.imageLibraryPopupBackground .publishedPopup {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 810px;
  height: 250px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  border-radius: 10px;
}
.imageLibraryPopupBackground .publishedPopup .topSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 50px 58px 40px;
}
.imageLibraryPopupBackground .publishedPopup .topSection .publishedIcon {
  color: #a1afcc;
  font-size: 100px;
}
.imageLibraryPopupBackground .publishedPopup .topSection .publishedText {
  margin-left: 50px;
  font-family: 'Source Sans Pro';
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.51;
  letter-spacing: 0.6px;
  color: #536280;
}
/* Button Styles */
.checkBox {
  display: inline-block;
  height: 20px;
  width: 20px;
  border: 1px solid #d2d9e5;
  border-radius: 4px;
  align-items: center;
  cursor: pointer;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.checkBox:hover:not(.checkBox--disabled):not(.checkBox--noHoverHighlight) {
  border: 1px solid #4a57b7;
  background-color: #4a57b7;
}
.checkBoxTick {
  width: 100%;
  height: 100%;
  text-align: center;
  display: none;
  justify-content: center;
  align-items: center;
}
.checkBoxTick-font {
  color: #fff;
}
.checkBox--active {
  border: 1px solid #4a57b7;
  background-color: #4a57b7;
}
.checkBox--active:hover:not(.checkBox--disabled):not(.checkBox--noHoverHighlight) {
  border: 1px solid #4a57b7;
  background-color: #4a57b7;
}
.checkBox--active .checkBoxTick {
  display: inline-block;
}
.checkBox--error {
  color: #c02743;
}
.checkBox--error .checkBox {
  border: 1px solid #c02743;
}
.checkBox--disabled {
  cursor: default;
  opacity: 0.5;
}
.checkbox-updated .actionText {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #536280;
}
.radioButtonWrapper .actionText {
  line-height: 18px;
  margin-left: 8px;
}
.radioButtonWrapper:not(.radioButton--disabled):not(.radioButton--noHoverHighlight) {
  cursor: pointer;
}
.radioButtonWrapper:not(.radioButton--disabled):not(.radioButton--noHoverHighlight):hover .radioButton {
  border: 5px solid #4a57b7;
}
.radioButton {
  height: 18px;
  width: 18px;
  border: 1px solid #a1afcc;
  border-radius: 10px;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.radioButton--active {
  border: 5px solid #4a57b7;
}
.radioButton--square {
  border-radius: 2px;
}
.tagWrapper__hoverContent {
  display: none;
}
.tagWrapper {
  text-align: center;
  border-radius: 4px;
  width: 100px;
  height: 24px;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  cursor: pointer;
}
.tagWrapper:hover .tagWrapper__content {
  display: none;
}
.tagWrapper:hover .tagWrapper__hoverContent {
  display: inline-block;
}
.tagWrapper__text {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  font-size: 13px;
  color: #fff;
  line-height: 24px;
  margin: 0;
}
.tagWrapper--active {
  background-color: #5e9efc;
}
.tagWrapper--active:hover {
  background-color: #c02743;
}
.tagWrapper--inactive {
  background-color: #a1afcc;
}
.tagWrapper--inactive:hover {
  background-color: #00cc9c;
}
.buttonWrapper {
  display: block;
}
.buttonWrapper--inline {
  display: inline-block;
}
.button {
  display: inline-block;
  align-items: center;
  justify-content: center;
  background-color: #d2d9e5;
  padding: 4px 16px;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  border-color: transparent;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
}
.buttonText {
  font-size: 15px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  text-align: center;
  color: #717b81;
  line-height: 1.5;
  vertical-align: middle;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
}
.button--primary .button,
.button--primaryAction .button {
  background-color: #4a57b7;
  box-shadow: 0 2px 0 0 #0000 inset;
  opacity: 0.5;
}
.button--primary .buttonText,
.button--primaryAction .buttonText {
  color: white;
  opacity: 0.5;
}
.button--primary.button--active:not(.button--noHoverHighlight):hover .button,
.button--primaryAction.button--active:not(.button--noHoverHighlight):hover .button {
  box-shadow: 0 -2px 0 0 #364196 inset;
}
.button--primary.button--active .button,
.button--primaryAction.button--active .button {
  opacity: 1;
}
.button--primary.button--active .button:active,
.button--primaryAction.button--active .button:active {
  background-color: #364196;
}
.button--primary.button--active .buttonText,
.button--primaryAction.button--active .buttonText {
  opacity: 1;
}
.button--tealAction .button {
  background-color: #dbddf1;
}
.button--tealAction .buttonText {
  color: white;
}
.button--tealAction.button--active .button {
  background-color: #49bdbb;
}
.button--tealAction.button--active .button:hover,
.button--tealAction.button--active .button:active {
  background-color: #3faeac;
}
.button--secondary .button {
  background-color: rgba(134, 149, 178, 0.16);
  box-shadow: 0 2px 0 0 #0000 inset;
}
.button--secondary .buttonText {
  color: #8695b2;
}
.button--secondary.button--active .button {
  background-color: #8695b2;
}
.button--secondary.button--active .button:hover {
  box-shadow: 0 -2px 0 0 #536280 inset;
}
.button--secondary.button--active .button:active {
  background-color: #536280;
}
.button--secondary.button--active .buttonText {
  color: white;
}
.button--outlined .button {
  background-color: #fff;
  border-color: #a4aada;
}
.button--outlined .buttonText {
  color: #a4aada;
}
.button--outlined.button--active .button {
  border-width: 1px;
  border-color: #4a57b7;
}
.button--outlined.button--active .button:hover {
  background-color: #4a57b7;
}
.button--outlined.button--active .button:hover .buttonText {
  color: #fff;
}
.button--outlined.button--active .button:active {
  border-color: #364196;
  background-color: #364196;
}
.button--outlined.button--active .button:active .buttonText {
  color: #fff;
}
.button--outlined.button--active .buttonText {
  color: #4a57b7;
}
.button--outlinedAction .button {
  background-color: #fff;
  border-color: #a4aada;
}
.button--outlinedAction .buttonText {
  color: #a4aada;
}
.button--outlinedAction.button--active .button {
  border-width: 1px;
  border-color: #5c90df;
}
.button--outlinedAction.button--active .button:hover {
  background-color: #5c90df;
}
.button--outlinedAction.button--active .button:hover .buttonText {
  color: #fff;
}
.button--outlinedAction.button--active .button:active {
  border-color: #364196;
  background-color: #364196;
}
.button--outlinedAction.button--active .button:active .buttonText {
  color: #fff;
}
.button--outlinedAction.button--active .buttonText {
  color: #5c90df;
}
.button--outlinedGrey .button {
  background-color: #fff;
  border-color: #a4aada;
}
.button--outlinedGrey .buttonText {
  color: #a4aada;
}
.button--outlinedGrey.button--active .button {
  border-width: 1px;
  border-color: #8695b2;
}
.button--outlinedGrey.button--active .button:hover {
  background-color: #8695b2;
}
.button--outlinedGrey.button--active .button:hover .buttonText {
  color: #fff;
}
.button--outlinedGrey.button--active .button:active {
  border-color: #8695b2;
  background-color: #8695b2;
}
.button--outlinedGrey.button--active .button:active .buttonText {
  color: #fff;
}
.button--outlinedGrey.button--active .buttonText {
  color: #8695b2;
}
.button--tertiary .button {
  background-color: rgba(0, 0, 0, 0);
}
.button--tertiary .buttonText {
  color: rgba(74, 87, 183, 0.5);
}
.button--tertiary.button--active .button:hover .buttonText {
  color: #364196;
}
.button--tertiary.button--active .button:active .buttonText {
  color: #364196;
}
.button--tertiary.button--active .buttonText {
  color: #4a57b7;
}
.button--boxed .button {
  background-color: #fff;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  opacity: 0.5;
  border: none;
  padding: 8px;
}
.button--boxed .button.button--boxed-height-50 {
  height: 50px;
}
.button--boxed .button.button--boxed-height-50 .buttonText {
  line-height: 34px;
}
.button--boxed .button.button--boxed-dusk .buttonText {
  color: #536280;
}
.button--boxed .buttonText {
  color: #4a57b7;
  font-size: 16px;
  margin: 0;
  letter-spacing: 0;
}
.button--boxed.button--active .button {
  opacity: 1;
}
.button--boxed.button--active .button:hover,
.button--boxed.button--active .button:active {
  -webkit-box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
.button--text .button {
  background-color: #fff;
}
.button--text .buttonText {
  color: #597db4;
}
.button--text.button--active .button:hover .buttonText {
  color: #364196;
}
.button--text.button--active .button:active .buttonText {
  color: #364196;
}
.button--text.button--active .buttonText {
  color: #4a57b7;
}
.button--text .button {
  background-color: #fff;
}
.button--text .buttonText {
  color: #597db4;
}
.button--text.button--active .button:hover .buttonText {
  color: #364196;
}
.button--text.button--active .button:active .buttonText {
  color: #364196;
}
.button--text.button--active .buttonText {
  color: #5c90df;
}
.button--toggle .button {
  background-color: #e2e7ed;
  border-radius: 0px;
}
.button--toggle.toggleFirst .button {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.button--toggle.toggleLast .button {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.button--toggle.button--active .button {
  background-color: #536280;
}
.button--toggle.button--active .buttonText {
  color: white;
}
.button--signup .button {
  display: flex;
  width: 160px;
  height: 42px;
}
.button--signupFooter .button {
  width: 240px;
  background-color: #fff;
  height: 42px;
  display: flex;
}
.button--signupFooter .buttonText {
  color: #ff6363;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  font-size: 16px;
}
.button--signupFooter.button--active .button:active,
.button--signupFooter.button--active .button:hover {
  background-color: #ff6363;
}
.button--signupFooter.button--active .button:active .buttonText,
.button--signupFooter.button--active .button:hover .buttonText {
  color: #fff;
}
.buttonTextWithIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.buttonTextWithIcon_icon {
  margin-right: 4px;
}
.imageInput_buttonContainer-simple {
  height: 100%;
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px;
  overflow: hidden;
}
.imageInput_buttonContainer-simple .imageInput_button-simple {
  margin-top: 0;
}
.imageInput_buttonContainer-simple .imageInput_button-simple .button {
  background-color: rgba(0, 0, 0, 0);
}
.imageInput_buttonContainer-simple .imageInput_button-simple .buttonText {
  color: rgba(0, 0, 0, 0);
}
.imageInput_buttonContainer-simple:hover .imageInput_button-simple .button {
  background-color: #4a57b7;
}
.imageInput_buttonContainer-simple:hover .imageInput_button-simple .buttonText {
  color: #fff;
}
.featurePickerButton {
  display: flex;
  width: 120px;
  height: 34px;
  background-color: #fecdcd;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  padding: 4px 8px;
  align-items: center;
  border-radius: 4px;
}
.featurePickerButton:hover,
.featurePickerButton:active {
  background-color: #ffa7a7;
}
.featurePickerButton_text {
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
  color: #ee6060;
  flex: 1;
  text-align: center;
  font-size: 16px;
}
.featurePickerButton_icon {
  height: 25px;
}
.featurePickerButton--purple {
  background-color: #dfd0df;
}
.featurePickerButton--purple .featurePickerButton_text {
  color: #a761a7;
}
.featurePickerButton--purple:hover,
.featurePickerButton--purple:active {
  background-color: #e2a3e2;
}
.featurePickerButton--blue {
  background-color: #c1d2f0;
}
.featurePickerButton--blue .featurePickerButton_text {
  color: #5674a8;
}
.featurePickerButton--blue:hover,
.featurePickerButton--blue:active {
  background-color: #a2bff3;
}
.buttonWrapper--compact .button {
  padding: 2px 8px;
}
.buttonWrapper--compact .button .buttonText {
  font-size: 12px;
}
.buttonWrapper--narrow .button {
  padding: 2px 16px;
}
.buttonWrapper--narrow .button .buttonText {
  font-size: 12px;
}
.aCheck {
  cursor: pointer;
  width: 25px;
  height: 25px;
  border-radius: 13px;
  background-color: rgba(255, 255, 255, 0.4);
}
.aCheck:hover {
  background-color: #597db4;
}
.aCheck:hover .aCheck-font {
  color: #262a2d;
}
.aCheck:hover:active {
  background-color: #364196;
}
.aCheck:hover:active .aCheck-font {
  color: white;
}
.aCheck.aCheck--disbaled {
  cursor: default;
}
.aCheck.aCheck--disbaled:hover {
  background-color: rgba(255, 255, 255, 0.4);
}
.aCheck.aCheck--disbaled:hover .aCheck-font {
  color: #364196;
}
.aCheck.aCheck--disbaled:hover:active {
  background-color: rgba(255, 255, 255, 0.4);
}
.aCheck.aCheck--disbaled:hover:active .aCheck-font {
  color: #364196;
}
.aCheck.aCheck--active {
  background-color: #fff;
}
.aCheck.aCheck--active .aCheck-font {
  color: #364196;
}
.aCheck.aCheck--active.aCheck--disbaled {
  cursor: default;
}
.aCheck.aCheck--active.aCheck--disbaled:hover {
  background-color: #fff;
}
.aCheck.aCheck--active.aCheck--disbaled:hover .aCheck-font {
  color: #364196;
}
.aCheck.aCheck--active.aCheck--disbaled:hover:active {
  background-color: #fff;
}
.aCheck.aCheck--active.aCheck--disbaled:hover:active .aCheck-font {
  color: #364196;
}
.oldtag {
  margin: 0 8px 8px 0;
  display: inline-block;
  padding: 8px 20px;
  border-radius: 30px;
  font-size: 13px;
  white-space: nowrap;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  background-color: #5c90df;
  color: #fff;
  opacity: 0.7;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, opacity 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, opacity 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, opacity 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, opacity 0.2s ease-in-out;
}
.oldtag_tick {
  display: none;
  font-size: 15px;
  margin-right: 4px;
}
.oldtag_edit {
  display: none;
  font-size: 15px;
  margin-left: 4px;
}
.oldtag_delete {
  font-size: 15px;
  margin-left: 4px;
}
.oldtag-selected {
  background-color: #5c90df;
  opacity: 1;
}
.oldtag-selected .oldtag_tick,
.oldtag-selected .oldtag_edit {
  display: inline;
}
.oldtag-clickable {
  cursor: pointer;
}
.oldtag-clickable:hover {
  opacity: 1;
}
.aCheck-Tick {
  width: 100%;
  height: 100%;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.aCheck-font {
  font-size: 14px;
  color: #364196;
}
.plussDropdown .btn-group {
  vertical-align: baseline !important;
}
.actionIcon {
  cursor: pointer;
  font-size: 25px;
  color: rgba(255, 255, 255, 0.4);
}
.actionIcon:hover {
  color: #597db4;
}
.actionIcon:hover:active {
  color: #364196;
}
.datepicker {
  width: 300px;
  background-color: #fff;
}
.datepicker__top {
  background-color: #5c90df;
  padding: 30px 24px 10px;
  height: 80px;
}
.datepicker__multitop {
  padding: 16px 16px 0;
}
.datepicker__multitop__text {
  margin: 0;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #5c90df;
  text-align: center;
}
.datepicker__title {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  color: #fff;
  font-size: 24px;
}
.datepicker__bottom {
  padding: 16px;
}
.datepicker__bottomHeader {
  display: flex;
  flex-direction: row;
}
.datepicker__selectedDate {
  width: 100px;
  height: 32px;
  text-align: center;
  border-radius: 2px;
  border: solid 1px #dbddf1;
}
.datepicker__selectedDateText {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  color: #3f4264;
  font-size: 16px;
  line-height: 28px;
}
.datepicker__monthSelector {
  height: 32px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.datepicker__monthChevron {
  font-size: 10px;
  color: #5c90df;
  cursor: pointer;
}
.datepicker__selectedMonth {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #3f4264;
  margin: 0 8px;
  min-width: 110px;
  text-align: center;
}
.datepicker__row {
  flex-direction: row;
  display: flex;
}
.datepicker__colHeader {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  color: #8695b2;
  font-size: 13px;
  letter-spacing: 1px;
  flex: 1;
  text-align: center;
  margin: 0;
}
.datepicker__date {
  flex: 1;
  margin: 0;
}
.datepicker__date__inner {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: solid 1px rgba(0, 0, 0, 0);
  text-align: center;
  color: #3f4264;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  margin: 0 auto;
  cursor: pointer;
}
.datepicker__date__inner:hover {
  color: #0c1f3a;
  border-color: #597db4;
  background-color: #597db4;
}
.datepicker__date--faded .datepicker__date__inner {
  color: #8695b2;
}
.datepicker__date--today .datepicker__date__inner {
  color: #5c90df;
  border-color: #5c90df;
}
.datepicker__date--selected .datepicker__date__inner {
  color: #fff;
  border-color: #5c90df;
  background-color: #5c90df;
}
.dishDatePicker {
  background-color: #fff;
  margin-top: 16px;
  width: 300px;
}
.dishDatePicker_title {
  padding: 16px;
  padding-bottom: 0;
  margin: 0;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  color: #536280;
  font-size: 16px;
}
.timepicker {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #d5dde4;
  align-items: center;
}
.timepicker.disabled {
  border-bottom: none;
  padding-left: 1px;
}
.timepicker__input {
  color: #3f4264;
  background-color: rgba(0, 0, 0, 0);
  letter-spacing: 0.3px;
  font-size: 16px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  border: none;
  width: 28px;
  height: 24px;
  text-align: center;
}
.timepicker__span {
  color: #3f4264;
  background-color: rgba(0, 0, 0, 0);
  letter-spacing: 0.3px;
  font-size: 16px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  border: none;
  height: 24px;
  text-align: center;
}
.timepicker__separator {
  color: #3f4264;
  letter-spacing: 0.3px;
  font-size: 16px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  line-height: 24px;
}
.timepicker_clear {
  cursor: pointer;
  width: 10px;
  height: 10px;
  margin-left: 4px;
}
.timepicker-condensed .timepicker__input {
  width: 22px;
  padding: 0;
}
.timepicker-condensed .timepicker__input,
.timepicker-condensed .timepicker__span,
.timepicker-condensed .timepicker__separator {
  font-size: 13px;
  letter-spacing: 0;
}
.eventCalendar {
  min-width: 1100px;
  width: 100%;
}
.eventCalendar__bottom {
  padding: 16px;
}
.eventCalendar__monthSelector {
  height: 32px;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.eventCalendar__monthChevron {
  font-size: 10px;
  color: #5c90df;
  cursor: pointer;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.eventCalendar__monthChevron:hover {
  color: #364196;
}
.eventCalendar__selectedMonth {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #3f4264;
  margin: 0 8px;
  min-width: 110px;
  text-align: center;
}
.eventCalendar__row {
  flex-direction: row;
  display: flex;
}
.eventCalendar__colHeader {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  color: #3f4264;
  font-size: 14px;
  flex: 1;
  text-align: center;
  margin: 0;
  padding: 8px;
  border: dashed 1px #dbddf1;
  -moz-transition: opacity 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}
.eventCalendar__date {
  flex: 1;
  margin: 0;
  height: 160px;
  padding: 8px;
  border: dashed 1px #dbddf1;
  overflow: hidden;
  background-color: #fff;
  -moz-transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
  -o-transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
  -webkit-transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
}
.eventCalendar__date__content {
  -moz-transition: transform 0.2s ease-in-out;
  -o-transition: transform 0.2s ease-in-out;
  -webkit-transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
}
.eventCalendar__date__inner {
  text-align: center;
  color: #3f4264;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  margin: 0 auto;
}
.eventCalendar__event {
  background-color: #597db4;
  border-radius: 2px;
  margin-top: 4px;
  padding: 0 8px;
  width: 100%;
  height: 24px;
  white-space: nowrap;
  color: #fff;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.eventCalendar__event:hover {
  height: auto;
  white-space: normal;
  -ms-text-overflow: initial;
  -o-text-overflow: initial;
  text-overflow: initial;
  background-color: #5c90df;
}
.eventCalendar__eventTime {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
  color: #fff;
  margin-right: 4px;
  line-height: 24px;
  font-size: 14px;
}
.eventCalendar__eventTitle {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  color: #fff;
  line-height: 24px;
  font-size: 14px;
}
.eventCalendar__moreEvents {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  color: #5c90df;
  line-height: 24px;
  font-size: 14px;
  cursor: pointer;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.eventCalendar__moreEvents:hover {
  color: #364196;
}
.eventCalendar__date--faded .eventCalendar__date__inner {
  color: #8695b2;
}
.eventCalendar__date--today .eventCalendar__date__inner {
  color: #5c90df;
}
.eventCalendar__date--selected {
  border-style: solid;
  transform: scale(1.0526);
  z-index: 2;
}
.eventCalendar__date--selected .eventCalendar__date__content {
  transform: scale(0.95);
}
.eventCalendar--hasSelected .eventCalendar__date:not(.eventCalendar__date--selected),
.eventCalendar--hasSelected .eventCalendar__colHeader {
  opacity: 0.5;
}
.selectedEventDate {
  position: fixed;
  width: 400px;
  height: 100%;
  top: 0;
  left: -400px;
  background-color: #fff;
  z-index: 100;
  -moz-transition: transform 0.2s ease-in-out;
  -o-transition: transform 0.2s ease-in-out;
  -webkit-transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.selectedEventDate__scroll {
  width: 100%;
  height: 100%;
  padding: 24px;
  overflow-y: auto;
}
.selectedEventDate--shown {
  transform: translateX(400px);
}
.selectedEventDate__close {
  position: absolute;
  top: 24px;
  right: 24px;
  font-size: 18px;
  cursor: pointer;
}
.selectedEventDate__close .fa {
  color: #536280;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.selectedEventDate__close .fa:hover {
  color: #262a2d;
}
.selectedEventDate__day {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #8695b2;
  margin-top: 48px;
  margin-bottom: 0;
}
.selectedEventDate__date {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  font-size: 36px;
  color: #3f4264;
  margin-bottom: 0;
  letter-spacing: 1px;
}
.selectedEventDate__events {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #3f4264;
  margin-top: 24px;
  margin-bottom: 0;
}
.selectedEventDate__event {
  position: relative;
  padding: 16px;
  border-left: solid 16px #dbddf1;
  margin-top: 24px;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.selectedEventDate__eventTitle {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #262a2d;
  margin: 0;
}
.selectedEventDate__eventTime {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #536280;
  margin: 0;
}
.selectedEventDate__eventLocation {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #536280;
  margin: 0;
}
.selectedEventDate__eventAttendanceContainer {
  margin-top: 8px;
}
.selectedEventDate__eventAttendance {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  color: #536280;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  float: left;
}
.selectedEventDate__eventAttendance .fa {
  line-height: 20px;
  color: #536280;
  font-size: 14px;
  margin-right: 8px;
}
.selectedEventDate__eventAttendanceFull {
  margin: 0;
  margin-left: 16px;
  float: left;
  border-radius: 2px;
  background-color: #0c1f3a;
  color: #fff;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  line-height: 20px;
  font-size: 12px;
  padding: 0 8px;
}
.selectedEventDate__edit {
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 14px;
  color: #5c90df;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.selectedEventDate__edit:hover {
  color: #364196;
}
.attendanceEntry {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 40px;
  color: #4a57b7;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  cursor: pointer;
  margin: 0 0 8px 0;
  padding: 0 0 0 30px;
  width: 240px;
  border-radius: 0 4px 4px 0;
}
.attendanceEntry:hover {
  background-color: rgba(74, 87, 183, 0.2);
}
.attendanceEntry-selected {
  background-color: rgba(74, 87, 183, 0.2);
}
/* General Styles */
.pointer {
  cursor: pointer !important;
}
.subtleHover {
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.subtleHover:hover {
  background-color: #a4aada;
}
.relative {
  position: relative;
}
:focus {
  outline: none;
}
.pageContainer {
  width: 100%;
  height: 100%;
  top: 0;
}
.pageContainer-grey {
  background-color: #f4f7f9;
}
.pageContainer-full {
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
}
.overlayPage-full {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
}
.emptyState {
  background-repeat: no-repeat;
  background-size: contain;
  height: 188px;
  width: 250px;
  background-image: url('./assets/images/emptyState.png');
}
.backgroundImage {
  background-color: #67758f;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.inputGroup {
  height: auto;
  line-height: 1;
  margin-bottom: 24px;
}
.inputGroup-label {
  font-size: 13px;
  letter-spacing: 1px;
  margin-bottom: 8px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
}
.inputGroup-input {
  font-size: 15px;
  border-color: #e0e0e0;
  height: auto;
  padding: 8px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
}
.clearfix {
  overflow: auto;
}
.clearfix ::after {
  content: '';
  clear: both;
  display: table;
}
/* Splash Screen Styles */
.poweredByPluss {
  height: 35px;
  width: 82px;
  background-image: url('./assets/images/powerPluss.png');
  background-repeat: no-repeat;
  background-size: contain;
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: -1;
}
.backgroundImage-login {
  background-image: url('./assets/images/chess_image.jpg');
}
.loginStripe {
  position: relative;
  padding: 32px;
  background-color: #fff;
  min-height: 100vh;
  width: fit-content;
  max-width: 476px;
  margin: 0 auto 0 50vw;
}
/* Home Screen Styles  */
.homeGroup:hover .homeGroup-icon {
  color: #597db4;
}
.homeGroup-icon {
  color: #5a5a6e;
}
.homeGroup-item {
  font-size: 16px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  line-height: 30px;
  cursor: pointer;
  color: #5a5a6e;
}
.homeGroup-item:hover {
  color: #597db4;
}
.sideMenuButtonContainer {
  width: 55px;
  height: 55px;
  display: inline-block;
  vertical-align: top;
}
.sideMenuButton {
  position: fixed;
  height: 55px;
  width: 56px;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 10000001;
  padding: 18px 18px;
  cursor: pointer;
}
.sideMenuButton_bar {
  width: 100%;
  height: 2px;
  background-color: #3f4264;
  -moz-transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
  -o-transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
  -webkit-transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
  transform-origin: right center;
}
.sideMenuButton-open .sideMenuButton_bar:nth-child(1) {
  transform: rotate(-45deg) translateY(2px);
}
.sideMenuButton-open .sideMenuButton_bar:nth-child(2) {
  opacity: 0;
  transform: translateX(10px);
}
.sideMenuButton-open .sideMenuButton_bar:nth-child(3) {
  transform: rotate(45deg) translateY(-2px);
}
.sideMenu_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  -moz-transition: opacity 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
  transform: translateX(-100%);
  z-index: 999999;
  cursor: pointer;
  opacity: 0;
}
.sideMenu_menu {
  width: 250px;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  background-color: #fff;
  -moz-transition: transform 0.2s ease-in-out;
  -o-transition: transform 0.2s ease-in-out;
  -webkit-transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transform: translateX(100%);
  z-index: 999999;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  -webkit-box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  border-radius: 0;
}
.sideMenu_top {
  padding: 60px 24px 0;
}
.sideMenu_profilePic {
  margin-top: 60px;
}
.sideMenu_name {
  margin-top: 16px;
}
.sideMenu_link {
  display: block;
}
.sideMenu_pattern {
  flex: 1;
  position: relative;
  overflow: hidden;
  min-height: 200px;
}
.sideMenu_pattern_inner {
  background: linear-gradient(135deg, #4a57b7 0%, #ff6363 100%);
  transform: skewY(-10deg);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 60px;
}
.sideMenu-open .sideMenu_overlay,
.sideMenu-open .sideMenu_menu {
  transform: translateX(0);
  opacity: 1;
}
.sideNav_section {
  flex: 1;
  margin-top: 40px;
}
.sideNav_section_topDivider {
  margin: 0 32px 40px;
  background-color: #dbddf1;
  height: 1px;
}
.sideNav-item-dot {
  opacity: 0;
  background-color: #4a57b7;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, opacity 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, opacity 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, opacity 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, opacity 0.2s ease-in-out;
}
.sideNav-item-icon {
  color: #a1afcc;
  font-size: 16px;
  line-height: 18px;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  width: 100%;
  text-align: center;
}
.sideNav-item-text {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #536280;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.sideNav-item-extra {
  flex: 1;
  align-self: center;
  display: flex;
  flex-direction: row-reverse;
}
.sideNav-item-extra .count {
  width: 18px;
  height: 18px;
  border-radius: 9px;
  background-color: #fd8282;
  display: flex;
  justify-content: center;
  font-family: 'Source Sans Pro';
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  color: #ffffff;
}
.sideNav-item {
  cursor: pointer;
}
.sideNav-item:hover .sideNav-item-dot,
.sideNav-item.sideNav-item--active .sideNav-item-dot {
  opacity: 1;
  background-color: #4a57b7;
}
.sideNav-item:hover .sideNav-item-icon,
.sideNav-item.sideNav-item--active .sideNav-item-icon {
  color: #4a57b7;
}
.sideNav-item:hover .sideNav-item-text,
.sideNav-item.sideNav-item--active .sideNav-item-text {
  color: #4a57b7;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
}
.sideBarSection {
  width: 100%;
  min-width: 200px;
  padding: 32px;
  padding-left: 24px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.sideBarSection:hover {
  background-color: #fff;
}
.sideBarSection:hover .sideBarSection__icon {
  color: #364196;
}
.sideBarSection__icon {
  color: #5c90df;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.sidebarSeparator {
  background-color: #dbddf1;
  height: 1px;
  margin: 8px 24px;
}
.separator {
  background-color: #dbddf1;
  height: 1px;
}
.siteSelector {
  padding: 32px 24px 0 32px;
}
.siteSelector .dropdown-menu {
  min-width: 150px;
  width: 150px;
  overflow-y: auto;
  max-height: 50vh;
}
.siteSelector .dropdown-menu li a {
  white-space: normal;
}
.siteSelector .popup_box .closeContainer {
  position: relative;
}
.siteSelector .popup_box .closeContainer .closeButton {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 20px;
}
.siteSelector .popup_box .headerSection {
  padding: 40px 34px 0;
}
.siteSelector .popup_box .headerSection .title {
  font-family: 'Source Sans Pro';
  font-size: 36px;
  font-weight: 600;
  color: #262a2d;
}
.siteSelector .popup_box .headerSection .description {
  font-family: 'Source Sans Pro';
  font-size: 16px;
  font-weight: normal;
  color: #536280;
}
.siteSelector .popup_box .siteNameSection {
  border-bottom: 1px solid #dbddf1;
  margin: 30px 76px 26px;
  padding: 0 20% 30px;
}
.siteSelector .popup_box .siteNameSection .siteName {
  margin-bottom: unset;
}
.siteSelector .popup_box .siteNameSection .siteName .siteNameInput {
  border: 1px solid #dbddf1;
  padding: 0 8px;
}
.siteSelector .popup_box .siteNameSection .siteName .inputRequired {
  left: unset;
  right: 8px;
}
.siteSelector .popup_box .planSection {
  border-bottom: 1px solid #dbddf1;
}
.siteSelector .popup_box .planSection .title {
  font-family: 'Source Sans Pro';
  font-size: 28px;
  font-weight: 600;
  color: #262a2d;
  text-align: center;
}
.siteSelector .popup_box .planSection .plans {
  display: flex;
  flex-direction: row;
  padding: 0px 60px 20px;
}
.siteSelector .popup_box .planSection .plans .planContainer {
  margin: 15px;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 286px;
  height: 160px;
  border-radius: 4px;
  background-color: #fff;
  -webkit-box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.siteSelector .popup_box .planSection .plans .planContainer .planInfoContainer {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 18px;
  border-bottom: 1px solid #dbddf1;
}
.siteSelector .popup_box .planSection .plans .planContainer .planInfoContainer .planTitle {
  font-family: 'Source Sans Pro';
  font-size: 28px;
  font-weight: 600;
  line-height: normal;
  color: #5c90df;
  max-width: 140px;
}
.siteSelector .popup_box .planSection .plans .planContainer .planInfoContainer .planTitle.unselected {
  color: #8695b2;
}
.siteSelector .popup_box .planSection .plans .planContainer .buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.siteSelector .popup_box .planSection .plans .planContainer .buttonContainer .selectButton {
  margin-top: 12px;
  margin-bottom: 14px;
  width: 145px;
  height: 24px;
  padding: unset;
}
.siteSelector .popup_box .planSection .plans .planContainer .buttonContainer .selectButton .buttonText {
  font-family: 'Source Sans Pro';
  font-size: 14px;
  font-weight: 600;
}
.siteSelector .popup_box .planSection .plans .planContainer.selected {
  border: 1px solid #5c90df;
}
.dropdown-admin {
  margin-top: 10px;
}
.dropdown-concierge {
  margin-top: 10px;
}
.dropdown-parent {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #5a5a6e;
  cursor: pointer;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  margin-left: 36px;
}
.dropdown-parent:hover {
  color: #5c90df;
}
.dropdown-parent:hover .dropdown-icon {
  color: #5c90df;
}
.dropdown-icon {
  margin-left: 20px;
  color: #8695b2;
  -moz-transition: transform 0.2s ease-in-out;
  -o-transition: transform 0.2s ease-in-out;
  -webkit-transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
}
.dropdown-icon--open {
  transform: rotate(90deg);
}
.analyticsFilter__dropDown {
  display: flex;
}
.analyticsFilter__dropDown__button {
  margin-top: 6px !important;
}
.analyticsFilter__dropDown__input {
  margin-bottom: 0;
  margin-left: 20px;
  width: 400px;
}
.analyticsFilter__dateRange {
  display: flex;
}
.dropdownLinksContainer {
  -moz-transition: height 0.2s ease-in-out, opacity 0.2s ease-in-out;
  -o-transition: height 0.2s ease-in-out, opacity 0.2s ease-in-out;
  -webkit-transition: height 0.2s ease-in-out, opacity 0.2s ease-in-out;
  transition: height 0.2s ease-in-out, opacity 0.2s ease-in-out;
  height: 0px;
  opacity: 0;
  overflow: hidden;
}
.dropdownLinksContainer--open {
  height: auto;
  opacity: 1;
}
.tvmodebuttonwrapper {
  margin-top: 20px;
  padding-right: 20px;
}
.siteSelector__loggedInText {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  color: #3f4264;
  font-size: 12px;
  margin: 0;
}
.btn-group.btn-group-xs .siteSelector__button,
.siteSelector__button {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  background-color: #fff;
  color: #4a57b7;
  border: none;
  font-size: 20px;
  padding: 0;
  margin: 0;
  text-align: left;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 12px;
  position: relative;
  letter-spacing: 0.8px;
}
.btn-group.btn-group-xs .siteSelector__button .caret,
.siteSelector__button .caret {
  position: absolute;
  right: 2px;
  top: 12px;
}
.siteSelector__new {
  float: right;
  color: #4a57b7;
  font-size: 20px;
  line-height: 28px;
  cursor: pointer;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.siteSelector__new:hover {
  color: #364196;
}
.dropdown--copyPopup {
  display: block;
  text-align: center;
  margin: 32px auto 0;
  max-width: 300px;
}
.dropdown--copyPopup .btn-group.btn-group-xs .siteSelector__button {
  max-width: 300px;
}
.dropdown-menu .active a,
.dropdown-menu .active a:hover,
.dropdown-menu .active a:focus {
  background-color: #5c90df;
}
.dropdown-menu li a:hover,
.dropdown-menu li a:focus {
  background-color: #5c90df;
}
.hub-sideContent {
  height: 100%;
  width: max-content;
  display: flex;
  flex-direction: column;
  background-color: #f4f7f9;
  z-index: 10;
  border-right: 1px solid #e7ecf1;
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 170px;
}
.hub-sideContent-topButton-dropdown {
  display: none;
}
.topHeader-addButtonWithDropdown:hover .hub-sideContent-topButton-dropdown {
  display: block;
  cursor: pointer;
}
.hub-sideContent-topButton {
  padding: 16px 24px 16px 39px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 55px;
}
.hub-sideContent-topButton--hide {
  opacity: 0;
  cursor: default;
}
.hub-sideContent-topButton--hide:active {
  background-color: transparent;
}
.hub-sideContent-topButton-text {
  padding-top: 2;
  color: #717b81;
  font-size: 13px;
  line-height: 20px;
  font-family: 'sf-semibold';
}
.hub-sideContent-topButton-icon {
  color: #717b81;
  font-size: 12px;
  line-height: 20px;
  margin-left: 20px;
}
.hub-wrapperContainer {
  position: relative;
  background-color: #fff;
  display: flex;
  width: 100%;
  height: 100%;
}
.hub-headerContentWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
}
.hub-contentWrapper {
  display: flex;
  flex: 1;
  padding: 24px 32px;
  overflow: auto;
}
.hub-contentWrapper-block {
  display: block;
}
.hub-editingOverlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #0003;
}
.hub-rightContentWrapper {
  width: 360px;
  padding-top: 16px;
  margin-left: 32px;
}
.hub_tidioPadding {
  height: 150px;
}
.pageSectionWrapper {
  font-size: 14px;
  white-space: normal;
  height: 100%;
  overflow-y: auto;
  background-color: #fff;
  padding: 24px;
  display: inline-block;
  vertical-align: top;
}
.pageSectionWrapper--newPopup {
  min-width: 788px;
  padding: 0px;
}
.pageSectionWrapper--newPopup950 {
  width: 950px;
}
.pageSectionWrapper--newPopup1000 {
  width: 1000px;
}
.pageSectionWrapper--fixedPopupSize {
  width: 788px;
  padding: 0px;
}
.pageSectionWrapper--newPopupSide {
  min-width: 448px;
  padding: 0px;
  background-color: #f4f7f9;
}
.pageSectionWrapper--newPopupSide-fixedWidth {
  width: 448px;
}
.pageSectionWrapper--newPopupSide--purple {
  background-color: #364196;
}
.pageSectionWrapper--newPopupSideLarge {
  min-width: 700px;
  padding: 0px;
  background-color: #f4f7f9;
}
.sideSection--highlighted {
  background-color: #5c90df;
}
.sideSection--highlighted .text-sectionTitle,
.sideSection--highlighted .fieldLabel,
.sideSection--highlighted .genericInput-help,
.sideSection--highlighted .actionText,
.sideSection--highlighted .text-formTitleSmall,
.sideSection--highlighted .text-formTitleMedium,
.sideSection--highlighted .text-formTitleLarge {
  color: #fff;
}
.sideSection--highlighted .radioButton--active,
.sideSection--highlighted .radioButtonWrapper:hover .radioButton {
  border-color: #fff;
}
.optionsSection-overflowButtons .optionsSection_banner_buttonSection {
  overflow-x: auto;
  justify-content: initial;
}
.optionsSection_banner {
  padding: 20px 0 0;
  background-color: #5c90df;
}
.optionsSection_banner_top_title {
  padding: 0 50px;
  color: #fff;
}
.optionsSection_banner_buttonSection {
  padding: 0 50px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.optionsButton {
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  border-bottom: solid 4px rgba(0, 0, 0, 0);
  cursor: pointer;
  margin: 0 10px;
  min-width: 100px;
}
.optionsButton:first-of-type {
  margin-left: 0;
}
.optionsButton:last-of-type {
  margin-right: 0;
}
.optionsButton_iconContainer {
  width: 46px;
  height: 46px;
  border-radius: 23px;
  padding: 14px;
  background-color: #fff;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
.optionsButton_text {
  margin-top: 4px;
  margin-bottom: 8px;
  color: #fff;
  text-align: center;
}
.optionsButton-selected {
  border-bottom-color: #fff;
}
.optionsButton-selected .optionsButton_iconContainer {
  background-color: #4a57b7;
}
.optionsContent {
  background-color: #e2e7ee;
  padding: 20px 50px 50px;
  min-height: 400px;
}
.optionsContent_box {
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: #fff;
}
.optionsContent_box_top {
  padding: 16px;
  border-bottom: solid 1px #dbddf1;
}
.optionsContent_bottom {
  padding: 16px;
}
.optionsContent_bottom-noTopPadding {
  padding-top: 0;
}
.linkedContentItem {
  display: flex;
  cursor: pointer;
  margin-top: 8px;
  align-items: center;
}
.linkedContentItem:hover .linkedContentItem_title {
  color: #3f4a61;
}
.linkedContentItem_image {
  width: 40px;
  height: 40px;
  border-radius: 2px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 8px;
}
.linkedContentItem_title {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  color: #536280;
  margin: 0;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.linkedContentItem_buttons {
  padding-left: 20px;
}
.linkedContentItem_buttons_icon {
  color: #536280;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  margin-left: 8px;
  font-size: 20px;
  width: 20px;
  height: 20px;
}
.linkedContentItem_buttons_icon:hover {
  color: #3f4a61;
}
.emergencyContacts {
  padding: 16px 0;
}
.emergencyContacts .addoption {
  margin-left: 40px;
}
.emergencyContact {
  display: flex;
  align-items: flex-end;
  padding: 0 32px 16px 0;
}
.emergencyContact_content {
  display: flex;
  flex: 1;
  align-items: center;
}
.personalDocuments {
  padding: 16px;
  display: flex;
  flex-direction: row;
}
.personalDocuments_left {
  width: 250px;
  margin-right: 16px;
}
.personalDocuments_fill {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.personalDocuments_list {
  margin-top: 16px;
  background-color: #f4f7f9;
  padding: 16px;
  flex: 1;
  overflow-y: auto;
}
.personalDocument {
  margin-top: 16px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  position: relative;
}
.personalDocument:first-of-type {
  margin-top: 0;
}
.personalDocument .moreMenu {
  top: 28px;
  right: 4px;
}
.personalDocument_moreIcon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-left: 16px;
}
.personalDocument_link {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 16px;
}
.personalDocument_link_icon {
  font-size: 14px;
  margin-right: 4px;
}
.personalDocument_link_text {
  font-family: 'sf-semibold', serif;
  font-size: 11px;
  margin: 0;
}
.personalDocument_title {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  color: #536280;
  flex: 1;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.personalNotes {
  padding: 16px;
  display: flex;
  flex-direction: row;
}
.personalNotes_left {
  width: 250px;
  margin-right: 16px;
}
.personalNotes_fill {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.personalNotes_list {
  margin-top: 16px;
  background-color: #f4f7f9;
  padding: 16px;
  flex: 1;
  overflow-y: auto;
}
.personalNote {
  margin-top: 16px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  position: relative;
}
.personalNote:first-of-type {
  margin-top: 0;
}
.personalNote .moreMenu {
  top: 28px;
  right: 4px;
}
.personalNote_moreIcon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-left: 16px;
}
.personalDocument_button {
  margin-left: 16px;
}
.personalNote_title {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  color: #536280;
  flex: 1;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.userListing {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10;
}
.userListing_inner {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.userListing_profilePic {
  margin-right: 8px;
}
.userListing_middle {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.userTagsSite {
  margin-top: 8px;
  height: 32px;
  flex-direction: row;
  align-items: center;
  display: flex;
}
.userTagsSite_title {
  width: 130px;
}
.userTagsSite_tags {
  flex: 1;
}
.userTagsAvailableSite {
  margin-top: 8px;
}
.userTagsAvailableSite_tags {
  background-color: #f4f7f9;
  padding: 8px;
  margin-top: 8px;
}
.selectedAudienceTags {
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
  max-width: 800px;
}
.availableAudienceTags {
  background-color: #f4f7f9;
  padding: 8px 8px 0 8px;
  margin-top: 8px;
  max-width: 800px;
}
.tag {
  height: 28px;
  background-color: #5c90df;
  border: solid 2px #5c90df;
  border-radius: 14px;
  display: inline-block;
  padding: 0 16px;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.tag_inner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.tag_icon {
  font-size: 12px;
  color: #4a57b7;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.tag_icon-left {
  margin-right: 8px;
}
.tag_icon-right {
  margin-left: 8px;
}
.tag_icon-clickable {
  cursor: pointer;
}
.tag_icon-clickable:hover {
  color: #364196;
}
.tag_center {
  flex: 1;
}
.tag_center-spaceAround {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.tag_text {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  color: #fff;
  font-size: 13px;
  line-height: 1;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.tag_input {
  margin-bottom: 0;
}
.tag_input .genericInput {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  color: #fff;
  font-size: 13px;
  padding-bottom: 0;
  line-height: 1.2;
}
.tag-minSize {
  min-width: 110px;
}
.tag-hasIcon {
  padding: 0 8px;
}
.tag-outlined {
  background-color: #fff;
}
.tag-outlined .tag_icon,
.tag-outlined .tag_text,
.tag-outlined .tag_input .genericInput {
  color: #5c90df;
}
.tag-outlined .tag_icon.tag_icon-clickable:hover {
  color: #364196;
}
.tag-clickable {
  cursor: pointer;
}
.tag-clickable:hover {
  background-color: #364196;
  border-color: #364196;
}
.tag-clickable.tag-outlined:hover {
  background-color: #5c90df;
  border-color: #5c90df;
}
.tag-clickable.tag-outlined:hover .tag_icon,
.tag-clickable.tag-outlined:hover .tag_text,
.tag-clickable.tag-outlined:hover .tag_input .genericInput {
  color: #fff;
}
.newTopBarButton {
  float: right;
  font-size: 20px;
  margin-left: 20px;
  cursor: pointer;
  color: #67758f;
}
.newTopBarButton:active,
.newTopBarButton:hover {
  color: #536280;
}
.managingAddButton {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.managingAddButton:hover .managingAddButton_icon,
.managingAddButton:hover .managingAddButton_text {
  color: #364196;
}
.managingAddButton_icon {
  font-size: 20px;
  margin-right: 8px;
  color: #5c90df;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.managingAddButton_text {
  margin: 0;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #5c90df;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.managingPageTitle {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 36px;
  color: #536280;
  letter-spacing: 0.7px;
  margin: 0;
}
.managingPageSubtitle {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  font-size: 24px;
  color: #536280;
  letter-spacing: 0.5px;
  margin: 0;
}
.pageTitle {
  font-family: 'Quicksand', sans-serif;
  font-weight: 600;
  font-size: 30px;
  color: #4a57b7;
  letter-spacing: 1px;
}
.pageTitle:after {
  content: '.';
  color: #ff6363;
}
.pageSubtitle {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 26px;
  color: #4a57b7;
  line-height: 32px;
  margin: 0;
}
.pageSubtitle.genericInput {
  height: 32px;
  padding: 0;
  line-height: 31px;
}
.pageSubtitleEdit {
  cursor: pointer;
  margin-left: 10px;
  font-size: 22px;
  color: #4a57b7;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.pageSubtitleEdit:hover {
  color: #364196;
}
.pageSubtitle-muted {
  color: #a4aada;
}
.pageSubtitle2 {
  font-family: 'Quicksand', sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #536280;
  margin: 0;
}
.tabContainer {
  display: flex;
  flex-direction: row;
}
.tab {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  position: relative;
  background-color: #f4f7f9;
  padding: 10px 20px;
}
.tab:hover {
  opacity: 1;
}
.tab:hover .tab_text {
  color: #4a57b7;
}
.tab:first-of-type {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.tab:last-of-type {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.tab_text {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 20px;
  color: rgba(74, 87, 183, 0.3);
  -moz-transition: color 0.2s ease-in-out, opacity 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, opacity 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, opacity 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, opacity 0.2s ease-in-out;
  margin: 0;
  min-width: 80px;
  text-align: center;
  white-space: nowrap;
}
.tab_top {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.tab-invalid {
  opacity: 1;
}
.tab-invalid .tab_text {
  color: #c02743;
}
.tab-selected {
  opacity: 1;
}
.tab-selected .tab_text {
  color: #4a57b7;
}
.tab-strikethrough {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/f852c33b41809ba627fedd29a4/pattern3.png');
  background-repeat: repeat;
  background-size: 13px 11px;
}
.tab-isEditable .tab_text {
  cursor: text;
}
.tabContainer-dashboard .tab {
  background-color: rgba(0, 0, 0, 0);
  padding: 0;
  margin-right: 20px;
}
.tabContainer-dashboard .tab-selected .tab_text {
  text-decoration: underline;
}
.chat {
  display: flex;
  flex-direction: column-reverse;
  flex: 1;
  overflow: hidden;
}
.chat_newMessage {
  padding: 10px 20px;
  border-top: solid 1px #dbddf1;
}
.chat_messages {
  flex: 1;
  overflow-y: auto;
}
.chat_inputFlex {
  display: flex;
  flex-direction: row-reverse;
}
.chat_send {
  font-size: 30px;
  cursor: pointer;
  color: #5c90df;
  padding: 10px;
}
.chat_send:hover {
  color: #364196;
}
.chat_imageIcon {
  font-size: 20px;
  cursor: pointer;
  color: #536280;
  margin-right: 10px;
  margin-bottom: 10px;
}
.chat_imageIcon:hover {
  color: #3f4a61;
}
.chat_imageIcon-selected {
  color: #5c90df;
}
.chat_imageIcon-selected:hover {
  color: #364196;
}
.chat_inputContainer {
  flex: 1;
}
.chat_input {
  margin-bottom: 0;
}
.chat_input .genericInput,
.chat_input .genericInput:focus {
  border-bottom: none;
}
.chat_noMessage {
  margin: 20px 0;
}
.chat_sideBar {
  width: 300px;
  border-left: solid 1px #dbddf1;
}
.chat_section {
  padding: 16px;
  border-bottom: solid 1px #dbddf1;
}
.chat_section_titleSection {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.chat_section_titleSection_caret {
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  color: #536280;
  cursor: pointer;
  font-size: 16px;
}
.chat_section_titleSection_caret:hover {
  color: #3f4a61;
}
.chat_section_image {
  height: 80px;
  width: auto;
  margin-right: 8px;
  margin-bottom: 8px;
  border: solid 1px #dbddf1;
}
.circleTableProfilePic {
  display: inline-block;
}
.message {
  padding: 10px 20px;
}
.message_profilePic {
  margin-right: 10px;
  margin-top: 20px;
}
.message_bubbleContainer {
  display: inline-block;
  max-width: 40vw;
  min-width: 100px;
}
.message_name {
  line-height: 15px;
  padding-bottom: 5px;
}
.message_bubble {
  padding: 10px;
  border-radius: 4px;
  background-color: #dbddf1;
}
.message_inner {
  display: flex;
}
.message_time {
  margin-bottom: 4px;
}
.message_system {
  text-align: center;
}
.message_image {
  display: inline-block;
  margin-top: 5px;
  margin-right: 5px;
  max-width: 300px;
  max-height: 80px;
}
.message-uploading {
  opacity: 0.5;
}
.message-self .message_time,
.message-self .message_name {
  text-align: right;
}
.message-self .message_bubble {
  background-color: #4a57b7;
}
.message-self .message_profilePic {
  margin-right: 0;
  margin-left: 10px;
}
.message-self .message_text {
  color: #fff;
}
.message-self .message_inner {
  flex-direction: row-reverse;
}
.newsletterPreviewOuter {
  width: 539px;
  height: 761px;
  overflow: hidden;
}
.newsletterPreview {
  width: 718px;
  height: 1013px;
  border: solid 2px #dbddf1;
  overflow: hidden;
  -webkit-box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  transform: scale(0.75);
  transform-origin: top left;
}
.profilePicHover {
  position: relative;
}
.profilePicHover:hover .profilePicHover_text {
  display: block;
}
.profilePicHover_text {
  display: none;
  position: absolute;
  background-color: #4a57b7;
  margin: 0;
  padding: 4px;
  color: #fff;
  border-radius: 2px;
  top: 0;
  left: 100%;
  white-space: nowrap;
  z-index: 200;
}
.activityEntry {
  padding: 15px 0;
  border-bottom: solid 1px #dbddf1;
  display: flex;
  flex-direction: row;
}
.activityEntry .profilePic {
  min-width: 34px;
  margin-right: 12px;
}
.activityEntry .activityTimeContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.activityEntry .activityTimeContainer .activityTimeIcon {
  font-size: 14px;
  margin-right: 8px;
  color: #18cca2;
}
.activityEntry .activityTimeContainer .activityTime {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  font-size: 13px;
  color: #8695b2;
  margin: 0;
}
.activityText {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
  color: #536280;
  font-size: 14px;
  margin: 0;
  margin-bottom: 8px;
}
.activityText_highlighted {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
}
.activityText_link {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  color: #5c90df;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.activityText_link:hover,
.activityText_link:active {
  text-decoration: none;
  color: #364196;
}
/* Event Styles */
.timeslotsContainer {
  overflow-x: scroll;
  overflow-y: hidden;
  max-width: 100%;
  height: 200px;
  white-space: nowrap;
}
.timeslotsContainer .datepicker {
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  position: fixed;
  z-index: 10000;
}
.timeslot {
  display: inline-block;
  border: solid 1px #dbddf1;
  padding: 8px;
  margin-right: 16px;
  position: relative;
  width: 250px;
  height: 180px;
  vertical-align: top;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.timeslot_dateinput {
  color: #4a57b7;
  font-size: 18px;
}
.timeslot_dateopener {
  color: #4a57b7;
  font-size: 16px;
  cursor: pointer;
  margin-left: 8px;
  padding-bottom: 5px;
}
.timeslot_icon {
  height: 15px;
  width: 15px;
  margin-right: 8px;
}
.timeslot_remove {
  position: absolute;
  top: 8px;
  right: 8px;
}
.timeslot-add {
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  background-color: #a4aada;
  cursor: pointer;
}
.timeslot-add:hover {
  background-color: #597db4;
}
.timeslot_addinner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.timeslot_addicon {
  font-size: 20px;
  text-align: center;
  color: #fff;
}
.timeslot_addtext {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  color: #fff;
  margin: 16px 0 0;
  text-align: center;
  font-size: 14px;
}
.timeslot-invalid {
  border-color: #c02743;
}
.eventTimeRep-outer {
  padding-right: 32px;
}
.eventTimeRep {
  position: 'relative';
  background-color: #fff;
  padding: 24px;
  padding-top: 8px;
  margin-bottom: 16px;
}
.eventTimeRep--submissionEdit {
  position: 'relative';
  background-color: #eeeeee88;
  padding: 24px;
  padding-top: 8px;
  margin-top: 16px;
}
.eventTimeRep-outer--selected {
  background-color: #5c90df;
}
.eventTimeRep-outer--selected .eventTimeRep--allowsColorChange {
  background-color: transparent;
}
.eventTimeRep-outer--selected .eventTimeRep--allowsColorChange .text-dark {
  color: #fff !important;
}
.eventTimeRep-outer--selected .eventTimeRep--allowsColorChange .text-brandingAction {
  color: #fff !important;
}
.eventTimeRep-outer--selected .eventTimeRep--allowsColorChange .text-lighter {
  color: #fff !important;
}
.ticketHistoryEntry {
  margin-top: 16px;
}
.ticketHistoryEntry_timestamp {
  color: #8695b2;
  font-size: 13px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  margin-bottom: 8px;
}
.statusChanger {
  background-color: #fff;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 4px;
  position: absolute;
  right: 56px;
  top: 68px;
}
.statusChanger .statusLabel {
  margin-top: 4px;
  cursor: pointer;
}
.statusChanger .statusLabel:first-of-type {
  margin-top: 0;
}
.statusChanger-maintenance {
  right: 136px;
}
.statusLabel {
  width: 100px;
  height: 24px;
  border-radius: 4px;
}
.statusLabel_text {
  width: 100%;
  height: 24px;
  line-height: 24px;
  color: #fff;
  text-align: center;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  font-size: 13px;
  display: block;
}
.statusLabel-large {
  width: 300px;
  height: 28px;
}
.statusLabel-large .statusLabel_text {
  height: 28px;
  line-height: 28px;
}
.statusLabel-full {
  width: 100%;
}
.maintenanceNote {
  background-color: #fff;
  border-radius: 2px;
  padding: 8px 16px;
  margin-top: 8px;
}
.maintenanceNote .moreMenu {
  top: 100%;
  right: 0;
}
.maintenanceNote .pdfAttachment {
  margin-top: 16px;
}
.maintenanceNote_top {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  position: relative;
}
.maintenanceNote_moreIcon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-left: 8px;
}
.maintenanceNote_name {
  flex: 1;
  margin: 0;
  font-size: 13px;
  color: #8695b2;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
}
.maintenanceNote_text {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #262a2d;
  margin: 16px 0 0;
}
.moreMenu {
  background-color: #fff;
  box-shadow: 0 1px 24px 0 rgba(103, 125, 169, 0.32);
  position: absolute;
  z-index: 500;
  padding: 8px 0;
  border-radius: 2px;
}
.moreMenu_option {
  padding: 8px 16px;
  min-width: 120px;
  color: #3f4264;
  font-size: 14px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  cursor: pointer;
}
.moreMenu_option:hover {
  color: #fff;
  background-color: #4a57b7;
}
.pdfAttachment {
  margin: 4px 0;
  display: block;
}
.pdfAttachment_container {
  padding: 4px;
  display: inline-block;
  background-color: rgba(74, 87, 183, 0.2);
  border-radius: 2px;
}
.pdfAttachment_icon {
  font-size: 16px;
  color: #4a57b7;
  margin-right: 4px;
}
.pdfAttachment_title {
  margin: 0;
  display: inline;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  color: #4a57b7;
  font-size: 14px;
}
.pdfAttachment_close {
  margin-left: 4px;
  width: 10px;
  cursor: pointer;
}
.pdfAttachment-white .pdfAttachment_icon,
.pdfAttachment-white .pdfAttachment_title {
  color: #fff;
}
.pdfAttachmentInput {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 16px;
}
.pdfAttachmentInput_icon {
  margin-right: 8px;
  height: 33px;
}
.pdfAttachmentInput_title {
  margin-right: 8px;
  padding-bottom: 4px;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pdfAttachmentInput_remove {
  margin-left: 8px;
  margin-bottom: 4px;
}
.liveStreamInput_dateTime {
  margin-right: 80px;
}
.fieldText {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  color: #536280;
  font-size: 16px;
  margin: 0;
}
.commentReply {
  margin-top: 16px;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  flex-direction: row-reverse;
  display: flex;
  padding: 8px;
}
.commentReply_button {
  width: 32px;
  height: 32px;
  border-radius: 16px;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  background-color: #dbddf1;
  margin-left: 8px;
  display: flex;
  justify-content: center;
}
.commentReply_button-active {
  background-color: #5c90df;
  cursor: pointer;
}
.commentReply_button-active:hover,
.commentReply_button-active:active {
  background-color: #364196;
}
.commentReply_icon {
  text-align: center;
  line-height: 32px;
  color: #fff;
  font-size: 18px;
  padding-right: 2px;
}
.commentReply_input {
  border: none;
  flex: 1;
  resize: none;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 15px;
  color: #536280;
  line-height: 22px;
  padding: 4px 0;
  min-height: 30px;
}
.supportImage {
  float: left;
  width: 250px;
  height: 250px;
  border-radius: 4px;
  margin-top: 16px;
  margin-right: 16px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.comment {
  margin-top: 16px;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 8px;
  background-color: #fff;
}
.comment_text {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 15px;
  color: #536280;
  line-height: 22px;
  margin: 0;
}
.comment_bottom {
  margin-top: 8px;
  display: flex;
  align-items: center;
}
.comment_profilePic {
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 8px;
}
.comment_name {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 13px;
  color: #536280;
  margin: 0;
}
.comment_time {
  flex: 1;
  text-align: right;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 13px;
  color: #8695b2;
  margin: 0;
}
.dashboardComment {
  margin-top: 24px;
  display: block;
}
.dashboardComment .comment {
  margin-top: 8px;
}
.dashboardComment:hover .dashboardComment_entity_icon {
  color: #4a57b7;
}
.dashboardComment:hover .dashboardComment_otherComments,
.dashboardComment:hover .dashboardComment_entity_text {
  color: #4a57b7;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
}
.dashboardComment_entity {
  display: flex;
  align-items: center;
}
.dashboardComment_entity_icon {
  font-size: 18px;
  color: #536280;
  margin-right: 8px;
}
.dashboardComment_entity_text {
  color: #536280;
}
.dashboardComment_otherComments {
  margin-top: 8px;
  color: #536280;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.dashboardComment-large {
  padding: 16px 24px;
  max-width: 700px;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.dashboardComment-large .comment {
  box-shadow: none;
  padding-left: 0;
}
.dashboardComment-large .dashboardComment_entity_icon {
  font-size: 24px;
}
.dashboardComment-large:hover {
  -webkit-box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
.dashboardComment_inner {
  flex: 1;
}
/* CARD VIEW Styles */
.hubOptions .fa,
.hubOptions .hubOptions {
  color: rgba(113, 123, 129, 0.8);
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.hubOptions:hover .fa,
.hubOptions:hover .hubOptions {
  color: #5c90df !important;
}
.hubOptions--active .fa {
  color: #5c90df !important;
}
.mainCard {
  position: relative;
  width: 320px;
  height: 180px;
  background-color: #fff;
  margin: 12px;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.mainCard:hover .text-brandingAction,
.mainCard:hover .text-mid,
.mainCard:hover .text-dark {
  color: #fff;
}
.mainCard:hover .text-mid {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
}
.mainCard:hover .mainCard_image {
  opacity: 0.9;
}
.mainCard:hover .mainCard_inner_buttons .fa {
  color: #fff;
}
.mainCard_inner {
  z-index: 2;
  height: 100%;
  width: 100%;
  padding: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.mainCard_inner_buttons .fa {
  cursor: pointer;
  color: #5c90df;
}
.mainCard_image {
  opacity: 0;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 3px;
}
.mainCard_image_inner {
  border-radius: 3px;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(117, 102, 243, 0.9), rgba(117, 102, 243, 0.9));
}
/* Survey style */
.questionContainer {
  display: flex;
  margin-top: 32px;
}
.questionNumber_container {
  display: flex;
  width: 40px;
}
.questionNumber {
  width: 40px;
  text-align: center;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  font-size: 30px;
  color: #5c90df;
}
.question {
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  flex: 1;
  position: relative;
}
.question_remove {
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  color: #5c90df;
  font-size: 20px;
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
}
.question_remove:hover {
  color: #364196;
}
.question_menu {
  height: 65px;
  width: 100%;
  display: flex;
}
.question_menu_option_text {
  text-align: center;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 40px;
  margin-top: 20px;
  color: #717b81;
  -moz-transition: color 0.3s ease-in-out;
  -o-transition: color 0.3s ease-in-out;
  -webkit-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
  width: 100%;
}
.question_menu_option {
  flex: 1 1;
  border-bottom: solid 3px #dbddf1;
  -moz-transition: border-color 0.3s ease-in-out;
  -o-transition: border-color 0.3s ease-in-out;
  -webkit-transition: border-color 0.3s ease-in-out;
  transition: border-color 0.3s ease-in-out;
  cursor: pointer;
}
.question_menu_option.disabled {
  cursor: default;
}
.question_menu_option:hover:not(.disabled),
.question_menu_option.question_menu_option-active {
  border-bottom-color: #5c90df;
}
.question_menu_option:hover:not(.disabled) .question_menu_option_text,
.question_menu_option.question_menu_option-active .question_menu_option_text {
  color: #5c90df;
}
.question_bottom {
  padding: 24px;
}
.question-results {
  padding: 16px;
}
.question_title {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 20px;
  color: #536280;
  margin: 0;
}
.question_description {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #536280;
  margin: 16px 0 0 0;
}
.multichoiceoption {
  display: flex;
  align-items: center;
}
.multichoiceoption .radioButtonWrapper {
  margin-bottom: 8px;
  margin-right: 8px;
}
.multichoiceoption_inputContainer {
  flex: 1;
}
.multichoiceoption_inputContainer .genericInputContainer {
  margin-bottom: 0;
}
.multichoiceoption_remove {
  margin-left: 8px;
  font-size: 20px;
  color: #4a57b7;
  cursor: pointer;
  -moz-transition: color 0.3s ease-in-out;
  -o-transition: color 0.3s ease-in-out;
  -webkit-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
}
.multichoiceoption_remove:hover {
  color: #364196;
}
.pollTitle {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  margin: 24px 0 0 0;
  font-size: 26px;
  color: #536280;
}
.multichoiceresult {
  display: flex;
  flex-direction: row-reverse;
  height: 36px;
  align-items: center;
  margin-top: 8px;
}
.multichoiceresult_responses {
  width: 100px;
  text-align: right;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  color: #536280;
  font-size: 16px;
}
.multichoiceresult_percentage {
  width: 60px;
  text-align: right;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  color: #536280;
  font-size: 20px;
}
.multichoiceresult_bar {
  flex: 1;
  height: 36px;
  border-radius: 1px;
  background-color: #f4f7f9;
  position: relative;
}
.multichoiceresult_innerBar {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  height: 36px;
  border-radius: 1px;
  background-color: #5c90df;
  opacity: 0.2;
}
.multichoiceresult_innerText {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  line-height: 36px;
  padding-left: 8px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #536280;
  margin: 0;
}
.question-paragraph {
  padding: 0;
}
.question-paragraph .question_title {
  padding: 16px 16px 0;
}
.question-paragraph .question_description {
  padding: 0 16px;
}
.paragraphresults {
  width: 100%;
  max-height: 500px;
  overflow-y: auto;
  padding: 16px;
}
.paragraphresult {
  width: 100%;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-top: 8px;
  padding: 16px;
}
.paragraphresult:first-of-type {
  margin-top: 0;
}
.paragraphresult_text {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #6c7a90;
}
.paragraphresult_bottom {
  display: flex;
  align-items: center;
}
.paragraphresult_pic {
  margin-right: 8px;
}
.paragraphresult_name {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #536280;
}
.addoption {
  cursor: pointer;
}
.addoption:hover .addoption_plus,
.addoption:hover .addoption_text {
  color: #364196;
}
.addoption_plus {
  font-size: 20px;
  color: #4a57b7;
  float: left;
  margin-right: 8px;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.addoption_plus.p60icon-add-circle {
  position: relative;
}
.addoption_plus.p60icon-add-circle .path2:before {
  position: absolute;
  top: 0;
  margin-left: 0;
}
.addoption_text {
  line-height: 20px;
  font-size: 14px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  color: #4a57b7;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  margin: 0;
}
.removeoption {
  font-size: 20px;
  color: #4a57b7;
  cursor: pointer;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.removeoption:hover {
  color: #364196;
}
.closeIcon {
  cursor: pointer;
  width: 14px;
  height: 14px;
}
.removeIcon {
  cursor: pointer;
  width: 12px;
  height: 12px;
}
.scalecontainer {
  display: flex;
  margin-top: 32px;
}
.scaleoption {
  flex: 1;
}
.scaleoption_circle {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin: 0 auto;
  background-color: #5c90df;
}
.scaleoption_number {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  color: #67758f;
  font-size: 16px;
  text-align: center;
  margin-top: 8px;
  width: 100%;
  margin-bottom: 0;
}
.scaleoption_input {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  color: #67758f;
  font-size: 13px;
  text-align: center;
  margin-top: 8px;
  width: 100%;
  border: none;
}
.scaleresults {
  display: flex;
  margin-top: 16px;
}
.scaleresult {
  flex: 1;
}
.scaleresult_percentage {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  text-align: center;
  color: #5c90df;
  font-size: 30px;
  margin: 0;
}
.scaleresult_count {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  text-align: center;
  color: #536280;
  font-size: 14px;
  margin: 0;
}
.scaleresult_number {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  text-align: center;
  margin: 16px 0 0;
  color: #717b81;
  font-size: 16px;
}
.scaleresult_text {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  text-align: center;
  margin: 0;
  color: #717b81;
  font-size: 13px;
}
.polloverview {
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: 100%;
  height: 130px;
  display: flex;
  padding: 16px;
  background-color: #fff;
}
.polloverview_section {
  flex: 1;
  height: 98px;
  display: flex;
  align-items: center;
}
.polloverview_icon {
  color: #d2d9e5;
  font-size: 30px;
  margin-right: 16px;
}
.polloverview_iconImage {
  width: 30px;
  height: 30px;
  margin-right: 16px;
}
.polloverview_right {
  flex: 1;
}
.polloverview_count {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 30px;
  color: #536280;
  margin: 0;
}
.polloverview_text {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #536280;
  margin: 0;
  height: 20px;
}
.polloverview_subtext {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #a1afcc;
}
.polloverview_num {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #536280;
  margin: 0;
  height: 20px;
}
.compactreactions {
  display: flex;
}
.compactreactions .polloverview_section {
  display: flex;
  justify-content: center;
  height: 30px;
}
.compactreactions .polloverview_right {
  flex: initial;
}
.compactreactions .polloverview_iconImage {
  width: 20px;
  height: 20px;
  margin-right: 4px;
}
.compactreactions .polloverview_count {
  font-size: 15px;
}
.pollsingledata {
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: 350px;
  height: 130px;
  display: flex;
  padding: 16px;
  background-color: #fff;
}
.insightscontainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.insights {
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: 290px;
  height: 275px;
  padding: 16px;
  background-color: #fff;
  margin-left: 20px;
}
.insights:nth-child(1) {
  margin-left: 0px;
}
.newsinsights {
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: 330px;
  height: 280px;
  padding: 16px;
  background-color: #fff;
  margin-left: 20px;
}
.newsinsights:nth-child(1) {
  margin-left: 0px;
}
.insightstitle {
  margin-bottom: 20px;
}
.hrline {
  border-top: 1px solid #dbddf1;
  margin-top: 20px;
}
.insights_section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.insightsstat {
  color: #5c90df;
  font-size: 14px;
  letter-spacing: 2pt;
}
.insightsnumber {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.insightsnumleft {
  flex: 0 0 1;
}
.insightsnumright {
  flex: 0 0 1;
  align-items: flex-end;
  text-align: right;
}
.insightsimg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 70px;
  height: 70px;
  background-color: #a1afcc;
  border-radius: 50%;
  flex: 0 0 1;
}
.insightsright {
  flex: 3;
  margin-left: 10px;
}
.newsTile {
  width: 350px;
  height: 300px;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  float: left;
  margin-right: 16px;
  margin-bottom: 16px;
}
.newsTile_image {
  width: 350px;
  height: 185px;
  background-color: #5c90df;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.newsTile_bottom {
  width: 350px;
  height: 115px;
  position: relative;
  padding: 12px;
}
.newsTile_tagDate {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.newsTile_date {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #536280;
  margin: 0;
}
.newsTile_tag {
  flex: 1;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #5c90df;
  margin: 0;
}
.newsTile_title {
  margin: 8px 0 0;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: #262a2d;
  cursor: pointer;
}
.newsTile_description {
  margin: 8px 0 0;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #536280;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -webkit-line-clamp: 2;
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
}
.newsTile_publisher {
  margin: 8px 0 0;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #536280;
}
.newsTile_publisherImage {
  height: 20px;
  position: absolute;
  bottom: 8px;
  right: 8px;
}
.newsTile-event .newsTile_title {
  display: block;
  margin: 0;
}
.newsTile-event .newsTile_bottom {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.newsTile-event .newsTile_bottom .newsTile_bottom_top {
  display: block;
  flex: 1;
}
.pollaudience {
  display: flex;
  width: 100%;
}
.pollaudience_left {
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  flex: 1;
  margin-right: 16px;
}
.pollaudience_right {
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  flex: 1;
  margin-left: 16px;
}
.pollaudience_title {
  padding: 16px 16px 0 16px;
  margin: 0;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  font-size: 24px;
  color: #536280;
}
.pollaudience_bottom {
  height: 400px;
  overflow-y: auto;
  padding: 0 16px 16px 16px;
}
.pollaudience_bottom .viewResponse {
  font-size: 10px;
  color: #4a57b7;
  margin-bottom: 0;
  cursor: pointer;
}
.pollaudience_anonymous {
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: flex;
  width: 100%;
  padding: 16px;
  align-items: center;
}
.pollaudience_anonymousIcon {
  color: #d2d9e5;
  font-size: 30px;
  margin-right: 16px;
}
.pollaudience_text {
  font-size: 14px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  color: #536280;
  margin: 0;
}
.polloverview--userAnalytics {
  height: 180px;
  min-width: 1200px;
}
.polloverview--userAnalytics .polloverview_section {
  height: 148px;
}
.polloverview--serviceAnalytics {
  height: 180px;
  min-width: 1000px;
}
.polloverview--serviceAnalytics .polloverview_section {
  height: 148px;
}
.polloverview--newsAnalytics {
  height: 180px;
  min-width: 1000px;
}
.polloverview--newsAnalytics .polloverview_section {
  height: 148px;
}
.polloverview--eventAnalytics {
  height: 180px;
  min-width: 1000px;
}
.polloverview--eventAnalytics .polloverview_section {
  height: 148px;
}
.servicebooking {
  width: 100%;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-top: 16px;
  padding: 16px;
}
.servicebooking:first-of-type {
  margin-top: 0;
}
.servicebooking_top {
  display: flex;
  padding-bottom: 16px;
}
.servicebooking_top_side {
  flex: 1;
}
.servicebooking_enquiryAbout {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  color: #536280;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0;
}
.servicebooking_time {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  color: #536280;
  font-size: 16px;
  margin: 0;
}
.servicebooking_text {
  margin: 8px 0 0;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 13px;
  color: #6c7a90;
}
.servicebooking_text:first-of-type {
  margin-top: 0;
}
.servicebooking_text--secondary {
  color: #8695b2;
}
.servicebooking_timestamp {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 13px;
  color: #8695b2;
  margin: 8px 0 0;
}
.servicebooking_bottom {
  border-top: solid 1px #dbddf1;
  padding-top: 16px;
}
.servicebooking_user {
  display: flex;
  align-items: center;
}
.servicebooking_pic {
  margin-right: 8px;
}
.servicebooking_name {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 13px;
  color: #536280;
  margin: 0;
}
.servicebooking_behalf {
  font-style: italic;
}
.servicebooking_behalfname {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
}
/* Table Styles */
table.plussTable {
  border-color: transparent !important;
  border: none !important;
  border-spacing: 0px 8px;
  border-collapse: unset;
  min-width: 100%;
}
table.plussTable tbody,
table.plussTable thead {
  background-color: transparent !important;
  border: none !important;
}
table.plussTable tbody tr,
table.plussTable thead tr {
  background-color: #fff !important;
  border: none !important;
  -webkit-box-shadow: 0px 0px 8px rgba(106, 163, 216, 0.2);
  -moz-box-shadow: 0px 0px 8px rgba(106, 163, 216, 0.2);
  box-shadow: 0px 0px 8px rgba(106, 163, 216, 0.2);
}
table.plussTable tbody tr:hover,
table.plussTable thead tr:hover {
  -webkit-box-shadow: 0px 0px 8px rgba(106, 163, 216, 0.9);
  -moz-box-shadow: 0px 0px 8px rgba(106, 163, 216, 0.9);
  box-shadow: 0px 0px 8px rgba(106, 163, 216, 0.9);
}
table.plussTable tbody tr td,
table.plussTable thead tr td {
  background-color: transparent !important;
  border: none !important;
  vertical-align: middle !important;
  padding: 8px !important;
}
table.plussTable tbody tr td:first-of-type,
table.plussTable thead tr td:first-of-type {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
table.plussTable tbody tr td:last-of-type,
table.plussTable thead tr td:last-of-type {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
table.plussTable tbody tr th,
table.plussTable thead tr th {
  background-color: transparent !important;
  border: none !important;
  padding: 8px !important;
}
table.plussTable thead tr {
  background-color: #fff !important;
  border: none !important;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
table.plussTable thead tr:hover {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
table.plussBorderedTable {
  border-collapse: collapse;
  min-width: 100%;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 8px rgba(60, 60, 80, 0.5);
  -moz-box-shadow: 0px 0px 8px rgba(60, 60, 80, 0.5);
  box-shadow: 0px 0px 8px rgba(60, 60, 80, 0.5);
}
table.plussBorderedTable tbody,
table.plussBorderedTable thead {
  background-color: transparent;
  border-radius: 5px;
}
table.plussBorderedTable tbody tr,
table.plussBorderedTable thead tr {
  background-color: #fff;
}
table.plussBorderedTable tbody tr th:first-of-type,
table.plussBorderedTable thead tr th:first-of-type,
table.plussBorderedTable tbody tr td:first-of-type,
table.plussBorderedTable thead tr td:first-of-type {
  border-left: 0;
}
table.plussBorderedTable tbody tr th:last-of-type,
table.plussBorderedTable thead tr th:last-of-type,
table.plussBorderedTable tbody tr td:last-of-type,
table.plussBorderedTable thead tr td:last-of-type {
  border-right: 0;
}
table.plussBorderedTable tbody tr:first-of-type td,
table.plussBorderedTable thead tr:first-of-type td,
table.plussBorderedTable tbody tr:first-of-type th,
table.plussBorderedTable thead tr:first-of-type th {
  border-top: 0;
}
table.plussBorderedTable tbody tr:first-of-type th:first-of-type,
table.plussBorderedTable thead tr:first-of-type th:first-of-type,
table.plussBorderedTable tbody tr:first-of-type td:first-of-type,
table.plussBorderedTable thead tr:first-of-type td:first-of-type {
  border-top-left-radius: 5px;
}
table.plussBorderedTable tbody tr:first-of-type th:last-of-type,
table.plussBorderedTable thead tr:first-of-type th:last-of-type,
table.plussBorderedTable tbody tr:first-of-type td:last-of-type,
table.plussBorderedTable thead tr:first-of-type td:last-of-type {
  border-top-right-radius: 5px;
}
table.plussBorderedTable tbody tr:last-of-type td,
table.plussBorderedTable thead tr:last-of-type td,
table.plussBorderedTable tbody tr:last-of-type th,
table.plussBorderedTable thead tr:last-of-type th {
  border-bottom: 0;
}
table.plussBorderedTable tbody tr:last-of-type th:first-of-type,
table.plussBorderedTable thead tr:last-of-type th:first-of-type,
table.plussBorderedTable tbody tr:last-of-type td:first-of-type,
table.plussBorderedTable thead tr:last-of-type td:first-of-type {
  border-bottom-left-radius: 5px;
}
table.plussBorderedTable tbody tr:last-of-type th:last-of-type,
table.plussBorderedTable thead tr:last-of-type th:last-of-type,
table.plussBorderedTable tbody tr:last-of-type td:last-of-type,
table.plussBorderedTable thead tr:last-of-type td:last-of-type {
  border-bottom-right-radius: 5px;
}
table.plussBorderedTable tbody tr td,
table.plussBorderedTable thead tr td {
  background-color: transparent;
  border: solid 1px #dbddf1;
  vertical-align: middle;
  padding: 8px;
}
table.plussBorderedTable tbody tr th,
table.plussBorderedTable thead tr th {
  border: solid 1px #dbddf1;
  padding: 8px;
}
table.plussBorderedTable thead tr {
  background-color: #a4aada;
}
table.plussBorderedTable.plussBorderedTable--notEmpty thead tr:last-of-type td,
table.plussBorderedTable.plussBorderedTable--notEmpty thead tr:last-of-type th {
  border-bottom: solid 1px #dbddf1;
}
table.plussBorderedTable.plussBorderedTable--notEmpty thead tr:last-of-type th:first-of-type,
table.plussBorderedTable.plussBorderedTable--notEmpty thead tr:last-of-type td:first-of-type {
  border-bottom-left-radius: 0;
}
table.plussBorderedTable.plussBorderedTable--notEmpty thead tr:last-of-type th:last-of-type,
table.plussBorderedTable.plussBorderedTable--notEmpty thead tr:last-of-type td:last-of-type {
  border-bottom-right-radius: 0;
}
.table-id {
  width: auto !important;
  min-width: 40px !important;
  max-width: 50px !important;
  overflow: hidden !important;
}
.plussTable thead tr {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  color: #5a5a6e;
  font-size: 12px;
}
.plussTable tbody tr {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  color: #6c7a90;
  font-size: 14px;
}
.plussTable tbody tr .table-TitleColumn {
  color: #262a2d !important;
  font-size: 16px;
  text-decoration: none;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.plussTable tbody tr .table-TitleColumn a {
  color: #262a2d !important;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  text-decoration: none;
}
.plussTable tbody tr .table-TitleColumn a:hover {
  color: #5c90df !important;
  text-decoration: none;
}
.plussTable tbody tr .table-options {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
}
.plussTable tbody tr .table-options a {
  color: #5c90df !important;
  text-decoration: none;
}
.table--columnActive {
  color: #5c90df !important;
}
.paginationContainer {
  margin: 8px 0;
}
.pagination__left,
.pagination__right {
  display: inline-block;
  color: #5c90df;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  font-size: 20px;
  line-height: 25px;
  cursor: pointer;
}
.pagination__left:hover,
.pagination__right:hover {
  color: #6244cf;
}
.pagination__text {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  color: #262a2d;
  font-size: 15px;
  line-height: 25px;
  padding: 0 16px;
  margin: 0;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  min-width: 120px;
}
.successPopupBackground {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
  width: 100%;
  height: 100%;
  background-color: rgba(60, 60, 80, 0.5);
}
.successPopup {
  width: 600px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  border-radius: 2px;
  padding: 0 24px;
}
.successPopup_tick {
  margin: 60px auto 0;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #5c90df;
}
.successPopup_tick_icon {
  line-height: 100px;
  text-align: center;
  width: 100px;
  color: #fff;
  font-size: 60px;
}
.successPopup_text {
  margin: 30px 0 0 0;
  color: #536280;
  text-align: center;
  width: 100%;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  font-size: 26px;
}
.successPopup_text-small {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 16px;
}
.successPopup_buttons {
  margin-bottom: 60px;
  margin-top: 40px;
}
.successPopup_button {
  width: 300px;
  text-align: center;
  margin: 20px auto 0;
  display: block;
}
.dashboardButtons_title {
  margin-bottom: 20px;
}
.dashboardModules {
  margin-top: 40px;
}
.dashboardModules_title {
  margin-bottom: 20px;
}
.dashboardButton {
  width: 160px;
  height: 200px;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 20px;
  background-color: #fff;
  padding: 20px;
  vertical-align: top;
}
.dashboardButton:hover {
  -webkit-box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
.dashboardButton:hover .dashboardButton_text {
  color: #4a57b7;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
}
.dashboardButton_inner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.dashboardButton_iconContainer {
  width: 66px;
  height: 66px;
  background-color: rgba(74, 87, 183, 0.2);
  border-radius: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dashboardButton_icon {
  font-size: 30px;
  display: block;
  color: #4a57b7;
}
.dashboardButton_text {
  font-size: 20px;
  color: #5f717f;
  margin: 20px 0 0;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  text-align: center;
  text-transform: capitalize;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -webkit-line-clamp: 2;
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
}
.dashboardFeaturePickerButton {
  background: linear-gradient(90deg, #d03e70 0%, #884a91 50%, #339eae 100%);
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  cursor: pointer;
  border-radius: 4px;
}
.dashboardFeaturePickerButton:hover {
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.dashboardFeaturePickerButton-inactive {
  background: none;
  background-color: #d2d9e5;
}
.dashboardFeaturePickerButton_text {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  color: #fff;
  font-size: 13px;
  margin: 0;
  letter-spacing: 1px;
}
.dashboardFeaturePickerButton_icon {
  font-size: 30px;
  color: #fff;
  margin-top: -4px;
  margin-left: 30px;
}
.list-group-item {
  border-color: #dbddf1;
}
.attachInterface {
  display: flex;
  padding: 10px 15px;
  border: solid 1px #dbddf1;
  border-radius: 0 0 4px 4px;
  max-width: 600px;
  flex-direction: row-reverse;
  align-items: center;
}
.attachInterface_current {
  flex: 1;
  text-align: left;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  color: #536280;
  margin: 0;
}
.attachInterface-open {
  border-radius: 0;
  border-bottom: 0;
}
.featurePickerInterfaces {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.featurePickerInterfaces_add {
  font-size: 20px;
  color: #5c90df;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  cursor: pointer;
  margin-right: 20px;
}
.featurePickerInterfaces_add:hover {
  color: #364196;
}
.featurePickerInterfaces_title {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  color: #262a2d;
  font-size: 14px;
  margin: 0;
  line-height: 16px;
  margin-right: 20px;
}
.featurePickerInterfaces_title-sub {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
}
.featurePickerInterfaces_button {
  background-color: #f4f7f9;
  padding: 0 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 44px;
}
.featurePickerInterfaces_button:last-of-type {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}
.featurePickerInterfaces_button:first-of-type {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  margin-right: 20px;
}
.featurePickerInterfaces_button:hover {
  background-color: #5c90df;
}
.featurePickerInterfaces_button:hover .featurePickerInterfaces_button_text {
  color: #fff;
}
.featurePickerInterfaces_button_text {
  color: #a1afcc;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  font-size: 16px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  margin: 0;
}
.featurePickerInterfaces_button-active {
  background-color: #5c90df;
}
.featurePickerInterfaces_button-active .featurePickerInterfaces_button_text {
  color: #fff;
}
.featurePickerInterfaces_button-active .featurePickerInterfaces_button_edit {
  display: block;
}
.featurePickerInterfaces_button_edit {
  display: none;
  padding: 4px 8px;
  margin-left: 8px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  color: #5c90df;
  background-color: #fff;
  border-radius: 4px;
  font-size: 14px;
}
.interfacePopup_userTypeSection {
  max-height: 220px;
  width: 500px;
  overflow-y: auto;
  padding: 0 20px;
}
.interfacePopup_userTypeSectionHeadline {
  margin-top: 20px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 13px;
  color: #536280;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.interfacePopup_helperBox {
  padding: 15px;
  background-color: #a4aada;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  color: #fff;
  font-size: 13px;
  border-radius: 4px;
}
.interfacePopup_userType {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  -webkit-box-shadow: 0 3px 30px rgba(103, 125, 169, 0.07);
  -moz-box-shadow: 0 3px 30px rgba(103, 125, 169, 0.07);
  box-shadow: 0 3px 30px rgba(103, 125, 169, 0.07);
  border-radius: 2px;
  padding: 10px;
  align-items: center;
  cursor: pointer;
}
.interfacePopup_userType_checkbox {
  margin-right: 10px;
}
.interfacePopup_userType_title {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 16px;
  margin: 0;
  color: #536280;
}
.interfacePopup_deleteWarning {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #536280;
}
.interfacePopup_deleteWarning-userTypes {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  color: #5c90df;
}
.tvPicker {
  display: flex;
  margin-top: 20px;
}
.tvPickerLeft {
  margin-right: 20px;
  flex-shrink: 0;
  width: 797px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tvPicker_portraitToggle {
  display: flex;
  align-items: center;
  justify-content: center;
}
.tvPicker_portraitToggle_toggle {
  margin-right: 10px;
}
.tvPicker_portraitToggle_label {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #536280;
  margin: 0;
}
.tvPickerPreview {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/a649a8284eb0bc16dd5122fa87/landscapetv.png');
  background-size: 797px 541px;
  background-repeat: no-repeat;
  height: 541px;
  width: 797px;
  position: relative;
}
.tvPickerPreview_inner {
  display: block;
  overflow: hidden;
  position: absolute;
  height: 396px;
  width: 704px;
  top: 54px;
  left: 46px;
  background-color: #fff;
}
.tvPickerPreview_header {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/5291916149e4af80a7d35cc034/topheader.png');
  background-repeat: no-repeat;
  background-size: 100%;
  height: 44px;
  width: 704px;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  border-radius: 0;
  z-index: 3;
  position: relative;
}
.tvPickerPreview_header_text {
  margin: 0;
  font-family: 'black_jackregular', 'Source Sans Pro', sans-serif;
  font-size: 16px;
  position: absolute;
  left: 12px;
  line-height: 44px;
}
.tvPickerPreview_header_logoContainer {
  width: 160px;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  top: 7px;
  margin-left: 12px;
}
.tvPickerPreview_header_logo {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left center;
}
.tvPickerPreview_weather {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/871135094be2bf35e22bc78344/weathersectiontransparent.png');
  background-repeat: no-repeat;
  background-size: 100%;
  height: 43px;
  width: 704px;
  border-bottom: solid 1px #dbddf1;
  position: relative;
}
.tvPickerPreview_weather_welcome {
  margin: 0;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  font-size: 16px;
  position: absolute;
  left: 12px;
  line-height: 42px;
  color: #3f4264;
  letter-spacing: 0.5px;
}
.tvPickerPreview_inner_content {
  height: 309px;
  width: 704px;
  position: relative;
}
.tvPickerPreview_widget {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
}
.tvPickerPreview_widget_title {
  margin: 0;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  font-size: 14px;
  position: absolute;
  left: 12px;
  line-height: 28px;
  color: #262a2d;
  letter-spacing: 0.5px;
}
.tvPickerPreview_widget-events-full {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/5acdfb704c34a35658a3afd628/eventsfull.png');
}
.tvPickerPreview_widget-gallery-full {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/cb71664e443dbe846c3a8fc5da/galleryfull.png');
}
.tvPickerPreview_widget-announcements-full {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/bd2ce7eb42c0af62abd4956428/announcementsfull.png');
}
.tvPickerPreview_widget-food-full {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/53d3fb0148bab331c3511d724f/menufull.png');
}
.tvPickerPreview_widget-events-hv {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/bc1c16be48d9aaad6a637c6ac0/eventshalfvertical.png');
}
.tvPickerPreview_widget-gallery-hv {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/ef478d00494b84a6d399ca5bdd/galleryhalfvertical.png');
}
.tvPickerPreview_widget-announcements-hv {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/18faed27446e827a8882ee187d/announcementshalfvertical.png');
}
.tvPickerPreview_widget-food-hv {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/a90d4cef4a05b6be44871dcaaf/menuhalfvertical1.png');
}
.tvPickerPreview_widget-events-hh {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/ba808de148829b2e1223d9a7d2/eventshalfhorizontal.png');
}
.tvPickerPreview_widget-gallery-hh {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/4dcab1de49ffaeb17f5650e37e/galleryhalfhorizontal.png');
}
.tvPickerPreview_widget-announcements-hh {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/7ed1b1704576b7be4d08b8773d/announcementhalfhorizontal.png');
}
.tvPickerPreview_widget-food-hh {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/6f9939624143aff3506b6592f8/menuhalfhorizontal.png');
}
.tvPickerPreview_widget-events-q {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/415ba45247278a45985e226730/quarterevents2.png');
}
.tvPickerPreview_widget-featuredEvents-q {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/ce11b23648b08eadef80ec7509/quarterevents.png');
}
.tvPickerPreview_widget-gallery-q {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/d860cbde4ad98e39aeb18d87ca/quartergallery.png');
}
.tvPickerPreview_widget-announcements-q {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/c9cc755149aab0d517d93c49d6/quarterannouncements.png');
}
.tvPickerPreview_widget-food-q {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/30ba87b745fe8a769269197d75/quartermenu.png');
}
.tvPickerPreview_widget-events-p1 {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/c7aaa0034b5ea470f834b5fb11/fullscreenevents.png');
}
.tvPickerPreview_widget-gallery-p1 {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/8a0e79c943cb9f23c06d570922/fullscreengallery.png');
}
.tvPickerPreview_widget-announcements-p1 {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/82471c7a4dc597280cf602b2b6/fullscreenannouncements.png');
}
.tvPickerPreview_widget-food-p1 {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/7032b56441be99b6462aededcc/fullscreenmenu.png');
}
.tvPickerPreview_widget-events-p2 {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/493722c842eaaf90565202cf7e/halfscreenevents.png');
}
.tvPickerPreview_widget-gallery-p2 {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/471e4d184d8e8138f82c2007d4/halfscreengallery.png');
}
.tvPickerPreview_widget-announcements-p2 {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/fb9c281745f0a46edaedf9dc28/halfscreenannouncements.png');
}
.tvPickerPreview_widget-food-p2 {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/4addf94d47c593a6387e005915/halfscreenmenu.png');
}
.tvPickerPreview_widget-events-p3 {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/5331c4fd402ca468f9f5740ef5/thirdscreenevents.png');
}
.tvPickerPreview_widget-gallery-p3 {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/bdd59090433e8baff5466f5772/thirdscreengallery.png');
}
.tvPickerPreview_widget-announcements-p3 {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/7ce523144e92a5c90d7b9e57ef/thirdscreenannouncements.png');
}
.tvPickerPreview_widget-food-p3 {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/8682bfb74957a0b66f0f01f39a/thirdscreenmenu.png');
}
.tvPickerPreview_widget-events-p4 {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/3105206d4b4a8522cb8db94589/quarterscreenevents.png');
}
.tvPickerPreview_widget-gallery-p4 {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/68f10e864aac84f31d6a5f97e6/quarterscreengallery.png');
}
.tvPickerPreview_widget-announcements-p4 {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/3e7abd9e4418b9e95865f827a0/quarterscreenannouncements.png');
}
.tvPickerPreview_widget-food-p4 {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/2766a6454b1c8a076b7d83f934/quarterscreenmenu.png');
}
.tvPickerPreview-portrait {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/bb5b521f4c3a9a69d9fc74d84d/portraittv1.png');
  background-size: 394px 664px;
  height: 664px;
  width: 394px;
}
.tvPickerPreview-portrait .tvPickerPreview_inner {
  height: 584px;
  width: 330px;
  top: 41px;
  left: 30px;
}
.tvPickerPreview-portrait .tvPickerPreview_header {
  height: 37px;
  width: 330px;
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/7572a9ab4fabb0a743546a2431/header.png');
}
.tvPickerPreview-portrait .tvPickerPreview_header_logoContainer {
  height: 25px;
  top: 6px;
}
.tvPickerPreview-portrait .tvPickerPreview_weather {
  height: 35px;
  width: 330px;
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/4a17ad764204985177cab0adcf/weatherbar.png');
}
.tvPickerPreview-portrait .tvPickerPreview_weather_welcome {
  font-size: 14px;
  line-height: 34px;
  left: 8px;
}
.tvPickerPreview-portrait .tvPickerPreview_inner_content {
  height: 512px;
  width: 330px;
  position: relative;
  display: flex;
  flex-direction: column;
}
.tvPickerPreview-portrait .tvPickerPreview_widget_title {
  font-size: 12px;
  line-height: 24px;
  left: 8px;
}
.tvPickerPreview-portrait-rotated {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/bb5b521f4c3a9a69d9fc74d84d/portraittv1.png');
  background-size: 394px 664px;
  height: 664px;
  width: 394px;
  transform: rotate(180deg);
  top: 2px;
  right: 4px;
}
.tvPickerPreview-portrait-rotated .tvPickerPreview_inner {
  transform: rotate(180deg);
}
.featurePicker_mainSection_tile-eventsTVWidget .featurePicker_mainSection_tile_image_inner {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/975bdf4646f6bbcc41d5be80e8/eventstvwidget.png');
}
.featurePicker_mainSection_tile-galleryTVWidget .featurePicker_mainSection_tile_image_inner {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/cb3acb72464aa00ed15a0de9a0/gallerytvwidget.png');
}
.featurePicker_mainSection_tile-foodTVWidget .featurePicker_mainSection_tile_image_inner {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/f64edb59490886d59a7b5a093e/menutvwidget.png');
}
.featurePicker_mainSection_tile-announcementsTVWidget .featurePicker_mainSection_tile_image_inner {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/67c7b1f14152908a474d22eed0/announcementstvwidget.png');
}
.featurePicker {
  display: flex;
  margin-top: 20px;
  padding: 0 20px;
  flex: 1;
  overflow: hidden;
}
.featurePickerLeft {
  overflow-y: auto;
  width: 384px;
  margin-right: 20px;
  position: relative;
}
.featurePicker_appOptionsButton {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.featurePicker_appOptionsButton .button--boxed .button {
  padding-left: 32px;
  padding-right: 32px;
}
.featurePickerRight_appOptionsCaret {
  position: absolute;
  left: -40px;
  top: 200px;
  border: solid 20px rgba(0, 0, 0, 0);
  border-right-color: #fff;
}
.featurePickerPreview {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/5c72951b484abb9a8dd77929c1/iphone8silver.png');
  background-size: 384px 752px;
  height: 752px;
  width: 384px;
  position: relative;
  flex-shrink: 0;
}
.featurePickerPreview_inner {
  background-color: #fff;
  display: block;
  overflow: hidden;
  position: absolute;
  width: 304px;
  height: 540px;
  top: 97px;
  left: 40px;
}
.featurePickerPreview_grid {
  padding: 8px;
  position: relative;
  z-index: 2;
}
.featurePickerPreview_gridItem {
  width: 136px;
  display: inline-block;
  margin: 4px;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  height: 150px;
  background-color: #fff;
  vertical-align: top;
}
.featurePickerPreview_gridItem_inner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 5px;
}
.featurePickerPreview_gridItem_iconCircle {
  height: 60px;
  width: 60px;
  border-radius: 30px;
  background-color: #f4f7f9;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 5px;
}
.featurePickerPreview_gridItem_icon {
  height: 40px;
  width: 40px;
}
.featurePickerPreview_gridItem_titleContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  height: 50px;
}
.featurePickerPreview_gridItem_title {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #262a2d;
  text-align: center;
  margin: 0;
}
.featurePickerPreview_gridItem-3cols {
  width: 88px;
  height: 110px;
}
.featurePickerPreview_gridItem-3cols .featurePickerPreview_gridItem_inner {
  padding: 5px;
}
.featurePickerPreview_gridItem-3cols .featurePickerPreview_gridItem_iconCircle {
  width: 50px;
  height: 50px;
  border-radius: 25px;
}
.featurePickerPreview_gridItem-3cols .featurePickerPreview_gridItem_icon {
  width: 35px;
  height: 35px;
}
.featurePickerPreview_gridItem-3cols .featurePickerPreview_gridItem_title {
  font-size: 13px;
}
.featurePickerPreview_gridPending {
  height: 410px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.featurePickerPreview_gridPending_label {
  font-size: 26px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  text-align: center;
}
.featurePickerPreview_main {
  flex: 1;
  overflow: hidden;
}
.featurePickerPreview_main-branding {
  padding: 56px 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.featurePickerPreview_main_title {
  font-size: 26px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  text-align: center;
}
.featurePickerPreview_main_logo {
  max-width: 80%;
  max-height: 60px;
}
.featurePickerRight {
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column-reverse;
  position: relative;
}
.featurePickerRight-options {
  flex-direction: column;
}
.featurePickerPage {
  padding: 20px;
  padding-left: 10px;
  flex: 1;
  overflow-y: auto;
  border-radius: 10px;
  background-color: #e2e7ee;
}
.featurePicker_tabs {
  padding: 30px 20px 20px;
  display: flex;
  justify-content: center;
  position: relative;
}
.featurePicker_tabsCaret {
  border: solid 20px rgba(0, 0, 0, 0);
  border-top-color: #e2e7ee;
  position: absolute;
  top: 0;
  left: 0;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
}
.featurePicker_back {
  cursor: pointer;
  font-size: 30px;
  color: #4a57b7;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  width: 40px;
  text-align: center;
  display: inline-block;
}
.featurePicker_back:hover {
  color: #364196;
}
.featurePicker_headerTray {
  height: 95px;
  width: 100%;
  padding: 0 40px;
  display: flex;
  border-radius: 0;
  align-items: center;
}
.featurePicker_headerTray .pageTitle {
  margin: 0;
}
.featurePicker_pageTitle {
  color: #4a57b7;
}
.featurePicker_templateText {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #67758f;
  margin: 0;
  cursor: pointer;
}
.featurePicker_templates {
  max-width: 600px;
}
.featurePicker_templatesButton {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.featurePicker_templatesButton_circle {
  width: 34px;
  height: 34px;
  border-radius: 17px;
  background-image: linear-gradient(45deg, rgba(92, 144, 223, 0.6) 0%, rgba(255, 99, 99, 0.6) 100%);
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.featurePicker_templatesButton_text {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #4a57b7;
  margin: 0;
}
.featurePicker_templateOption {
  display: inline-block;
  cursor: pointer;
  height: 180px;
  width: 180px;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin: 20px 20px 0 0;
}
.featurePicker_templateOption:hover {
  -webkit-box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
.featurePicker_templateOption_inner {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
}
.featurePicker_templateOption_icon {
  height: 40px;
  width: 40px;
  margin-top: 40px;
}
.featurePicker_templateOption_text {
  margin: 20px 0 0;
  text-align: center;
  color: #262a2d;
  font-size: 20px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
}
.featurePicker_selectedTemplate {
  height: 32px;
  display: flex;
  align-items: center;
  padding: 0 8px;
  background-color: #f4f7f9;
  margin-top: 10px;
}
.featurePicker_selectedTemplate_icon {
  width: 28px;
  height: 28px;
  margin-right: 4px;
}
.featurePicker_selectedTemplate_text {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700;
  font-size: 13px;
  color: #5c90df;
  margin: 0;
  white-space: nowrap;
}
.featurePicker_selectedTemplate_remove {
  width: 13px;
  height: 13px;
  margin-left: 8px;
  cursor: pointer;
  position: relative;
  top: -1px;
}
.featurePickerPreview_splash {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/6fc6082a4689a674d51606e218/splashscreen.png');
  background-size: 100%;
  width: 100%;
  height: 100%;
}
.featurePickerPreview_gridHeader {
  width: 100%;
  height: 226px;
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/6fb5d8994a9b9a7b0c3ecf220d/headergradientgrid2.png');
  background-size: 100%;
  position: absolute;
  top: 0;
}
.featurePickerPreview_header {
  width: 100%;
  height: 74px;
  border-bottom: solid 1px #dbddf1;
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/2b0d4229404fa54e35bf570b59/headerblankwhite.png');
  background-size: 100%;
  position: relative;
}
.featurePickerPreview_header_background,
.featurePickerPreview_header_image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: cover;
  -webkit-mask-size: cover;
}
.featurePickerPreview_header_text {
  font-family: 'black_jackregular', 'Source Sans Pro', sans-serif;
  font-size: 16px;
  position: absolute;
  top: 33px;
  left: 16px;
}
.featurePickerPreview_header-gradient {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/fccc101e4edeb3bfdab79f4313/headerblankgradient.png');
}
.featurePickerPreview_header-block {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/bae9dd3840cb98ce19d2f56220/headertransparentblank.png');
}
.featurePickerPreview_header-pattern {
  background-image: none;
}
.featurePickerPreview_header-pattern .featurePickerPreview_header_image {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/bae9dd3840cb98ce19d2f56220/headertransparentblank.png');
}
.featurePickerPreview_header_logoContainer {
  width: 160px;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  top: 30px;
  margin-left: 12px;
}
.featurePickerPreview_header_logo {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left center;
}
.featurePickerPreview_headerWhatsOn {
  width: 100%;
  height: 65px;
  border-bottom: solid 1px #dbddf1;
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/6ae285f8462996f7b0705de269/headerwhitewhatson.png');
  background-size: 100%;
}
.featurePickerPreview_headerWhatsOn-gradient {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/1c689d7e4c1fb6524fa57d0876/headergradientwhatson.png');
}
.featurePickerPreview_headerWhatsOn-pattern,
.featurePickerPreview_headerWhatsOn-block {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/fc98aca14028aae801d0b6e4db/headertransparentwhatson.png');
}
.featurePickerPreview_headerHub {
  width: 100%;
  height: 65px;
  border-bottom: solid 1px #dbddf1;
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/ae6c88f9472fbb6348b3260ae1/headerwhitehub.png');
  background-size: 100%;
}
.featurePickerPreview_headerHub-gradient {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/01496e1040eaac66d52592a56e/headergradienthub.png');
}
.featurePickerPreview_headerPeople {
  width: 100%;
  height: 94px;
  border-bottom: solid 1px #dbddf1;
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/a35495d3489d8c5e3a47f04ca7/headerwhitecontacts.png');
  background-size: 100%;
}
.featurePickerPreview_headerPeople-gradient {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/ac58304b4e2fa9f09e15c8804b/headergradientcontacts.png');
}
.featurePickerPreview_headerMore {
  width: 100%;
  height: 65px;
  border-bottom: solid 1px #dbddf1;
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/b6e3012b45428573f29c003692/headerwhitemore.png');
  background-size: 100%;
  position: relative;
}
.featurePickerPreview_headerMore-gradient {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/160a77ad4c7ea9713618e7d43b/headergradientmore.png');
}
.featurePickerPreview_headerMore-block {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/2c0de9494752925d4dfe580b6a/headertransparentmore.png');
}
.featurePickerPreview_headerMore-pattern {
  background-image: none;
}
.featurePickerPreview_headerMore-pattern .featurePickerPreview_header_image {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/2c0de9494752925d4dfe580b6a/headertransparentmore.png');
}
.featurePickerPreview_headerMore-pattern .featurePickerPreview_headerGeneric_text {
  color: #fff;
}
.featurePickerPreview_headerGeneric {
  width: 100%;
  height: 65px;
  border-bottom: solid 1px #dbddf1;
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/015d852a4a698f0aaf431c4e8c/genericheaderwhite.png');
  background-size: 100%;
  position: relative;
}
.featurePickerPreview_headerGeneric-gradient {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/8a0858ac4a32a29d3f0739c0aa/genericheadergradient.png');
}
.featurePickerPreview_headerGeneric-gradient .featurePickerPreview_headerGeneric_text {
  color: #fff;
}
.featurePickerPreview_headerGeneric-block {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/833ccd9040c9ae184c84501cbf/genericheadertransparent.png');
}
.featurePickerPreview_headerGeneric-block .featurePickerPreview_headerGeneric_text {
  color: #fff;
}
.featurePickerPreview_headerGeneric-pattern {
  background-image: none;
}
.featurePickerPreview_headerGeneric-pattern .featurePickerPreview_header_image {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/833ccd9040c9ae184c84501cbf/genericheadertransparent.png');
}
.featurePickerPreview_headerGeneric-pattern .featurePickerPreview_headerGeneric_text {
  color: #fff;
}
.featurePickerPreview_headerGeneric_text {
  margin: 0;
  position: absolute;
  top: 18px;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 12px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: #3e4245;
  line-height: 47px;
  letter-spacing: 0.5px;
  color: #262a2d;
}
.featurePickerPreview_homeEmpty {
  width: 100%;
  height: 166px;
  position: absolute;
  bottom: 0;
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/22dea10d4b0586a57857768a89/circlebgoverlay.png');
  background-size: 100%;
  display: flex;
  justify-content: center;
}
.featurePickerPreview_homeEmpty_text {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  position: absolute;
  bottom: 20px;
  padding: 5px 20px;
  line-height: 20px;
  border-radius: 15px;
  color: #fff;
  margin: 0;
}
.featurePickerPreview_footer {
  width: 100%;
  height: 56px;
  border-top: solid 1px #dbddf1;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  bottom: 0;
  background-color: #fff;
  z-index: 3;
}
.featurePickerPreview_footer-block .featurePickerPreview_footer_icon_title {
  font-weight: 400;
}
.featurePickerPreview_footer-block .featurePickerPreview_footer_icon-selected .featurePickerPreview_footer_icon_svg {
  width: 30px;
  height: 30px;
  margin: 2px auto 0;
}
.featurePickerPreview_footer-block .featurePickerPreview_footer_icon-selected .featurePickerPreview_footer_icon_title {
  font-weight: 600;
}
.featurePickerPreview_footer_background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}
.featurePickerPreview_footer_icon {
  width: 60px;
  height: 50px;
  background-size: contain;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  position: relative;
}
.featurePickerPreview_footer_icon_svg {
  width: 24px;
  height: 24px;
  margin: 5px auto 0;
}
.featurePickerPreview_footer_icon_title {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 11px;
  font-family: helvetica, sans-serif;
  margin: 3px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.featurePickerPreview_tile {
  width: 100%;
  background-size: 100%;
  position: relative;
}
.featurePickerPreview_tile-newsWidget {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/1080a6644ed8925269ec8ad873/newswidget.png');
  background-size: 100%;
  height: 195px;
}
.featurePickerPreview_tile-facilitiesWidget {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/b1c27f0f4f8da617950c6f9656/facilitieswidget.png');
  background-size: 100%;
  height: 195px;
}
.featurePickerPreview_tile-eventsWidget {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/ecbcdd0a4ad7833143510ac293/eventswidget.png');
  background-size: 100%;
  height: 195px;
}
.featurePickerPreview_tile-eventsWidget-compressed {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/d2edae464e45b262ee7c6629f3/eventsminifiedwidget.png');
  height: 175px;
}
.featurePickerPreview_tile-eventsWidget-compressed .featurePickerPreview_tile_title-widget {
  top: 8px;
}
.featurePickerPreview_tile-groupsWidget {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/de97eb204522b2195cd3683052/groupswidgettransparent.png');
  background-size: 100%;
  height: 195px;
}
.featurePickerPreview_tile-peopleWidget {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/55c0823b4306a2e1d88946718e/peoplewidgettransparent.png');
  background-size: 100%;
  height: 195px;
}
.featurePickerPreview_tile-offersWidget {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/a3e5c5c540549e9a5a45f9bdd9/offerswidget.png');
  background-size: 100%;
  height: 195px;
}
.featurePickerPreview_tile-surveysWidget {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/61032bb2404493e9545fd83882/surveywidget.png');
  background-size: 100%;
  height: 195px;
}
.featurePickerPreview_tile-servicesWidget {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/ecd6e7214deca9f95f9d80b154/serviceswidget.png');
  background-size: 100%;
  height: 195px;
}
.featurePickerPreview_tile-galleryWidget {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/9c5c93b74211a6aa70b2f611c0/imagelibrarywidgettransparent.png');
  background-size: 100%;
  height: 195px;
}
.featurePickerPreview_tile-news {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/919ee268483ba8ff62b2c00104/newsmainbigtransparent.png');
  background-size: 100%;
  height: 455px;
}
.featurePickerPreview_tile-news-compressed {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/6d73d3454beca7b298b55005a2/newsmainsmalltransparent.png');
  height: 388px;
}
.featurePickerPreview_tile-offers {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/d20fafd549a6936f9605dfa202/offersmain.png');
  background-size: 100%;
  height: 481px;
}
.featurePickerPreview_tile-events {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/7d6c22af4f64b86437c8d1e4cf/eventsmainwithbutton.png');
  background-size: 100%;
  height: 475px;
  margin-top: -33px;
}
.featurePickerPreview_tile-facilities {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/629ee73e444689a568be6f5eac/facilitiesmain.png');
  background-size: 100%;
  height: 475px;
}
.featurePickerPreview_tile-people {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/a6fb047646f9bfc8406671226e/peoplemaintransparent.png');
  background-size: 100%;
  height: 456px;
}
.featurePickerPreview_tile-people-allowCall {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/9a18ae7a47e5b72f778d0c3c46/peoplemaintransparentwithphone.png');
}
.featurePickerPreview_tile-groups {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/dd45ab5a4c53886fb347238030/groupsmaintransparent.png');
  background-size: 100%;
  height: 385px;
}
.featurePickerPreview_tile-surveys {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/9983dc6a4ed999b5b8e94234fe/surveymain.png');
  background-size: 100%;
  height: 399px;
}
.featurePickerPreview_tile-services {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/7080bac445ce86d69fab34d7ef/servicesmain.png');
  background-size: 100%;
  height: 478px;
}
.featurePickerPreview_tile-gallery {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/ecd9dd7f4966a080502a3e0b37/imagelibrarymaintransparent.png');
  background-size: 100%;
  height: 486px;
}
.featurePickerPreview_tile_title {
  font-family: -apple-system, BlinkMacSystemFont, 'Source Sans Pro', sans-serif;
  font-weight: 500;
  color: #262a2d;
  font-size: 17px;
  margin: 15px 16px 0;
  line-height: 17px;
  position: relative;
  z-index: 2;
}
.featurePickerPreview_tile_title-widget {
  position: absolute;
  top: 2px;
  left: 0;
}
.featurePickerPreview_datePicker {
  width: 100%;
  height: 70px;
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/0ed183514f3da9018716274d92/datepicker.png');
  background-size: 100%;
}
.featurePickerPreview_events {
  width: 100%;
  height: 570px;
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/065ab0044685856152f449c008/displayallevents.png');
  background-size: 100%;
}
.featurePickerPreview_events-noEmpty {
  height: 483px;
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/f1176b5b4b559d10e3c75eab6d/notdisplayemptydates.png');
}
.featurePickerPreview_hub {
  width: 100%;
  height: 504px;
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/ae02f3644fbc8d8babd48f39d6/hubcontent.png');
  background-size: 100%;
}
.featurePickerPreview_people {
  width: 100%;
  height: 530px;
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/d1f0349f488986adbf4a55f6ac/peoplenocall.png');
  background-size: 100%;
}
.featurePickerPreview_people-allowCall {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/9fcc301a44dd8ff38c5f8e93e0/peoplecall.png');
}
.featurePickerPreview_more {
  width: 100%;
  height: 500px;
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/afb8d64940c9835b5a18e8b83f/morecontent.png');
  background-size: 100%;
}
.featurePickerPreview_more_logout {
  width: 100%;
  height: 122px;
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/9e7949ec4149a6d14493e2f3ac/morelogout.png');
  background-size: 100%;
}
.featurePickerPreview_more_entry {
  width: 100%;
  height: 40px;
  padding: 0 16px;
}
.featurePickerPreview_more_entry:first-of-type .featurePickerPreview_more_entry_inner {
  border-top: none;
}
.featurePickerPreview_more_entry_inner {
  padding: 0 8px;
  height: 100%;
  border-top: solid 1px #dbddf1;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.featurePickerPreview_more_entry_icon {
  font-size: 20px;
}
.featurePickerPreview_more_entry_text {
  margin: 0;
  flex: 1;
  font-size: 13px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #262a2d;
}
.featurePickerPreview_more-noMaintenance {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/1cc110364b638350f4bcb9a18e/morewithoutmaintenance.png');
}
.featurePicker_add .button {
  height: 48px;
  margin-left: 20px;
}
.featurePicker_add .button .buttonText {
  display: flex;
  align-items: center;
}
.featurePicker_add_plus {
  font-size: 25px;
  margin-right: 4px;
}
.featurePicker_addButton {
  width: 160px;
  height: 40px;
  position: relative;
}
.featurePicker_addButton_inner {
  width: 160px;
  height: 40px;
  border-radius: 4px;
  background-color: #f4f7f9;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.featurePicker_addButton_inner:hover .featurePicker_addButton_leftBox {
  background-color: #364196;
}
.featurePicker_addButton_inner:hover .featurePicker_addButton_text {
  color: #364196;
}
.featurePicker_addButton_leftBox {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  background-color: #5c90df;
  display: flex;
  justify-content: center;
  align-items: center;
}
.featurePicker_addButton_leftBox_icon {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  color: #fff;
  font-size: 24px;
  margin: 0;
}
.featurePicker_addButton_text {
  flex: 1;
  padding: 0 10px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700;
  color: #5c90df;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  font-size: 14px;
  margin: 0;
}
.featurePicker_addButton_menu {
  position: absolute;
  bottom: 50px;
  right: 0;
  width: 100%;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 10;
  background-color: #fff;
}
.featurePicker_addButton_menu_option {
  height: 40px;
  background-color: #fff;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  width: 100%;
  margin: 0;
  padding: 10px;
  color: #536280;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  cursor: pointer;
}
.featurePicker_addButton_menu_option:hover {
  background-color: #536280;
  color: #fff;
}
.featurePicker_addButton_menu_option:first-of-type {
  border-radius: 4px 4px 0 0;
}
.featurePicker_addButton_menu_option:last-of-type {
  border-radius: 0 0 4px 4px;
}
.featurePicker_appOptionsLeft {
  width: 350px;
  padding-right: 40px;
  padding-left: 20px;
  border-right: solid 1px #dbddf1;
  min-height: 500px;
}
.featurePicker_nameLogoContainer {
  padding-top: 70px;
}
.featurePicker_logoPreview {
  flex: 1;
  margin-right: 10px;
  height: 50px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.featurePicker_mainSection {
  -webkit-overflow-scrolling: touch;
  font-size: 0;
}
.featurePicker_mainSection-grid {
  display: flex;
}
.featurePicker_mainSection-grid .featurePicker_mainSection_inner {
  width: 310px;
  overflow: initial;
  overflow-x: initial;
  white-space: initial;
}
.featurePicker_mainSection-grid .featurePicker_mainSection_inner .featurePicker_iconOption-4thCol {
  margin-right: 0;
}
.featurePicker_mainSection_inner {
  white-space: nowrap;
  overflow: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  font-size: 0;
  padding: 10px;
}
.featurePicker_helperText {
  margin: 0;
  font-size: 16px;
  color: #5c90df;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-style: italic;
}
.featurePicker_iconButton {
  cursor: pointer;
  width: 50px;
  align-items: center;
  display: flex;
  margin-left: 10px;
}
.featurePicker_iconButton_iconContainer {
  width: 40px;
  height: 40px;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: #fff;
  overflow: hidden;
  position: relative;
  padding: 6px;
}
.featurePicker_iconButton_icon {
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  background-size: 60px 50px;
  background-repeat: no-repeat;
  width: 60px;
  height: 50px;
  position: absolute;
  top: -4px;
  left: -17px;
}
.featurePicker_titleInput {
  margin: 0;
}
.featurePicker_titleInput .genericInput {
  height: 40px;
  width: 200px;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  border: none;
  line-height: 40px;
  background-color: #fff;
  padding: 0 10px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  color: #4a57b7;
  font-size: 20px;
}
.featurePicker_gridToggle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}
.featurePicker_gridToggle_toggle {
  margin-right: 10px;
}
.featurePicker_gridToggle_label {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #536280;
  margin: 0;
}
.featurePicker_iconSection {
  position: absolute;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  top: 10px;
  left: 10px;
  width: 155px;
  padding: 10px 5px 5px 10px;
  z-index: 20;
  background-color: #fff;
}
.featurePicker_iconSection-large {
  width: 295px;
}
.featurePicker_iconOption {
  border: solid 2px #dbddf1;
  border-radius: 4px;
  width: 30px;
  height: 30px;
  position: relative;
  overflow: hidden;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 1px;
  cursor: pointer;
}
.featurePicker_iconOption:not(.featurePicker_iconOption-selected):hover {
  border-color: #a4aada;
}
.featurePicker_iconOption_icon {
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  background-size: 60px 50px;
  background-repeat: no-repeat;
  width: 60px;
  height: 50px;
  position: absolute;
  top: -4px;
  left: -17px;
}
.featurePicker_mainSection_widgetContainer {
  flex: 1;
  padding: 10px;
}
.featurePicker_mainSection_widgetContainer .featurePicker_mainSection_tile {
  display: inline-block;
  margin-bottom: 20px;
}
.featurePicker_mainSection_widgetContainer .featurePicker_mainSection_tile.featurePicker_mainSection_tile-faded {
  display: none;
}
.featurePicker_mainSection_widgetContainer .featurePicker_mainSection_tile.featurePicker_mainSection_tile-canMoveDown,
.featurePicker_mainSection_widgetContainer .featurePicker_mainSection_tile.featurePicker_mainSection_tile-canMoveUp {
  margin-bottom: 40px;
}
.featurePicker_mainSection_widgetContainer .featurePicker_mainSection_tile-tv {
  margin-bottom: 40px;
}
.featurePicker_mainSection_widgetContainer-boxed {
  background-color: #f4f7f9;
  padding: 20px 0 20px 20px;
  margin: 10px 0 10px 50px;
  border-radius: 10px;
  max-width: 640px;
}
.featurePicker_mainSection_widgetContainer-boxed-tv {
  margin-left: 0;
  margin-right: 0;
  max-width: 600px;
}
.featurePicker_mainSection_widgetContainer-boxed-tv.featurePicker_mainSection_widgetContainer-boxed-transparent {
  width: 600px;
}
.featurePicker_mainSection_widgetContainer-boxed-transparent {
  background-color: rgba(0, 0, 0, 0);
}
.featurePicker_mainSection_widgetContainer_title {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: #536280;
  margin-bottom: 20px;
}
.featurePicker_mainSection_widgetContainer_title-optional {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  color: #8695b2;
}
.featurePicker_mainSection_empty {
  max-width: 400px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.featurePicker_mainSection_tile {
  width: 290px;
  height: 450px;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-right: 20px;
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  -moz-transition: opacity 0.2s ease-in-out, height 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out, height 0.2s ease-in-out;
  -webkit-transition: opacity 0.2s ease-in-out, height 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out, height 0.2s ease-in-out;
  position: relative;
  z-index: 2;
  background-color: #fff;
}
.featurePicker_mainSection_tile:hover {
  -webkit-box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
.featurePicker_mainSection_tile:hover .featurePicker_mainSection_tile_addIcon {
  color: #364196;
}
.featurePicker_mainSection_tile:hover .featurePicker_mainSection_tile_info {
  transform: translateY(0);
}
.featurePicker_mainSection_tile:last-of-type {
  margin-right: 0;
}
.featurePicker_mainSection_tile_inner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
}
.featurePicker_mainSection_tile_reorder {
  position: absolute;
  left: 100%;
  top: 0;
  display: none;
  height: 100%;
  flex-direction: column;
  padding-left: 10px;
  justify-content: center;
}
.featurePicker_mainSection_tile_reorder_button {
  background-color: #a1afcc;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  cursor: pointer;
  width: 18px;
  height: 18px;
  border-radius: 9px;
  align-items: center;
  justify-content: center;
  display: none;
}
.featurePicker_mainSection_tile_reorder_button:hover {
  background-color: #67758f;
}
.featurePicker_mainSection_tile_reorder_icon {
  color: #fff;
  font-size: 14px;
}
.featurePicker_mainSection_tile_footer {
  width: 100%;
}
.featurePicker_mainSection_tile_footer_inner {
  height: 50px;
  width: 100%;
  display: flex;
  padding: 10px;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.featurePicker_mainSection_tile_footer_text {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #5f717f;
  margin: 0;
}
.featurePicker_mainSection_tile_footer_description {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #536280;
  margin: 0;
  padding: 0 10px;
  line-height: 15px;
  max-height: 60px;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -webkit-line-clamp: 4;
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
}
.featurePicker_mainSection_tile_footer_tick,
.featurePicker_mainSection_tile_footer_edit {
  font-size: 13px;
  color: #a1afcc;
  margin: 0;
  margin-left: 5px;
}
.featurePicker_mainSection_tile_footer_tick,
.featurePicker_mainSection_tile_footer_settingsButton {
  -moz-transition: opacity 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
}
.featurePicker_mainSection_tile_footer_edit {
  display: none;
  cursor: pointer;
}
.featurePicker_mainSection_tile_options {
  position: absolute;
  top: 6px;
  right: 30px;
  width: 18px;
  min-width: 18px;
  height: 18px;
  border-radius: 9px;
  display: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.featurePicker_mainSection_tile_options_icon {
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  color: #5c90df;
  font-size: 14px;
  line-height: 20px;
}
.featurePicker_mainSection_tile_options_icon:hover {
  color: #364196;
}
.featurePicker_mainSection_tile_optionsButton {
  margin-right: 10px;
}
.featurePicker_mainSection_tile_optionsButton .button .buttonText {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.featurePicker_mainSection_tile_optionsButton_icon {
  margin-right: 4px;
}
.featurePicker_mainSection_tile_image {
  width: 100%;
  flex: 1;
  padding: 20px;
  position: relative;
  overflow: hidden;
}
.featurePicker_mainSection_tile_image_inner {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.featurePicker_mainSection_tile_image_inner_no {
  text-align: center;
  font-size: 60px;
  color: #8695b2;
}
.featurePicker_mainSection_tile_info {
  width: 100%;
  background-color: #5c90df;
  position: absolute;
  bottom: 0;
  left: 0;
  -moz-transition: transform 0.3s ease-in-out;
  -o-transition: transform 0.3s ease-in-out;
  -webkit-transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transform: translateY(100%);
  padding: 20px;
  display: block;
}
.featurePicker_mainSection_tile_info_title {
  margin: 0;
  font-size: 16px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  color: #fff;
}
.featurePicker_mainSection_tile_info_text {
  margin: 10px 0 0;
  font-size: 14px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  color: #fff;
  white-space: initial;
}
.featurePicker_mainSection_tile_image_cancelButton {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 10px;
  height: 10px;
  display: none;
  align-items: center;
  justify-content: center;
}
.featurePicker_mainSection_tile_image_cancelButton_icon {
  color: #a1afcc;
  width: 10px;
  height: 10px;
  cursor: pointer;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.featurePicker_mainSection_tile_image_cancelButton_icon:hover {
  color: #67758f;
}
.featurePicker_mainSection_tile_addIcon {
  color: #5c90df;
  font-size: 30px;
  line-height: 30px;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  margin-right: 10px;
}
.featurePicker_mainSection_tile_title {
  display: flex;
  height: 32px;
  align-items: center;
  padding: 4px 8px;
}
.featurePicker_mainSection_tile-news .featurePicker_mainSection_tile_image_inner {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/7f817a754490be2a2c859db2fe/newsmainmasked.png');
}
.featurePicker_mainSection_tile-offers .featurePicker_mainSection_tile_image_inner {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/08dbe0374bcd999d69d4ca70dd/offermainmasked.png');
}
.featurePicker_mainSection_tile-events .featurePicker_mainSection_tile_image_inner {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/e7f2556d407aa7c9174a2ba903/eventsmainmasked.png');
}
.featurePicker_mainSection_tile-facilities .featurePicker_mainSection_tile_image_inner {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/b663d5ce46eaa1a15dcdff43c0/facilitiesmainitemcatalog.png');
}
.featurePicker_mainSection_tile-people .featurePicker_mainSection_tile_image_inner {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/432fbd9a41fa8c114c9d704abb/peoplemain.png');
}
.featurePicker_mainSection_tile-groups .featurePicker_mainSection_tile_image_inner {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/284b1edd42caa0122ccb8e438d/groupsmain.png');
}
.featurePicker_mainSection_tile-surveys .featurePicker_mainSection_tile_image_inner {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/9e27fd894645a666b0b0f8b268/surveymainmasked.png');
}
.featurePicker_mainSection_tile-services .featurePicker_mainSection_tile_image_inner {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/0e2e5c68484c80e867adf31836/servicesmainitem.png');
}
.featurePicker_mainSection_tile-gallery .featurePicker_mainSection_tile_image_inner {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/9d650c1a4ee888b81a50ed5585/imagelibrarymain.png');
}
.featurePicker_mainSection_tile-newsWidget .featurePicker_mainSection_tile_image_inner {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/fc1ab89c41cc9de0d637845e7d/newswidgetfull.png');
}
.featurePicker_mainSection_tile-groupsWidget .featurePicker_mainSection_tile_image_inner {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/3d88a47444af98380da22b2a4a/groupswidget.png');
}
.featurePicker_mainSection_tile-peopleWidget .featurePicker_mainSection_tile_image_inner {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/47b492564e1ba4a49d7057c49f/peoplewidget.png');
}
.featurePicker_mainSection_tile-eventsWidget .featurePicker_mainSection_tile_image_inner {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/d59d47f44a468ace74d6e5ae6a/eventswidget.png');
}
.featurePicker_mainSection_tile-facilitiesWidget .featurePicker_mainSection_tile_image_inner {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/f4ae38974c71a894415b9ee40b/facilitieswidgetfull.png');
}
.featurePicker_mainSection_tile-surveysWidget .featurePicker_mainSection_tile_image_inner {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/f65815c74ba3a811f4ffe0555c/surveyswidget.png');
}
.featurePicker_mainSection_tile-offersWidget .featurePicker_mainSection_tile_image_inner {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/bd867ec945a687f7d928ee854d/offerswidget.png');
}
.featurePicker_mainSection_tile-servicesWidget .featurePicker_mainSection_tile_image_inner {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/66136b4e496fadad2d71306cdb/serviceswidgetfull.png');
}
.featurePicker_mainSection_tile-galleryWidget .featurePicker_mainSection_tile_image_inner {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/40838c9747e492ae6b48c648c1/imagelibrarywidget.png');
}
.featurePicker_mainSection_tile-header {
  width: 250px;
  height: 130px;
  margin-top: 20px;
  display: inline-block;
}
.featurePicker_mainSection_tile-header .featurePicker_mainSection_tile_image_inner {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/6d5598b945c7a66926eca341ba/headerwhite.png');
}
.featurePicker_mainSection_tile-header.featurePicker_mainSection_tile-selected {
  margin-bottom: 0;
}
.featurePicker_mainSection_tile-footer {
  width: 250px;
  height: 50px;
  margin-top: 20px;
}
.featurePicker_mainSection_tile-footer .featurePicker_mainSection_tile_image {
  display: none;
}
.featurePicker_mainSection_tile-footer.featurePicker_mainSection_tile-selected {
  margin-bottom: 0;
}
.featurePicker_mainSection_tile-header-gradient .featurePicker_mainSection_tile_image_inner {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/43eee380430e90127bd1ee7e08/headergradient.png');
}
.featurePicker_mainSection_tile-header-block .featurePicker_mainSection_tile_image_inner {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/bbbe9ff2461f850aafd666fdad/headerblock.png');
}
.featurePicker_mainSection_tile-header-pattern .featurePicker_mainSection_tile_image_inner {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/7b1c10cd4b56aa3838c10c76e3/headerpattern.png');
}
.featurePicker_mainSection_tile-faded {
  opacity: 0.5;
  display: none;
}
.featurePicker_mainSection_tile-faded:hover {
  opacity: 1;
}
.featurePicker_mainSection_tile-selected {
  cursor: default;
  margin-bottom: 20px;
}
.featurePicker_mainSection_tile-selected:last-of-type {
  margin-bottom: 0;
}
.featurePicker_mainSection_tile-selected:hover {
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.featurePicker_mainSection_tile-selected .featurePicker_mainSection_tile_footer_tick,
.featurePicker_mainSection_tile-selected .featurePicker_mainSection_tile_footer_settingsButton {
  opacity: 1;
}
.featurePicker_mainSection_tile-selected .featurePicker_mainSection_tile_footer_edit {
  display: inline-block;
}
.featurePicker_mainSection_tile-selected .featurePicker_mainSection_tile_image_cancelButton {
  display: flex;
}
.featurePicker_mainSection_tile-selected .featurePicker_mainSection_tile_options {
  display: flex;
}
.featurePicker_mainSection_tile-selected .featurePicker_mainSection_tile_info,
.featurePicker_mainSection_tile-selected .featurePicker_mainSection_tile_addIcon {
  display: none;
}
.featurePicker_mainSection_tile-selected .featurePicker_mainSection_tile_title {
  background-color: #f4f7f9;
  max-width: 125px;
}
.featurePicker_mainSection_tile-selected .featurePicker_mainSection_tile_title .featurePicker_mainSection_tile_footer_text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.featurePicker_mainSection_tile-selected.featurePicker_mainSection_tile-hasOptions:not(.featurePicker_mainSection_tile-mainItem) .featurePicker_mainSection_tile_title {
  max-width: 155px;
}
.featurePicker_mainSection_tile-canMoveDown .featurePicker_mainSection_tile_reorder,
.featurePicker_mainSection_tile-canMoveUp .featurePicker_mainSection_tile_reorder {
  display: flex;
}
.featurePicker_mainSection_tile-canMoveUp .featurePicker_mainSection_tile_reorder_button-up {
  display: flex;
}
.featurePicker_mainSection_tile-canMoveDown .featurePicker_mainSection_tile_reorder_button-down {
  display: flex;
}
.featurePicker_mainSection_tile-canMoveDown.featurePicker_mainSection_tile-canMoveUp .featurePicker_mainSection_tile_reorder_button-down {
  margin-top: 10px;
}
.featurePicker_mainSection_tile-mainItem .featurePicker_mainSection_tile_footer_inner {
  flex-direction: row;
}
.featurePicker_mainSection_tile-mainItem .featurePicker_mainSection_tile_title {
  max-width: 100%;
  min-width: 150px;
  justify-content: space-between;
}
.featurePicker_mainSection_tile-mainItem .featurePicker_mainSection_tile_options {
  position: relative;
  top: 0;
  right: 0;
  margin-right: 5px;
}
.featurePicker_mainSection_tile-mainItem .featurePicker_mainSection_tile_footer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.featurePicker_mainSection_tile-compressed {
  height: 70px;
}
.featurePicker_mainSection_tile-compressed:last-of-type {
  margin-right: 20px;
}
.featurePicker_mainSection_tile-compressed .featurePicker_mainSection_tile_inner {
  flex-direction: row;
  align-items: center;
  padding: 5px;
}
.featurePicker_mainSection_tile-compressed .featurePicker_mainSection_tile_image_inner {
  width: 80px;
}
.featurePicker_mainSection_tile-compressed .featurePicker_mainSection_tile_image_cancelButton,
.featurePicker_mainSection_tile-compressed .featurePicker_mainSection_tile_options {
  position: relative;
  top: 0;
  right: 0;
  margin-left: 5px;
}
.featurePicker_mainSection_tile-compressed .featurePicker_mainSection_tile_footer_inner {
  flex: 1;
  width: auto;
  flex-direction: row;
  padding: 5px;
  padding-right: 0;
}
.featurePicker_mainSection_tile-compressed.featurePicker_mainSection_tile-isEditing .featurePicker_mainSection_tile_image_inner,
.featurePicker_mainSection_tile-compressed.featurePicker_mainSection_tile-isEditing .featurePicker_mainSection_tile_image_cancelButton,
.featurePicker_mainSection_tile-compressed.featurePicker_mainSection_tile-isEditing .featurePicker_mainSection_tile_options {
  display: none;
}
.featurePicker_mainSection_tile-condensed {
  height: 180px;
}
.featurePicker_mainSection_tile-placeholderDrop {
  z-index: 1;
}
.featurePicker_mainSection_tile-placeholderDrop .featurePicker_mainSection_tile_image,
.featurePicker_mainSection_tile-placeholderDrop .featurePicker_mainSection_tile_image_inner,
.featurePicker_mainSection_tile-placeholderDrop .featurePicker_mainSection_tile_footer_inner,
.featurePicker_mainSection_tile-placeholderDrop .featurePicker_mainSection_tile_reorder,
.featurePicker_mainSection_tile-placeholderDrop .featurePicker_mainSection_tile_footer,
.featurePicker_mainSection_tile-placeholderDrop .featurePicker_mainSection_tile_image_cancelButton,
.featurePicker_mainSection_tile-placeholderDrop .featurePicker_mainSection_tile_options {
  display: none;
}
.featurePicker_mainSection_tile-placeholderDrop .featurePicker_mainSection_tile_inner {
  background-color: #f4f7f9;
  border: dashed 1px #dbddf1;
}
.featurePicker_mainSection_tile-dragged {
  opacity: 0.5;
  z-index: 10;
}
.featurePicker_mainSection_tile-dragged .featurePicker_mainSection_tile_reorder {
  display: none;
}
.featurePicker_mainSection_tile-tv {
  margin-bottom: 40px;
  margin-right: 40px;
  width: 250px;
}
.featurePicker_mainSection_tile-tv:last-of-type {
  margin-bottom: 40px;
}
.featurePicker_mainSection_tile-tv .featurePicker_mainSection_tile_footer {
  border-top-width: 0;
}
.featurePicker_mainSection_tile-tv .featurePicker_mainSection_tile_footer_inner {
  flex-direction: row;
}
.featurePicker_mainSection_tile-tv .featurePicker_mainSection_tile_title {
  max-width: 200px;
}
.featurePicker_mainSection_tile-tv .featurePicker_mainSection_tile_image_inner {
  background-size: 80%;
}
.featurePicker_mainSection_tile-tv .featurePicker_mainSection_tile_reorder_button {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}
.featurePicker_mainSection_tile-tv .featurePicker_mainSection_tile_reorder {
  display: flex;
}
.featurePicker_mainSection_tile-tv.featurePicker_mainSection_tile-condensed .featurePicker_mainSection_tile_footer {
  border-top-width: 1px;
}
.featurePicker_mainSection_tile-tv.featurePicker_mainSection_tile-compressed .featurePicker_mainSection_tile_inner {
  flex-direction: row-reverse;
}
.featurePicker_listOption {
  width: 250px;
  height: 50px;
  background-color: #fff;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-right: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  -moz-transition: opacity 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}
.featurePicker_listOption:hover {
  -webkit-box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
.featurePicker_listOption_inner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding: 10px;
}
.featurePicker_listOption_text {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #536280;
  margin: 0;
  flex: 1;
}
.featurePicker_listOption_tick {
  font-size: 20px;
  color: #5c90df;
  margin: 0;
  -moz-transition: opacity 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
}
.featurePicker_listOption-selected .featurePicker_listOption_tick {
  opacity: 1;
}
.featurePicker_titleContainer {
  display: flex;
  align-items: center;
  height: 30px;
  margin-top: 20px;
  margin-bottom: 10px;
}
.featurePicker_sectionTitle {
  display: flex;
  align-items: center;
  width: 250px;
  margin-right: 20px;
}
.featurePicker_sectionTitle_image {
  width: 17px;
  height: 26px;
  margin-right: 10px;
}
.featurePicker_sectionTitle_text {
  margin: 0;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #536280;
}
.featurePicker_popupContainer {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(54, 65, 150, 0.7);
  padding: 40px;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.featurePicker_popupContainer-logoPopup .featurePicker_popup {
  height: 450px;
  width: 650px;
}
.featurePicker_popupContainer-optionsPopup .featurePicker_popup {
  max-height: 1000px;
  max-width: 1400px;
}
.featurePickerLogoPopup_guidelinesTitle {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #4a57b7;
}
.featurePickerLogoPopup_guidelinesText {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #536280;
}
.featurePicker_popup {
  width: 100%;
  height: 100%;
  background-color: #fff;
  -webkit-box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  position: relative;
}
.featurePicker_popup_inner {
  display: flex;
  flex: 1;
  overflow-y: auto;
}
.featurePicker_popup_close {
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
}
.featurePicker_popup_close:hover .featurePicker_popup_close {
  color: #5f717f;
}
.featurePicker_popup_close {
  color: #536280;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  font-size: 20px;
}
.featurePicker_popup_titleContainer {
  display: flex;
  align-items: center;
  height: 40px;
}
.featurePicker_popup_title {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 26px;
  color: #4a57b7;
  display: inline-block;
}
.featurePicker_popup_title-faded {
  opacity: 0.5;
}
.featurePicker_popup_titleIcon {
  font-size: 26px;
  color: #4a57b7;
  margin: 0 10px;
}
.featurePicker_option {
  width: 250px;
  height: 250px;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-right: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  -moz-transition: opacity 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}
.featurePicker_option:hover {
  -webkit-box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
.featurePicker_option .featurePicker_mainSection_tile_footer_text,
.featurePicker_option .featurePicker_mainSection_tile_footer_tick {
  color: #5f717f;
}
.featurePicker_option .featurePicker_mainSection_tile_footer {
  padding-bottom: 10px;
}
.featurePicker_option-selected .featurePicker_mainSection_tile_footer_tick,
.featurePicker_option-selected .featurePicker_mainSection_tile_footer_settingsButton {
  opacity: 1;
}
.featurePicker_option-pattern .featurePicker_mainSection_tile_footer {
  padding-bottom: 0;
}
.featurePicker_option-pattern .featurePicker_mainSection_tile_image_inner {
  background-size: cover;
  background-position: center;
}
.featurePicker_option-whatsOnDatePicker .featurePicker_mainSection_tile_image_inner {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/a1f7e0f148749057f5b84d67a0/datepicker.png');
}
.featurePicker_option-whatsOnEmptyDates .featurePicker_mainSection_tile_image_inner {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/70de639e49d1ad0c0b8f9e5d6c/emptydates.png');
}
.featurePicker_option-phonePeople .featurePicker_mainSection_tile_image_inner {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/787c775e43c68681a672b64f53/allowcalls.png');
}
.featurePicker_option-useLargeNews .featurePicker_mainSection_tile_image_inner {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/900489214c05a14cbef5978005/newscondensedpicker.png');
}
.featurePicker_option-useEventImage .featurePicker_mainSection_tile_image_inner {
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/05d2168649b787be1c2cef0f4a/eventsminifiedpicker.png');
}
.featurePicker-appOptions {
  background-color: #f4f7f9;
}
.featurePicker-appOptions .featurePickerPage {
  background-color: #fff;
}
.chrome-picker-noShadow {
  box-shadow: none !important;
}
.weatherCityPrediction {
  color: #5c90df;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
  margin: 10px 0 0 0;
}
.weatherCityPrediction:hover,
.weatherCityPrediction:active {
  color: #364196;
}
.colourPicker {
  width: 100%;
  padding-top: 100px;
  padding-bottom: 20px;
}
.colourPicker-open {
  padding-top: 50px;
}
.colourPicker_button {
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  width: 100%;
  padding: 8px;
  display: flex;
  cursor: pointer;
  margin-bottom: 10px;
  background-color: #fff;
}
.colourPicker_button:hover {
  -webkit-box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
.colourPicker_text {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  color: #536280;
  font-size: 16px;
  margin: 0;
}
.colourPicker_rainbow {
  width: 70px;
  height: 34px;
  border-radius: 4px;
  background-image: url('https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/f2b79c2240559835cd7d086c00/rainbow.png');
  background-size: 100% 100%;
  position: relative;
}
.colourPicker_rainbow_circle {
  position: absolute;
  border: solid 1px #222;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  transform: translate(-50%, -50%);
}
.colourPicker_previewCircles {
  width: 70px;
  height: 34px;
  position: relative;
}
.colourPicker_previewCircles_circle {
  width: 34px;
  height: 34px;
  border-radius: 17px;
  border: solid 2px #fff;
  position: absolute;
}
.colourPicker_previewCircles_circle-1 {
  left: 0;
  background-color: #ff6c5c;
}
.colourPicker_previewCircles_circle-2 {
  left: 18px;
  background-color: #f19066;
  z-index: 2;
}
.colourPicker_previewCircles_circle-3 {
  left: 36px;
  background-color: #94d0c5;
  z-index: 3;
}
.colourQuickChoices {
  width: 300px;
  height: 225px;
  margin-top: 40px;
  margin-bottom: 40px;
}
.colourQuickChoices_column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.colourQuickChoices_circle {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.colourQuickChoices_circle_tick {
  color: #fff;
  font-size: 20px;
  margin: 0;
  opacity: 0;
  -moz-transition: opacity 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}
.colourQuickChoices_circle-selected .colourQuickChoices_circle_tick {
  opacity: 1;
}
.signUpContainer {
  min-height: 100vh;
  overflow-y: scroll;
}
.signUpContainer .background {
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 0;
  background: linear-gradient(to bottom, #7168b5 0%, #5c90df 100%);
}
.signUpContainer .deco-pluss45deg {
  position: fixed;
  top: 250px;
  right: -50px;
  opacity: 0.2;
}
.signUpContainer .deco-pluss {
  position: fixed;
  top: 200px;
  left: 150px;
  opacity: 0.2;
}
.signUpContainer .deco-circle {
  position: fixed;
  bottom: 80px;
  left: 120px;
  opacity: 0.2;
}
.iconTextButton {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.iconTextButton:hover .iconTextButton_icon,
.iconTextButton:active .iconTextButton_icon,
.iconTextButton:hover .iconTextButton_text,
.iconTextButton:active .iconTextButton_text {
  color: #364196;
}
.iconTextButton_icon {
  color: #4a57b7;
  font-size: 16px;
  margin-right: 8px;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.iconTextButton_text {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  color: #4a57b7;
  font-size: 14px;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  margin: 0;
}
.iconTextButton-action .iconTextButton_icon,
.iconTextButton-action .iconTextButton_text {
  color: #5c90df;
}
.iconTextButton-action:hover .iconTextButton_icon,
.iconTextButton-action:active .iconTextButton_icon,
.iconTextButton-action:hover .iconTextButton_text,
.iconTextButton-action:active .iconTextButton_text {
  color: #364196;
}
.deco-pluss45deg {
  background-image: url('https://plusscommunities.com/assets/decos/pluss_white-45deg.svg');
  background-size: contain;
  background-repeat: no-repeat;
  width: 350px;
  height: 350px;
}
.deco-pluss {
  background-image: url('https://plusscommunities.com/assets/decos/pluss_white.svg');
  background-size: contain;
  background-repeat: no-repeat;
  width: 75px;
  height: 75px;
}
.deco-circle {
  background-image: url('https://pluss-prd-uploads.s3-ap-southeast-2.amazonaws.com/assets/deco/circle_sml_white.svg');
  background-size: contain;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
}
.signUp_account {
  background-color: #fff;
  margin-top: 100px;
  border-radius: 4px;
  padding: 30px;
}
.signUp_account-noTop {
  margin-top: 0;
}
.signUp_title {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  color: #4a57b7;
  font-size: 28px;
}
.signUp_title-noDot:after {
  content: '';
}
.signUp_subtitle {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  color: #536280;
  font-size: 16px;
  margin: 0;
}
.signUp_inputContainer {
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.signUp_inputContainer--noAlign {
  align-items: default;
}
.signUp_inputContainer-other .signUp_inputLabel {
  width: 220px;
  padding-right: 20px;
  text-align: right;
}
.signUp_inputContainer-disabled {
  opacity: 0.3;
}
.signUp_inputLabel {
  width: 160px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  color: #536280;
  font-size: 16px;
}
.signUp_inputLabel-widthAuto {
  width: auto;
}
.signUp_inputLabel-dropdown {
  width: 220px;
  padding-right: 20px;
}
.signUp_input {
  flex: 1;
  height: 56px;
  border: solid 1px #dbddf1;
  border-radius: 3px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding: 8px;
}
.signUp_input.validation-valid .signUp_input_validation {
  background-color: #5c90df;
}
.signUp_input.validation-error {
  border-color: #c02743;
}
.signUp_input.validation-error .signUp_input_validation {
  background-color: #c02743;
}
.signUp_input_text,
.signUp_input-select .select_input {
  border: none;
  line-height: 40px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #262a2d;
  flex: 1;
}
.signUp_input_validation {
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: #dbddf1;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.signUp_input-valid .signUp_input_validation {
  background-color: #5c90df;
}
.signUp_input-error {
  border-color: #c02743;
}
.signUp_input-error .signUp_input_validation {
  background-color: #c02743;
}
.signUp_input-select {
  flex-direction: row;
  position: relative;
}
.signUp_input-select .select_input {
  flex: 1;
}
.signUp_input-select .select_options {
  top: 60px;
}
.signUp_multi {
  flex: 1;
  display: flex;
  align-items: center;
}
.signUp_multi_option {
  flex: 1;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.signUp_multi_option.validation-error .signUp_multi_option_box {
  border-color: #c02743;
}
.signUp_template {
  position: relative;
  width: 140px;
  height: 140px;
  padding: 10px 20px;
  display: inline-block;
  margin: 20px 20px 0 0;
  background: #fff;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  vertical-align: top;
  cursor: pointer;
  -moz-transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  -o-transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  -webkit-transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
.signUp_template:not(.signUp_template-hasSelection):hover .signUp_template_icon {
  transform: scale(1.4);
}
.signUp_template:not(.signUp_template-hasSelection):hover .signUp_template_hover {
  opacity: 1;
}
.signUp_template-inGrid {
  position: absolute;
}
.signUp_template-hasSelection {
  cursor: unset;
}
.signUp_template-hasSelection .signUp_template_icon {
  transform: scale(1.4);
}
.signUp_template-hasSelection:not(.signUp_template-isSelected) {
  opacity: 0;
}
.signUp_template-byo {
  overflow: hidden;
  padding: 0;
}
.signUp_template-byo .signUp_template_background {
  width: 200%;
  height: 200%;
  position: absolute;
  top: -50%;
  left: -50%;
  background: linear-gradient(135deg, #4a57b7 30%, #5c90df 70%);
}
.signUp_template-byo .signUp_template_inner {
  padding: 10px 20px;
  position: relative;
  z-index: 2;
}
.signUp_template-byo .signUp_template_title {
  color: #fff;
}
.signUp_template-byo:hover .signUp_template_background {
  -webkit-animation: rotate-animation 2s linear infinite;
  -moz-animation: rotate-animation 2s linear infinite;
  animation: rotate-animation 2s linear infinite;
}
@-moz-keyframes rotate-animation {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotate-animation {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotate-animation {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.signUp_template_hover {
  -webkit-box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  -moz-transition: opacity 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
  width: 140px;
  height: 140px;
  position: absolute;
  top: 0;
  left: 0;
}
.signUp_template_inner {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.signUp_template_icon {
  width: 40px;
  height: 40px;
  -moz-transition: transform 0.2s ease-in-out;
  -o-transition: transform 0.2s ease-in-out;
  -webkit-transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
}
.signUp_template_title {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  color: #262a2d;
  font-size: 16px;
  margin: 10px 0 0 0;
  text-align: center;
  line-height: 18px;
}
.signUp_template_award {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 10px;
  margin: 10px 0 0 0;
  padding: 5px 10px;
  line-height: 10px;
  border-radius: 10px;
  background-color: rgba(92, 144, 223, 0.2);
  color: #5c90df;
}
.signUp_changeTemplate {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  color: #4a57b7;
  font-size: 14px;
  cursor: pointer;
  width: 140px;
  text-align: center;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  margin-top: 10px;
  position: relative;
  z-index: 10;
}
.signUp_changeTemplate:hover {
  color: #364196;
}
.signUp_featureTitle {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  color: #536280;
  font-size: 18px;
}
.signUp_feature {
  width: 140px;
  height: 40px;
  line-height: 40px;
  color: #5c90df;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  border-radius: 20px;
  font-size: 18px;
  text-align: center;
  display: inline-block;
  margin: 20px 20px 0 0;
}
.signUp_lottieText {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  text-align: center;
  margin-bottom: 40px;
  font-size: 24px;
  color: #262a2d;
}
.signUp_bottom {
  margin-top: 50px;
  padding: 0 30px;
}
.signUp_bottom_step {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
  color: #fff;
  font-size: 16px;
  margin: 0;
  margin-right: 30px;
}
.signUp_bottom_stepIndicator {
  margin-right: 10px;
  width: 30px;
  height: 2px;
  border-radius: 2px;
  background-color: #fff;
  opacity: 0.2;
}
.signUp_bottom_stepIndicator-highlighted {
  height: 4px;
  opacity: 1;
}
.signUp_separator {
  width: 100%;
  height: 1px;
  background-color: #dbddf1;
}
.signUp_terms {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
  font-style: italic;
  font-size: 14px;
  color: #a1afcc;
}
.signUp_websitePopup {
  width: 640px;
  padding: 30px;
}
.signUp_websiteInput {
  border: solid 2px #5c90df;
  padding: 12px 16px;
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
  font-size: 26px;
  width: 100%;
  border-radius: 2px;
  margin-top: 25px;
  color: #5c90df;
}
.signUp_websiteInput::placeholder {
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
  color: rgba(92, 144, 223, 0.5);
}
.signUp_branding {
  width: 640px;
  margin-right: 30px;
  position: relative;
  z-index: 5;
}
.signUp_branding_inner {
  background-color: #fff;
  border-radius: 4px;
  width: 100%;
  padding: 30px;
}
.signUp_siteNameInput {
  border: none;
  padding: 0;
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
  font-size: 36px;
  width: 100%;
  color: #262a2d;
  border-bottom: solid 1px #dbddf1;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.signUp_siteNameInput::placeholder {
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
  color: rgba(38, 42, 45, 0.5);
}
.signUp_siteNameInput.signUp_input-error {
  border-bottom-color: #c02743;
}
.signUp_colourCircle {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  display: inline-block;
  margin: 0 14px 14px 0;
  cursor: pointer;
  vertical-align: top;
}
.signUp_colourCircle_tick {
  font-size: 20px;
  line-height: 50px;
  color: #fff;
  width: 50px;
  text-align: center;
}
.signUp_colourCircle-small {
  width: 25px;
  height: 25px;
  margin: 0 10px 0 0;
}
.signUp_colourInput {
  margin: 0;
  cursor: pointer;
  color: #536280;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  font-size: 16px;
  border-bottom: solid 1px #dbddf1;
  width: 60px;
  text-align: center;
}
.popup-signUpSuccess .popup_box {
  width: 600px;
  align-items: center;
}
.popup-signUpSuccess .successPopup_tick {
  margin-top: 0;
  margin-bottom: 40px;
}
.popup-signUpSuccess .popup_title,
.popup-signUpSuccess .popup_subtitle {
  text-align: center;
}
.signUpSuccess {
  width: 800px;
  background-color: #fff;
  border-radius: 4px;
  padding: 30px;
}
.signUpSuccess_seeItText {
  font-size: 22px;
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
  color: #536280;
}
.signUpSuccess_footer {
  background-image: url(https://pluss-prd-media.s3-ap-southeast-2.amazonaws.com/assets/cta-bg.svg);
  background-size: cover;
  background-position: center;
  width: 100%;
  position: fixed;
  bottom: 0;
  height: 100px;
  z-index: 500;
}
.signUpSuccess_footer_text {
  margin: 0;
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
  font-size: 34px;
  color: #fff;
}
.signUpSuccess_plussLogo {
  height: 35px;
  margin-right: 20px;
}
.signUpSuccess_plussLogoText {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #536280;
  flex: 1;
  margin: 0;
}
.signUpSuccess_appStoreImage {
  height: 35px;
}
.signUpSuccess_whiteLabelTitle {
  margin-top: 20px;
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
  color: #536280;
  font-size: 22px;
}
.signUpSuccess_clientLogo {
  width: 130px;
  height: 130px;
  border-radius: 2px;
  border: solid 1px #dbddf1;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 20px;
}
.signUpSuccess_right {
  flex: 1;
}
.onboardingBlocker {
  position: fixed;
  z-index: 1000000000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.onboardingFade {
  position: absolute;
  z-index: 1000000000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
}
.onboardingContainer {
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  border-radius: 10px;
  position: fixed;
  width: 650px;
  padding: 20px;
  z-index: 1000000001;
}
.onboardingContainer .button--tertiary .button {
  width: 100px;
}
.onboardingContainer_close {
  color: #fff;
  font-size: 24px;
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
}
.onboardingContainer_title {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  color: #fff;
  font-size: 30px;
  margin: 0;
  letter-spacing: 1.2px;
}
.onboardingContainer_bottom {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-top: 10px;
}
.onboardingContainer_laptop {
  width: 200px;
  margin-left: 40px;
}
.onboardingContainer_bottomLeft {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.onboardingContainer_text {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
  color: #fff;
  font-size: 14px;
  letter-spacing: 0.5px;
  margin-bottom: 20px;
}
.onboardingContainer-skyBlue {
  background-color: #7da6e5;
}
.onboardingContainer-skyBlue .button--tertiary.button--active .buttonText {
  color: #7da6e5;
}
.onboardingContainer-skyBlue .button--tertiary.button--active .button:hover .buttonText,
.onboardingContainer-skyBlue .button--tertiary.button--active .button:active .buttonText {
  color: #6897e1;
}
.onboardingContainer-teal {
  background-color: #49bdbb;
}
.onboardingContainer-teal .button--tertiary.button--active .buttonText {
  color: #49bdbb;
}
.onboardingContainer-teal .button--tertiary.button--active .button:hover .buttonText,
.onboardingContainer-teal .button--tertiary.button--active .button:active .buttonText {
  color: #3faeac;
}
.onboardingContainer-purple {
  background-color: #6e79c5;
}
.onboardingContainer-purple .button--tertiary.button--active .buttonText {
  color: #6e79c5;
}
.onboardingContainer-purple .button--tertiary.button--active .button:hover .buttonText,
.onboardingContainer-purple .button--tertiary.button--active .button:active .buttonText {
  color: #5c68be;
}
.onboardingContainer-intro_dash_1 {
  right: 30px;
  bottom: 30px;
}
.onboardingContainer-intro_fp_1 {
  left: 50%;
  top: 125px;
  transform: translateX(-50%);
}
.onboardingContainer-intro_fp_2 {
  right: 30px;
  bottom: 87px;
}
.onboardingContainer-intro_fp_options_1 {
  right: 70px;
  bottom: 127px;
}
@media screen and (min-width: 1480px) {
  .onboardingContainer-intro_fp_options_1 {
    transform: translateX(50vw);
    right: initial;
    left: 20px;
  }
}
@media screen and (min-height: 1080px) {
  .onboardingContainer-intro_fp_options_1 {
    transform: translateY(-50vh);
    top: initial;
    bottom: -410px;
  }
}
@media screen and (min-height: 1080px) and (min-width: 1480px) {
  .onboardingContainer-intro_fp_options_1 {
    transform: translate(50vw, -50vh);
  }
}
.onboardingContainer-intro_fp_tv_1 {
  right: 30px;
  bottom: 87px;
}
.introComplete .popup_box {
  width: 640px;
  display: flex;
}
.introComplete .popup_title {
  margin-top: 60px;
}
.introComplete_phone {
  width: 200px;
  margin-right: 30px;
  margin-left: 30px;
  margin-top: 30px;
}
.introComplete_appStoreImage {
  height: 35px;
}
.introComplete_right {
  flex: 1;
  padding-right: 80px;
}
.visitorSignIn_header {
  padding: 10px;
  display: flex;
  justify-content: center;
}
.visitorSignIn_header_logo {
  height: 40px;
}
.visitorSignIn_banner_title {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  font-size: 30px;
  color: #fff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.visitorSignIn_banner_subtitle {
  margin-top: 10px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 15px;
  color: #fff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.visitorSignIn_header_subtitle {
  margin-top: 10px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
  font-size: 15px;
  color: #262a2d;
}
.visitorSignIn_header_explanationBox {
  padding: 10px;
  margin-top: 10px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  font-size: 15px;
  color: #fff;
}
.visitorSignIn_banner {
  height: 150px;
  width: 100%;
  position: relative;
}
.visitorSignIn_banner_image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.visitorSignIn_banner_gradient {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  padding: 20px 10px;
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.2));
  z-index: 2;
}
.visitorSignIn_questionSection {
  padding: 0 10px 10px 10px;
}
.visitorSignIn_question {
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  border-radius: 0;
  padding: 10px;
  margin-bottom: 10px;
}
.visitorSignIn_form {
  padding: 10px;
}
.visitorSignIn_formSection {
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  border-radius: 0;
  padding: 10px 10px 10px 20px;
  margin-bottom: 10px;
}
.visitorSignIn_formSection_title {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  color: #262a2d;
  font-size: 14px;
}
.visitorSignIn_formSelector {
  display: flex;
  padding: 10px;
}
.visitorSignIn_formOption {
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  height: 40vw;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-top: 10px;
  flex-direction: column;
}
.visitorSignIn_formOption:first-of-type {
  margin-top: 0;
}
.visitorSignIn_formOption_title {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  color: #717b81;
  font-size: 26px;
  cursor: pointer;
  margin-top: 5px;
  text-align: center;
}
.visitorSignIn_text-staticTitle {
  font-size: 20px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  color: #262a2d;
}
.visitorSignIn_text-staticText {
  font-size: 15px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  color: #262a2d;
}
.visitorSignInPreviewContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.visitorSignInPreviewContainer .managingPageTitle {
  margin-bottom: 20px;
}
.visitorSignInPreviewContainer_inner {
  border: solid #262a2d 2px;
  height: 60vh;
  width: 600px;
  overflow-y: auto;
  overflow-x: hidden;
}
/* FOOD */
.dishCard {
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: #fff;
  width: 256px;
  height: 270px;
  margin-right: 25px;
  margin-top: 25px;
  display: inline-block;
  cursor: pointer;
  position: relative;
}
.dishCard_action {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 24px;
  height: 24px;
  background-color: #fff;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.dishCard_action:hover {
  background-color: #5c90df;
}
.dishCard_action:hover .dishCard_action_icon {
  color: #fff;
}
.dishCard_action-edit {
  right: 44px;
}
.dishCard_action_icon {
  font-size: 15px;
  color: #5c90df;
  margin: 0;
  text-align: center;
  line-height: 24px;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.dishCard_image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 256px;
  height: 190px;
}
.dishCard_bottom {
  height: 80px;
  padding: 10px;
  display: flex;
  flex-direction: column;
}
.dishCard_bottom_title {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 18px;
  margin: 0;
  color: #3f4264;
}
.dishCard_bottom_fill {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.dishCard_bottom_description {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 12px;
  margin: 0;
  color: #8695b2;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -webkit-line-clamp: 2;
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
}
.weekSelector {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.weekSelector_changeWeek {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.weekSelector_changeWeek:hover .weekSelector_changeWeek_icon,
.weekSelector_changeWeek:hover .weekSelector_changeWeek_text {
  color: #5c90df;
}
.weekSelector_changeWeek-next {
  flex-direction: row-reverse;
}
.weekSelector_changeWeek-next .weekSelector_changeWeek_icon {
  margin-right: 0;
  margin-left: 8px;
}
.weekSelector_changeWeek_icon {
  font-size: 15px;
  color: #8695b2;
  margin-right: 8px;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.weekSelector_changeWeek_text {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #8695b2;
  margin: 0;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.weekSelector_day {
  height: 26px;
  width: 130px;
  text-align: center;
  line-height: 26px;
  background-color: #dbddf1;
  color: #8695b2;
  border-radius: 13px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 14px;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  cursor: pointer;
}
.weekSelector_day:hover {
  background-color: #364196;
  color: #fff;
}
.weekSelector_day-selected {
  background-color: #5c90df;
  color: #fff;
}
/* Overwrites */
.noTopBorder {
  border-top: none;
}
.noBottomBorder {
  border-bottom: none;
}
.noLeftBorder {
  border-left: none;
}
.noRightBorder {
  border-right: none;
}
/* TV Mode */
.tvscreen-forceRotate {
  width: 100vh;
  height: 100vw;
  position: fixed;
  transform: rotate(90deg);
  transform-origin: center;
  bottom: -420px;
  left: 420px;
}
.tvscreen-forceRotate .tvscreen {
  height: 100vw;
}
.tvscreen-forceRotate270 {
  transform: rotate(270deg);
}
.tvscreen {
  display: flex;
  height: 100vh;
  flex-direction: column;
}
.tvscreenPortrait {
  display: flex;
  height: 100vh;
  flex-direction: column;
}
/* --- TV Header */
.tvheader {
  background-color: #ffffff;
  -webkit-box-shadow: 1px 1px 30px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 1px 30px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 30px rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 80px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
}
.logocontainer {
  flex: 1;
  padding-left: 40px;
}
.logoimage {
  height: 50px;
}
.datetimetext {
  text-align: right;
  font-family: 'Source Sans Pro';
  font-size: 18pt;
  font-weight: 800;
  color: #5f717f;
  letter-spacing: 1pt;
  padding-right: 40px;
}
/* Users */
.user_addSite {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 13px;
  color: #5c90df;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  cursor: pointer;
}
.user_addSite:hover {
  color: #364196;
}
.welcomeemail {
  margin-top: 20px;
}
.sendWelcome {
  color: #5c90df;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  cursor: pointer;
}
.sendWelcome:hover {
  color: #364196;
}
.fa-status-true {
  color: #00cc9c;
}
.fa-status-false {
  color: #c02743;
}
.fa-status-unknown {
  color: #67758f;
}
.userStatusIcon {
  width: 25px;
  padding-right: 8px;
}
.inviteCode_container {
  height: 50px;
  width: 140px;
  background-color: #fff;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #dbddf1;
  margin-top: 8px;
}
.inviteCode_code {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  color: #5c90df;
  font-size: 20px;
  line-height: 50px;
  margin: 0;
}
.inviteCode_expiry {
  margin-top: 8px;
  margin-bottom: 0;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 13px;
  color: #5a5a6e;
}
.inviteCode_generateCode {
  margin-top: 8px;
  margin-bottom: 0;
  cursor: pointer;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  font-size: 13px;
  color: #5c90df;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.inviteCode_generateCode:hover {
  color: #364196;
}
/* --- TV Body */
.tvbody {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 1;
}
.tvbodyPortrait {
  flex: 2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 1;
}
.tvbodyPortrait .tvcontent {
  flex: 1;
  display: flex;
  flex-direction: column;
  z-index: 1;
}
.tvbodyPortrait .tvsubcontent {
  display: flex;
  flex-direction: row;
  padding-left: 50px;
  max-height: 500px;
}
.tvbodyPortrait .tvtitle {
  flex: 1;
  padding-right: 50px;
  padding-top: 20px;
  background-image: url('https://pluss60-dev-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:efbab8db-136a-446e-b14a-d00af0067841/public/25b6e1754573a71518cfc1fbb0/asset.png');
  background-position-x: 250px;
  background-position-y: -30px;
  background-repeat: no-repeat;
}
.tvbodyPortrait .tvfeatured {
  background-size: cover;
}
.tvbodyPortrait .tvevents {
  display: flex;
  flex-direction: column;
  padding-left: 50px;
  flex: 0 0 auto;
}
.tvbodyPortrait .eventtitle {
  flex: 0 0 auto;
}
.tvbodyPortrait .eventlist {
  flex-grow: 1;
  flex-wrap: wrap;
  display: grid;
  padding: 5px;
  grid-template-columns: repeat(auto-fit, minmax(450px, 60px));
  grid-gap: 60px;
}
.tvbodyPortrait .eventcontainer {
  flex: 1;
  padding-bottom: 40px;
}
.tvbodyPortrait .grid-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-self: flex-start;
}
.tvbodyPortrait .eventcard {
  width: 450px;
  height: 270px;
  border-radius: 1px;
  background-color: #fff;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.1);
}
.tvbodyPortrait .eventcardimg {
  width: 450px;
  height: 180px;
  border-radius: 1px;
  display: block;
}
.tvbodyPortrait .tvevents {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
/* ------ TV Content */
.tvcontent {
  flex: 4;
  display: flex;
  flex-direction: column;
  z-index: 1;
}
.weatherbox {
  width: 300px;
  height: 300px;
  -webkit-box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
  margin-top: 40px;
}
.weathertitle {
  font-family: 'Source Sans Pro';
  letter-spacing: 4pt;
  font-size: 16pt;
  font-weight: 800;
  text-transform: uppercase;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
}
.weathercontent {
  background-color: #89c4f4;
  height: 150px;
  display: block;
}
.weathericon {
  position: relative;
  margin-top: 30px;
  margin-left: 90px;
  width: 130px;
}
.weathercity {
  font-family: 'Source Sans Pro';
  font-weight: 'Semibold';
  font-size: 16pt;
  padding-left: 20px;
  color: #323232;
}
.weatherinfo {
  font-family: 'Source Sans Pro';
  font-size: 14pt;
  padding-left: 20px;
  margin-top: -5px;
  color: #323232;
}
.tvsubcontent {
  flex: 1;
  display: flex;
  flex-direction: row;
  padding-left: 50px;
}
.headingtextbold {
  font-family: 'Source Sans Pro';
  font-size: 36pt;
  font-weight: 700;
  letter-spacing: 2;
  color: #262a2d;
  line-height: 40pt;
}
.headingtext {
  font-family: 'Source Sans Pro';
  font-size: 32pt;
  font-weight: 300;
  color: #262a2d;
}
.subtitletext {
  font-family: 'Source Sans Pro';
  font-size: 16pt;
  font-weight: 400;
  letter-spacing: 1px;
}
.tvtitle {
  flex: 1;
  padding-right: 50px;
  padding-top: 20px;
  background-image: url('https://pluss60-dev-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:efbab8db-136a-446e-b14a-d00af0067841/public/25b6e1754573a71518cfc1fbb0/asset.png');
  background-position-x: 250px;
  background-position-y: -30px;
  background-repeat: no-repeat;
}
.tvfeatured {
  flex: 4;
  background-size: cover;
}
.tvfeaturedinnerPortrait {
  width: 100%;
  height: 100%;
  background: linear-gradient(transparent, rgba(30, 30, 30, 0.6));
  background-size: cover;
  position: relative;
}
.tvfeaturedinner {
  width: 100%;
  height: 100%;
  background: linear-gradient(transparent, rgba(30, 30, 30, 0.6));
  background-size: cover;
  position: relative;
}
.tvfeaturedcontent {
  position: absolute;
  bottom: 20px;
  left: 20px;
}
.smallheading {
  font-family: 'Source Sans Pro';
  text-transform: uppercase;
  letter-spacing: 3px;
  font-weight: 800;
  color: #262a2d;
  font-size: 13pt;
}
.featuredhighlight {
  background-color: #fff;
  border-radius: 30px;
  padding: 2px;
  -webkit-box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  width: 550px;
  margin-top: 20px;
}
.featuredtext {
  font-family: 'Source Sans Pro';
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 800;
  color: #262a2d;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  font-size: 14pt;
}
/* ------ TV Announcements */
.tvannouncements {
  width: 450px;
  background-color: #ffffff;
  -webkit-box-shadow: 1px 1px 30px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 1px 30px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 30px rgba(0, 0, 0, 0.2);
  align-self: stretch;
  z-index: 2;
  position: relative;
}
.tvannouncementsPortrait {
  display: flex;
  width: 100%;
  height: 530px;
  flex-direction: column;
  background-color: #ffffff;
  -webkit-box-shadow: 1px 1px 30px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 1px 30px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 30px rgba(0, 0, 0, 0.2);
  align-self: stretch;
  z-index: 2;
}
.tvannouncementsPortrait .announcementhead {
  padding-left: 30px;
  line-height: 40px;
}
.tvannouncementsPortrait .announcementcontent {
  padding-left: 50px;
  padding-top: 10px;
  overflow: hidden;
  flex: 1;
}
.tvannouncementsPortrait .announcementitem {
  display: inline-block;
  width: 33.3%;
  padding-right: 30px;
  padding-bottom: 20px;
}
.tvannouncementsPortrait .announcetitle {
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -webkit-line-clamp: 3;
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 0;
  margin-bottom: 0;
}
.tvannouncementsPortrait .announcementOffer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 130px;
  z-index: 2;
}
.announcementhead {
  margin: 30px 20px 0;
  line-height: 40px;
}
.announcementcontent {
  padding: 20px;
  overflow: hidden;
  max-height: 930px;
}
.announcementitem {
  padding-bottom: 30px;
}
.announcetitle {
  font-size: 16pt;
  font-family: 'Source Sans Pro';
  color: #323232;
  font-weight: bold;
  line-height: 22pt;
  word-break: break-word;
}
.announcedatetime {
  color: #5f717f;
  font-size: 14pt;
  padding-top: 10px;
}
.announcementOffer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 130px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
/* TV Events */
.tvevents {
  flex: 1;
  padding-left: 50px;
  padding-top: 20px;
}
.eventtitle {
  flex: 1;
}
.eventlist {
  display: grid;
  padding: 5px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 50px));
  grid-gap: 50px;
}
.eventcontainer {
  padding-bottom: 10px;
}
.eventcard {
  width: 300px;
  height: 270px;
  border-radius: 1px;
  background-color: #fff;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.1);
}
.eventcardimage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.eventcardimg {
  width: 300px;
  height: 180px;
  border-radius: 1px;
  display: block;
}
.cardTitle {
  margin-left: 20px;
  font-family: 'Source Sans Pro';
  font-size: 16pt;
  font-weight: 600;
  letter-spacing: 1px;
  color: #262a2d;
}
.cardText {
  margin-left: 20px;
  font-family: 'Source Sans Pro';
  font-size: 12pt;
  font-weight: 400;
  letter-spacing: 1px;
  padding-bottom: 10px;
  color: #5f717f;
}
.addUserPopupBackground {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
  width: 100%;
  height: 100%;
  background-color: rgba(60, 60, 80, 0.5);
}
.addUserPopup {
  display: flex;
  flex-direction: column;
  width: 390px;
  height: 500px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  border-radius: 10px;
}
.addUserPopup .topSection {
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
  padding: 30px 30px 0 30px;
}
.addUserPopup .bottomSection {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid rgba(60, 60, 80, 0.1);
}
.addUserPopup .closeButton {
  font-size: 20px;
  color: #536280;
  position: absolute;
  top: 16px;
  right: 16px;
  font-weight: normal;
}
.addUserPopup .heading {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 26px;
  color: #536280;
  letter-spacing: 0.8px;
}
.addUserPopup .searchContainer {
  margin-top: 16px;
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #dbddf1;
  padding-bottom: 13px;
  overflow-y: hidden;
  resize: none;
}
.addUserPopup .searchIcon {
  font-size: 20px;
  color: #8695b2;
}
.addUserPopup .searchInput {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  margin-left: 16px;
  font-size: 16px;
  border: none;
  line-height: 14px;
  color: #8695b2;
  background-color: rgba(0, 0, 0, 0);
}
.addUserPopup .searchResultContainer {
  margin: 20px 0 20px 0;
  flex: 1;
  overflow: scroll;
}
.addUserPopup .searchItemContainer {
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.addUserPopup .searchItemIcon {
  margin-right: 14px;
  margin-left: 6px;
  height: 36px;
  width: 36px;
  border-radius: 18px;
}
.addUserPopup .searchItemText {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #262a2d;
}
.addUserPopup .saveButton {
  margin: 16px 30px 16px 0;
}
.broadcasterList {
  width: 70%;
}
.broadcasterList .broadcasterContainer {
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.broadcasterList .broadcasterImage {
  margin-right: 10px;
  height: 30px;
  width: 30px;
  border-radius: 15px;
}
.broadcasterList .broadcasterName {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  flex: 1;
  font-size: 14px;
  color: #262a2d;
}
/* Phone Screen */
.payment-container {
  padding-top: 25px;
  padding-left: 20px;
  padding-right: 20px;
}
.payment-heading {
  font-family: 'sf-semibold', 'sans-serif';
  font-size: 20px;
  line-height: 14px;
  color: #262a2d;
  padding-bottom: 28px;
}
.payment-error {
  font-family: 'sf-medium', 'sans-serif';
  font-size: 14px;
  color: #c02743;
  padding-bottom: 10px;
}
.payment-error p {
  margin-top: 0;
  margin-bottom: 0;
}
.payment-field {
  padding-bottom: 20px;
}
.payment-field .label {
  font-family: 'sf-medium', 'sans-serif';
  font-size: 13px;
  line-height: 24px;
  color: #717b81;
  padding: 0;
}
.payment-field .genericInput {
  font-family: 'sf-regular', 'sans-serif';
  font-size: 17px;
  line-height: 24px;
  color: #262a2d;
  padding-left: 0;
  padding-right: 0;
  border-radius: 0;
}
.payment-field .genericInput.padded {
  padding-top: 8px;
}
.payment-field .genericInput.focused {
  border-bottom: 1px solid #262a2d;
}
.payment-field .separator {
  font-family: 'sf-regular', 'sans-serif';
  font-size: 20px;
  line-height: 32px;
  color: #262a2d;
  background-color: transparent;
  vertical-align: top;
  padding-top: 1px;
  padding-bottom: 4px;
}
.payment-field #expiry-month,
.payment-field #expiry-year,
.payment-field .gw-proxy-expiryMonth,
.payment-field .gw-proxy-expiryYear {
  width: 25px !important;
  text-align: center !important;
}
.payment-field #security-code,
.payment-field .gw-proxy-securityCode {
  width: 50px !important;
}
.payment-button {
  width: 100%;
  height: 36px;
}
.payment-button .buttonText {
  font-family: 'sf-semibold', 'sans-serif';
  font-size: 14px;
  line-height: 25px;
}
.dashboard_seeAllButton {
  margin-top: 24px;
  height: 100px;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: #fff;
  display: block;
}
.dashboard_seeAllButton:hover {
  -webkit-box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
.dashboard_seeAllButton:hover .dashboard_seeAllButton_top_icon,
.dashboard_seeAllButton:hover .dashboard_seeAllButton_top_seeAll_text,
.dashboard_seeAllButton:hover .dashboard_seeAllButton_top_seeAll_icon {
  color: #364196;
}
.dashboard_seeAllButton_top {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.dashboard_seeAllButton_top_icon {
  font-size: 40px;
  color: #5c90df;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.dashboard_seeAllButton_top_seeAll {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashboard_seeAllButton_top_seeAll_text {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  color: #5c90df;
  font-size: 16px;
  margin-bottom: 0;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.dashboard_seeAllButton_top_seeAll_icon {
  color: #5c90df;
  font-size: 15px;
  margin-left: 10px;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.dashboard_seeAllButton_bottom {
  width: 100%;
  height: 50px;
  border-radius: 0 0 4px 4px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashboard_seeAllButton_bottom_text {
  font-size: 16px;
  color: #5f717f;
  margin: 0;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
}
.dashboard_activateTV {
  width: 270px;
  height: 210px;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: #e7ecf1;
  display: block;
}
.dashboard_activateTV:hover {
  -webkit-box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
.dashboard_activateTV:hover .dashboard_activateTV_top_icon {
  background-color: #364196;
}
.dashboard_activateTV:hover .dashboard_activateTV_top_activate_text,
.dashboard_activateTV:hover .dashboard_activateTV_top_activate_icon {
  color: #364196;
}
.dashboard_activateTV_top {
  width: 100%;
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.dashboard_activateTV_top_icon {
  width: 50px;
  height: 50px;
  -webkit-mask: url(https://pluss-prd-media.s3-ap-southeast-2.amazonaws.com/assets/cast-icon.svg) no-repeat 100% 100%;
  mask: url(https://pluss-prd-media.s3-ap-southeast-2.amazonaws.com/assets/cast-icon.svg) no-repeat 100% 100%;
  -webkit-mask-size: cover;
  mask-size: cover;
  background-color: #5c90df;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.dashboard_activateTV_top_activate {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashboard_activateTV_top_activate_text {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  color: #5c90df;
  font-size: 20px;
  margin-bottom: 0;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.dashboard_activateTV_top_activate_icon {
  color: #5c90df;
  font-size: 15px;
  margin-left: 10px;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.dashboard_activateTV_bottom {
  width: 100%;
  height: 50px;
  border-radius: 0 0 4px 4px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashboard_activateTV_bottom_text {
  font-size: 16px;
  color: #5f717f;
  margin: 0;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
}
.activate-container {
  width: 100%;
}
.activate-container .topSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: solid 1px #dbddf1;
}
.activate-container .topSection .activate-device {
  flex: 1;
}
.activate-container .topSection .activate-device .heading {
  font-family: 'Source Sans Pro';
  font-size: 36px;
  font-weight: normal;
  line-height: 54px;
  color: #536280;
}
.activate-container .topSection .activate-device .activate-field {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
}
.activate-container .topSection .activate-device .activate-field .label {
  font-family: 'Source Sans Pro';
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #536280;
  text-align: left;
  padding: 0;
  margin-bottom: 10px;
}
.activate-container .topSection .activate-device .activate-field .genericInput {
  font-family: 'Source Sans Pro';
  font-size: 30px;
  color: #536280;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  border-radius: 0;
  width: 420px;
  overflow-y: unset;
}
.activate-container .topSection .activate-device .activate-field .genericInput.padded {
  padding-top: 8px;
}
.activate-container .topSection .activate-device .activate-field .genericInput.focused {
  border-bottom: 1px solid #262a2d;
}
.activate-container .topSection .activate-device .activate-field .help {
  margin-top: 8px;
  font-family: 'Source Sans Pro';
  font-size: 14px;
  color: #8695b2;
}
.activate-container .topSection .activate-device .activate-field .status {
  font-family: 'Source Sans Pro';
  font-size: 16px;
  color: #ff8c75;
  margin-top: 15px;
  margin-bottom: 15px;
}
.activate-container .topSection .activate-device .activate-button {
  width: 125px;
}
.activate-container .topSection .activate-device .activate-button .buttonText {
  font-family: 'Source Sans Pro';
  font-size: 13px;
  font-weight: 600;
}
.activate-container .topSection .activate-guide-image {
  background-image: url('./assets/images/tvActivationCode.png');
  background-repeat: no-repeat;
  background-size: contain;
  height: 320px;
  width: 570px;
}
.activate-container .bottomSection {
  flex: 1;
  padding-top: 40px;
}
.activate-container .bottomSection .heading {
  font-family: 'Source Sans Pro';
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  color: #536280;
  padding-bottom: 30px;
}
.activate-container .bottomSection .deviceNameCell {
  font-family: 'Source Sans Pro';
  font-size: 16px;
  color: #262a2d;
}
.activate-container .bottomSection .deviceViewCell {
  font-family: 'Source Sans Pro';
  font-size: 14px;
  color: #536280;
}
.activate-container .bottomSection .deviceStatusCell {
  font-family: 'Source Sans Pro';
  font-size: 14px;
  color: #5c90df;
}
.activate-container .bottomSection .settings-button {
  width: 100px;
}
.activate-container .bottomSection .settings-button .buttonText {
  font-family: 'Source Sans Pro';
  font-size: 13px;
  font-weight: 600;
}
.activate-container .settingsPopup {
  width: 640px;
  padding: 30px;
  position: relative;
}
.activate-container .settingsPopup .closeButton {
  font-size: 20px;
  color: #536280;
  position: absolute;
  top: 16px;
  right: 16px;
  font-weight: normal;
}
.activate-container .settingsPopup .heading {
  font-family: 'Source Sans Pro';
  font-size: 34px;
  font-weight: normal;
  color: #536280;
}
.activate-container .settingsPopup .subHeading {
  font-family: 'Source Sans Pro';
  font-size: 26px;
  font-weight: normal;
  line-height: 39px;
  color: #8695b2;
}
.activate-container .settingsPopup .help {
  font-family: 'Source Sans Pro';
  font-size: 13px;
  font-weight: normal;
  color: #fff;
  background-color: #a4aada;
  padding: 14px 17px;
  margin-top: 18px;
}
.activate-container .settingsPopup .linkedViewsHeading {
  font-family: 'Source Sans Pro';
  font-size: 13px;
  font-weight: 600;
  color: #536280;
  letter-spacing: 1.04px;
  margin-top: 30px;
}
.activate-container .settingsPopup .radioButtonWrapper .actionText {
  font-size: 15px !important;
  margin-left: 20px !important;
  font-weight: 600;
  color: #536280;
}
.activate-container .settingsPopup .status {
  font-family: 'Source Sans Pro';
  font-size: 14px;
  color: #ff8c75;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
}
.activate-container .settingsPopup .buttonsContainer {
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.activate-container .settingsPopup .buttonsContainer .button {
  margin-left: 10px;
  margin-right: 10px;
  width: 160px;
}
.activate-container .settingsPopup .buttonsContainer .button .buttonText {
  font-family: 'Source Sans Pro';
  font-size: 16px;
  font-weight: 600;
}
.userTypesButtonContainer {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 21px;
}
.userTypesButtonContainer .button .buttonInner {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 28px;
}
.userTypesButtonContainer .button .buttonInner .text {
  margin-left: 12px;
}
.editPermissions {
  min-width: 100%;
}
.editPermissions .headingSection {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 36px;
}
.editPermissions .headingSection .headingText {
  font-family: 'Quicksand', sans-serif;
  font-size: 36px;
  font-weight: bold;
  color: #536280;
}
.editPermissions .headingSection .addNewContainer {
  margin-top: 15px;
}
.editPermissions .userTypesList {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
.editPermissions .popup_box {
  position: relative;
}
.editPermissions .popup_box .closeButton {
  position: absolute;
  top: 17px;
  right: 17px;
  width: 14px;
  height: 14px;
}
.editPermissions .popup_box .title {
  font-family: 'Quicksand', sans-serif;
  font-size: 34px;
  font-weight: bold;
  color: #262a2d;
}
.editPermissions .popup_box .userTypeNameSection {
  margin: 15px;
}
.editPermissions .popup_box .userTypeNameSection .userTypeNameInput {
  font-size: 26px;
  padding: 0 8px;
}
.editPermissions .popup_box .popupButton .button {
  width: 160px;
  height: 42px;
  display: flex;
}
.selectUserType .selectTypeHeading {
  font-family: 'Source Sans Pro';
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 1.04px;
  color: #536280;
  margin-top: 20px;
}
.selectUserType .userTypeOptions {
  padding-bottom: 15px;
}
.selectUserType .userTypeOptions .radioButtonWrapper {
  width: 180px;
  height: 46px;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  align-items: center;
  padding: 0 25px;
}
.selectUserType .userTypeOptions .actionText {
  margin-left: 15px !important;
  font-family: 'Source Sans Pro';
  font-size: 16px;
  font-weight: 600;
  color: #536280;
}
.editUserType {
  min-width: 100%;
}
.editUserType .headingText {
  font-family: 'Quicksand', sans-serif;
  font-size: 36px;
  font-weight: bold;
  color: #536280;
  margin-bottom: 36px;
}
.editUserType .userTypeNameSection {
  display: flex;
  flex-direction: row;
  align-items: 'center';
}
.editUserType .userTypeNameSection .iconContainer {
  width: 86px;
  height: 86px;
  border-radius: 43px;
  background-color: #dbddf1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.userType {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 140px;
  height: 180px;
  background-color: #fff;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-right: 20px;
  margin-bottom: 20px;
  padding-top: 15px;
  padding-bottom: 11px;
}
.userType.selected {
  -webkit-box-shadow: unset;
  -moz-box-shadow: unset;
  box-shadow: unset;
}
.userType .iconContainer {
  width: 86px;
  height: 86px;
  border-radius: 43px;
  background-color: #dbddf1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.userType .userTypeName {
  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 120px;
  overflow: hidden;
}
.userType .editContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  padding: 24px 32px 56px;
}
.userType .editContainer .headingText {
  font-family: 'Quicksand', sans-serif;
  font-size: 36px;
  font-weight: bold;
  color: #536280;
  margin-bottom: 36px;
}
.userType .editContainer .subHeadingText {
  font-family: 'Source Sans Pro';
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 1.2px;
  color: #536280;
  margin: 20px 0 15px;
}
.userType .editContainer .nameInputSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 30px;
}
.userType .editContainer .nameInputSection .userTypeNameInput {
  font-size: 26px;
  padding: 0;
  margin-left: 22px;
  width: 500px;
}
.userType .editContainer table {
  margin-bottom: 0;
}
.userType .editContainer table th {
  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #536280;
  border-bottom: unset;
  padding: 8px 0;
}
.userType .editContainer table td {
  padding: 10px 0;
  border-top: unset;
}
.userType .editContainer table td .checkBox {
  height: 24px;
  width: 24px;
  border-radius: 12px;
}
.userType .editContainer table td.text {
  font-family: 'Source Sans Pro';
  font-size: 14px;
  color: #536280;
  line-height: 22px;
}
.userType .editContainer .buttonsSection {
  background-color: #fff;
  border-top: 1px solid #e7ecf1;
  height: 56px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding: 0 24px;
}
.signatureContainer {
  padding: 5px 10px;
  border-radius: 4px;
  border: 1px dashed #dbddf1;
}
.signatureImg {
  max-width: 100%;
  max-height: 50px;
}
.signature_canvas {
  height: 150px;
  background-color: #e2e7ee;
  border: solid 1px #dbddf1;
  border-radius: 4px;
}
.signInForms {
  min-width: 100%;
}
.signInForms .addButton {
  margin-bottom: 32px;
}
.signInForms .plussTable {
  padding-bottom: 180px;
}
.signInForms .sortCol {
  cursor: pointer;
}
.signInForms .formOptions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.signInForms .formOptions .icon {
  font-size: 20px;
  padding: 5px;
  margin-right: 8px;
  cursor: pointer;
}
.addForm .formType {
  margin-top: 30px;
  width: 30%;
}
.addForm .formIconSection {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.addForm .pageNavSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 30px;
}
.addForm .pageNavSection .pages {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #5c90df;
  border-bottom-color: #5c90df;
  border-bottom-style: solid;
  border-bottom-width: 4px;
  padding: 0 20px 7px;
}
.addForm .pageNavSection .changePage {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.addForm .pageNavSection .changePage.disabled {
  opacity: 0.5;
}
.addForm .pageNavSection .changePage .icon {
  font-size: 15px;
  color: #5c90df;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.addForm .pageNavSection .changePage .text {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #5c90df;
  margin: 0 8px;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.addForm .pageNavSection .changePage:hover .icon,
.addForm .pageNavSection .changePage:hover .text {
  color: #5c90df;
}
.addForm .fields .addField {
  margin: 20px 0 0 40px;
}
.addForm .fields .fieldContainer {
  position: relative;
  display: flex;
  margin-top: 32px;
}
.addForm .fields .fieldContainer .fieldNumberContainer {
  display: flex;
  width: 40px;
}
.addForm .fields .fieldContainer .fieldNumberContainer .fieldNumber {
  width: 40px;
  text-align: center;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  font-size: 30px;
  color: #5c90df;
}
.addForm .fields .fieldContainer .field {
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  flex: 1;
  position: relative;
}
.addForm .fields .fieldContainer .field .fieldHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 0;
  border-bottom: 1px solid #dbddf1;
}
.addForm .fields .fieldContainer .field .fieldHeader .group {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.addForm .fields .fieldContainer .field .fieldHeader .group .line {
  background-color: #536280;
  width: 34px;
  height: 6px;
  margin: 3px 13px 0 0;
}
.addForm .fields .fieldContainer .field .fieldHeader .group .fieldType {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #536280;
}
.addForm .fields .fieldContainer .field .fieldHeader .group .dropdownInput {
  width: 150px !important;
  margin: 5px 20px 0 0 !important;
}
.addForm .fields .fieldContainer .field .fieldHeader .group .dropdownInput div {
  margin-bottom: unset !important;
}
.addForm .fields .fieldContainer .field .fieldHeader .group .dropdownInput .dropdownInput-bottomBorder {
  border-bottom: unset;
}
.addForm .fields .fieldContainer .field .fieldHeader .group .dropdownInput .dropdownInput-bottomBorder input {
  font-size: 14px;
  color: #5c90df;
  width: 80%;
}
.addForm .fields .fieldContainer .field .fieldHeader .group .dropdownInput .dropdownInput-bottomBorder .fa-chevron-down,
.addForm .fields .fieldContainer .field .fieldHeader .group .dropdownInput .dropdownInput-bottomBorder .fa-chevron-up {
  color: #5c90df !important;
}
.addForm .fields .fieldContainer .field .fieldHeader .group .delete {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #ff6363;
  margin-right: 15px;
  margin-left: -15px;
  cursor: pointer;
}
.addForm .fields .fieldContainer .field .fieldInner {
  margin: 25px 20px 0;
  display: flex;
  flex-direction: column;
}
.addForm .fields .fieldContainer .field .fieldInner .placeHolderInput {
  padding: 5px;
  background-color: #e2e7ee;
}
.addForm .fields .fieldContainer .field .fieldInner .placeHolderInput div {
  margin-bottom: unset !important;
}
.addForm .fields .fieldContainer .field .fieldInner .placeHolderInput input {
  border-bottom: unset;
}
.addForm .fields .fieldContainer .field .fieldInner .optionsContainer {
  margin-bottom: 10px;
}
.addForm .fields .fieldContainer .field .fieldInner .optionsContainer .headings th {
  border-bottom: unset;
  color: #536280;
}
.addForm .fields .fieldContainer .field .fieldInner .optionsContainer .headings .icon {
  width: 5px;
}
.addForm .fields .fieldContainer .field .fieldInner .optionsContainer .headings .fail {
  width: 60px;
}
.addForm .fields .fieldContainer .field .fieldInner .optionsContainer .headings .options {
  width: 100px;
}
.addForm .fields .fieldContainer .field .fieldInner .optionsContainer .headings .options-wide {
  width: 200px;
}
.addForm .fields .fieldContainer .field .fieldInner .optionsContainer .headings .remove {
  width: 10px;
}
.addForm .fields .fieldContainer .field .fieldInner .optionsContainer .option td {
  border-top: unset;
}
.addForm .fields .fieldContainer .field .fieldInner .optionsContainer .option .radioButtonWrapper {
  margin-top: -2px;
}
.addForm .fields .fieldContainer .field .fieldInner .optionsContainer .option .genericInputContainer {
  margin-bottom: unset;
  margin-top: -4px;
  margin-left: -2px;
}
.addForm .fields .fieldContainer .field .fieldInner .optionsContainer .option .genericInput-textarea {
  margin-top: -5px;
  margin-left: -2px;
}
.addForm .fields .fieldContainer .field .fieldInner .optionsContainer .option .cornerCancelButton_icon {
  color: #5c90df;
}
.addForm .fields .fieldContainer .field .fieldInner .optionAdd {
  margin-left: 40px;
}
.addForm .fields .fieldContainer .switchField {
  position: absolute;
  right: -25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
}
.addForm .fields .fieldContainer .switchField .circle {
  background-color: #a1afcc;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  cursor: pointer;
  width: 18px;
  height: 18px;
  border-radius: 9px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 5px 0;
}
.addForm .fields .fieldContainer .switchField .circle:hover {
  background-color: #67758f;
}
.addForm .fields .fieldContainer .switchField .circle .icon {
  color: #fff;
  font-size: 14px;
}
.formPages {
  margin-top: 38px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 380px;
}
.formPages .page {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-style: solid;
  border-width: 2px;
  border-color: transparent;
  width: 30%;
  height: 140px;
  border-radius: 4px;
  margin-right: 10px;
  margin-bottom: 15px;
  cursor: pointer;
}
.formPages .page:hover,
.formPages .page.selected {
  border-style: solid;
  border-width: 2px;
  border-color: #364196;
}
.formPages .page.add {
  background-color: #5c90df;
}
.formPages .page.add .addoption_plus {
  margin-right: unset;
  color: #fff;
}
.formPages .page.add .addoption_plus .text-white {
  color: #5c90df !important;
}
.formPages .page.add .text {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #fff;
}
.formPages .page .removePage {
  position: absolute;
  top: 6px;
  right: 0;
}
.formPages .page .removePage .addoption_plus {
  color: #5c90df;
  font-size: 15px;
}
.formPages .page .title {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #5c90df;
}
.formPages .page .fields {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #8695b2;
}
.formPages .page .currentPage {
  position: absolute;
  bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.formPages .page .currentPage .icon {
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: #5c90df;
  margin-right: 6px;
}
.formPages .page .currentPage .text {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 11px;
  color: #5c90df;
}
.automation .progressBar {
  margin-bottom: 35px;
}
.automation .primaryBtn {
  width: 100px;
  text-align: center;
}
.automation .secondaryBtn {
  width: 100px;
  text-align: center;
}
.automation .buildQueueContainer {
  max-height: 300px;
  overflow: auto;
  padding: 0 10px;
  margin-bottom: 16px;
}
.paymentHub {
  flex-direction: column;
  padding-bottom: 200px;
}
.paymentHub .stripe-image {
  background-image: url('./assets/images/stripeLogo.svg');
  background-repeat: no-repeat;
  background-size: contain;
  height: 62px;
  width: 131px;
}
.numberSpinner {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.numberSpinner .buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  -webkit-box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.numberSpinner .buttonContainer:not(.disabled) {
  cursor: pointer;
}
.numberSpinner .buttonContainer:not(.disabled):hover {
  background-color: #6c7a90;
}
.numberSpinner .buttonContainer:not(.disabled):hover .buttonIcon {
  color: #fff;
}
.numberSpinner .buttonContainer.disabled .buttonIcon {
  color: rgba(108, 122, 144, 0.2);
}
.numberSpinner .buttonIcon {
  font-size: 14px;
  color: #6c7a90;
  -moz-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.numberSpinner .numberText {
  text-align: center;
  width: 40px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 15px;
  color: #536280;
}
/*  */
@media only screen and (max-width: 600px) {
  /* Splash Screen Styles */
  .loginStripe {
    position: relative;
    padding: 32px;
    background-color: #fff;
    height: fit-content;
    min-height: unset;
    width: fit-content;
    margin: 0 auto;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}
@media screen and (max-height: 1000px) {
  .featurePicker .featurePickerLeft,
  .featurePicker-scaled .featurePickerLeft {
    padding-right: calc(384px * 0.2);
    margin-right: calc(-384px * 0.2 + 20px);
  }
  .featurePicker .featurePickerPreview,
  .featurePicker-scaled .featurePickerPreview {
    transform: scale(0.8);
    transform-origin: top left;
  }
}
